import { Box, Container } from "@mui/material";
import Header from "../Header";
import Footer from "../Footer";
import DashboardContainer from "../../DashboardContainer";

const BaseAuthTemplate = ({ children }: any) => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Box>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default BaseAuthTemplate;
