import { Box, Grid, IconButton, Typography } from "@mui/material";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import { FontWeight } from "../../../core/types";
import { useLocation, useNavigate } from "react-router-dom";
import { stageOneInitialValue } from "../constants";
import { Formik } from "formik";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import { stageOneAuditCheckListA } from "../../../core/validations/formSchema";
import { ASSESSMENTFINDINGS } from "../../../router/config";
import TextWithLabel from "../../../core/components/TextWithLabel";
import { auditMethodology, recommendations } from "./constant";
import { handleDownloadPdf } from "../../../core/utils";

const AuditReport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state;

  const { data: prosData } = data;
  const { application, companyDetail } = prosData;
  const { leadAuditor, qmsAuditor, technicalAuditor } = application;
  const { nameOfCompany } = companyDetail;

  const detailField = (title: string, subtitle: any) => {
    const isBool = typeof subtitle === "boolean";
    let subTitle = "";
    if (isBool) {
      subTitle = subtitle === true ? "Yes" : "No";
    } else {
      subTitle = subtitle ? subtitle : "-";
    }
    return (
      <Grid item xs={2} sm={4} md={4}>
        {title && <TextWithLabel title={title} subTitle={subTitle} />}
      </Grid>
    );
  };

  const commonText = (text: string) => {
    return (
      <Typography fontSize={16} fontWeight={FontWeight.BOLD} pl={1} pt={2}>
        {text}
      </Typography>
    );
  };

  return (
    <AdminLayout>
      <DashboardContainer>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"baseline"}
        >
          <IconButton onClick={() => handleDownloadPdf("page-content")}>
            <Typography mr={1}>Download</Typography>
            <FileDownloadRoundedIcon />
          </IconButton>
        </Box>
        <Box id="page-content">
          <Formik
            initialValues={stageOneInitialValue}
            validationSchema={stageOneAuditCheckListA}
            onSubmit={(value) => {
              navigate(ASSESSMENTFINDINGS, {
                state: { data: data },
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  pt={7}
                  pl={2}
                  pr={2}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  width={"100%"}
                >
                  <Typography
                    fontSize={16}
                    fontWeight={FontWeight.BOLD}
                    pl={2}
                    pt={3}
                  >
                    Certification Audit Report
                  </Typography>
                  <Box
                    pt={2}
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <TextWithLabel title={"Name"} subTitle={nameOfCompany} />
                    <TextWithLabel
                      title={"Date of stage 2 audit"}
                      subTitle={"24-May-24"}
                    />
                  </Box>
                  <Box pl={1} pt={2} width={"100%"}>
                    <Typography fontWeight={FontWeight.BOLD}>
                      Type of audit : New
                    </Typography>
                    <Typography pt={1} fontWeight={FontWeight.BOLD}>
                      Product / service-related legal or regulatory
                      requirements: Placeholder
                    </Typography>
                    <Typography pt={1} fontWeight={FontWeight.BOLD}>
                      Date of stage 1 adequacy audit: 4-May-24
                    </Typography>
                  </Box>
                  <Grid
                    container
                    spacing={4}
                    alignItems={"baseline"}
                    columns={{ xs: 2, sm: 12, md: 16 }}
                    sx={{
                      paddingTop: 2,
                      paddingBottom: 2,
                    }}
                  >
                    {detailField(
                      "Scope of audit/ certification:",
                      "ISO, FSSC 220000, GO Standards"
                    )}
                    {detailField("Exclusions & Justifications (if any):", "-")}
                  </Grid>
                  <Box width={"100%"}>
                    <Typography
                      fontSize={16}
                      fontWeight={FontWeight.BOLD}
                      pl={1}
                    >
                      Audit Team
                    </Typography>
                    <Grid
                      container
                      spacing={4}
                      alignItems={"baseline"}
                      columns={{ xs: 2, sm: 13, md: 16 }}
                      sx={{
                        paddingBottom: 2,
                      }}
                    >
                      {detailField("Lead Auditor", leadAuditor)}
                      {detailField("Auditor", qmsAuditor)}
                      {detailField("Technical Expert", technicalAuditor)}
                      {detailField("Observer", "-")}
                    </Grid>
                    <Typography
                      fontSize={16}
                      fontWeight={FontWeight.BOLD}
                      pl={1}
                    >
                      Audit methodology
                    </Typography>
                    {auditMethodology.map((item: string) => {
                      return commonText(item);
                    })}
                    {commonText("Opening meeting and audit proceedings")}
                    {commonText(
                      "Closing meeting: \n\nThe audit was conducted amicably, the staff was found transparent and in learning mode. Following issues were highlighted –"
                    )}
                    {commonText("SWOT ANALYSIS AND RECOMMENDATIONS: ")}
                    {recommendations.map((item: string) => {
                      return commonText(item);
                    })}
                    {commonText("Recommendation of the audit team:  ")}
                    {commonText("Follow-up required :  ")}
                    {commonText("Surveillance Frequency: once in 12 months")}
                  </Box>
                </Box>
                {/* <Box
                pt={7}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                width={"85%"}
              >
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Box> */}
              </form>
            )}
          </Formik>
        </Box>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default AuditReport;
