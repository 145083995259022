import { Box, Checkbox, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { FontWeight, TextFieldType } from "../../../core/types";
import TextInputTextField from "../../../core/components/Input/TextInputTextField";

interface SelectedState {
  [key: string]: string;
}

const CheckRemarkComponent = ({
  rows = [],
  setFieldValue,
  fieldName = "name",
  heading = "heading",
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [selected, setSelected] = useState<any>({});

  const handleChange =
    (name: string, option: string, label: string, index: any) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = {
        value: event.target.checked ? option : "",
        label: label,
      };

      setSelected((prev: any) => ({
        ...prev,
        [name]: newValue,
      }));
      setFieldValue(`${fieldName}.list.${index}`, newValue);
      setFieldValue(`${fieldName}.heading`, heading);
    };

  return (
    <Box pl={2} pt={1}>
      {rows.map((item: any, index: any) => {
        return (
          <Box pb={4}>
            <Box
              display={"flex"}
              justifyContent={isMobile ? 'flex-start' :  "space-between"}
              alignItems={isMobile ? "flex-start" :  "center"}
              flexDirection={isMobile ? 'column' : "row"}
              pt={1}
            >
              <Typography fontSize={16} fontWeight={FontWeight.BOLD}>
                {item.label}
              </Typography>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontSize={16} fontWeight={FontWeight.BOLD}>
                    Checked
                  </Typography>
                  <Checkbox
                    checked={selected[item.name]?.value === "checked"}
                    onChange={handleChange(
                      item.name,
                      "checked",
                      item.label,
                      index
                    )}
                  />
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontSize={16} fontWeight={FontWeight.BOLD}>
                    Comply
                  </Typography>
                  <Checkbox
                    checked={selected[item.name]?.value === "comply"}
                    onChange={handleChange(
                      item.name,
                      "comply",
                      item.label,
                      index
                    )}
                  />
                </Box>
              </Box>
            </Box>
            <TextInputTextField
              type={TextFieldType.TEXT}
              name="followUpFeedback"
              label=""
              disabled={selected[item.name] == undefined}
              onChange={(val: any) => {
                setFieldValue(
                  `${fieldName}.list.${index}.followUpFeedback`,
                  val.target.value
                );
                setFieldValue(`${fieldName}.heading`, heading);
              }}
              multiline={true}
              row={2}
              labelHeight={0}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default CheckRemarkComponent;
