import { Box, Grid, IconButton, Typography, useMediaQuery, useTheme,  } from "@mui/material";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { getApplicationReviewByCompanyId } from "../../../store/Application/applicationService";
import { useEffect, useState } from "react";
import Loader from "../../../core/components/Loader";
import TextWithLabel from "../../../core/components/TextWithLabel";
import { FontWeight } from "../../../core/types";
import SeasonalActivityTable from "../components/SeasonalAcivityTable";
import ManPowerCalculationTable from "../components/ManPowerCalculationTable";
import IncreaseInManDays from "../components/IncreaseInManDays";
import ManDayDetails from "../components/ManDayDetails";
import ImpartialityAssessement from "../components/ImpartialityAssessment";
import { levelOfIntegration } from "../../adminReview/constants";
import { handleDownloadPdf } from "../../../core/utils";
import DashboardContainer from "../../../core/components/DashboardContainer";

const ApplicationReviewView = ({ data }: any) => {
  const [responseData, setResponseData] = useState<any>({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { execute, loading } = useAsyncRequest(getApplicationReviewByCompanyId);

  const compareNames = (list1: any, list2: any) => {
    const anotherListNames = list2.map((item: any) => item?.name);
    return list1.map((item: any) => ({
      name: item?.name,
      label: item?.label,
      existsInAnotherList: anotherListNames.includes(item?.name) ? "Yes" : "No",
    }));
  };

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      setResponseData(data);
    } else {
      setResponseData(null);
    }
  };

  useEffect(() => {
    const payload = {
      companyId: data,
    };
    execute(payload, handleResponse);
  }, [data]);

  const heading = (title: string) => {
    return (
      <Typography variant="h5" fontWeight={FontWeight.MEDIUM}>
        {title.toUpperCase()}
      </Typography>
    );
  };

  const detailField = (title: string, subtitle: any) => {
    const isBool = typeof subtitle === "boolean";
    let subTitle = "";
    if (isBool) {
      subTitle = subtitle === true ? "Yes" : "No";
    } else {
      subTitle = subtitle ? subtitle : "-";
    }
    return (
      <Grid item xs={2} sm={4} md={4}>
        {title && <TextWithLabel title={title} subTitle={subTitle} />}
      </Grid>
    );
  };

  return (
    <Box>
      <Loader open={loading} />
      <Box display={"flex"} justifyContent={"flex-end"} alignItems={"baseline"}>
        <IconButton onClick={() => handleDownloadPdf("page-content")}>
          <Typography mr={1}>Download</Typography>
          <FileDownloadRoundedIcon />
        </IconButton>
      </Box>
      {responseData !== null ? (
        <Box id="page-content">
          <TextWithLabel
            title={"Comments on Logo"}
            subTitle={responseData?.commentsOnLogo}
          />
          <Box pt={3}>
            <DashboardContainer>
            {heading(" Particulars w.r.t. Category")}
            <Grid
              container
              spacing={4}
              alignItems={"baseline"}
              columns={{ xs: 2, sm: 12, md: 16 }}
              sx={{
                paddingTop: 2,
                paddingBottom: 2,
              }}
            >
              {detailField("Category", "Food")}
              {detailField("Sub-Category", "Food")}
              {detailField("Season of Operation", "All Season")}
            </Grid>
            </DashboardContainer>
          </Box>
          <Box pt={3}>
          <DashboardContainer>
            {heading("Information about the Accreditation")}
            <Grid
              container
              spacing={4}
              alignItems={"baseline"}
              columns={{ xs: 2, sm: 12, md: 16 }}
              sx={{
                paddingTop: 2,
                paddingBottom: 2,
              }}
            >
              {detailField("Accreditation Requested by Client", "ALL")}
              {detailField("Accreditation Available", "N/A")}
              {detailField("Accreditation To Be Granted", "ALL")}
            </Grid>
            </DashboardContainer>
          </Box>
          <Box pt={3}>
          <DashboardContainer>
            {heading(
              "Seasonal Activities : [such as harvesting activities etc.]"
            )}
            <SeasonalActivityTable
              sesonalActivityData={responseData?.seasonalActivities}
            />
            </DashboardContainer>
          </Box>
          <Box pt={3}>
          <DashboardContainer>
            {heading("Man Power Calculations")}
            <ManPowerCalculationTable
              manPowerCalculation={responseData?.manPowerCalculation}
            />
            </DashboardContainer>
          </Box>
          <Box pt={3}>
          <DashboardContainer>
            {heading("Increase in Man-days")}
            <IncreaseInManDays
              increaseinManDays={responseData?.increaseinManDays}
              totalTimeOnSIte={responseData?.totalTimeOnSIte}
            />
            </DashboardContainer>
          </Box>
          <Box pt={3}>
            <DashboardContainer>
             <ManDayDetails responseData={responseData} />
            </DashboardContainer>
          </Box>
          <Box pt={3}>
          <DashboardContainer>
            {heading("Impartiality Assessments")}
            <ImpartialityAssessement
              impartialityAssessementList={
                responseData?.impartialityAssessementList
              }
            />
            </DashboardContainer>
          </Box>
          <Box pt={3}>
          <DashboardContainer>
            {heading("Review of Last 3 years Audit performance:")}
            {detailField(
              "Verification of conclusion made by Team Leader of Last Surveillance Audit",
              responseData?.verificaionOfConclusionMadeByTeamLeaderLastServeillanceAudit
            )}
            {detailField(
              "Any significant changes in the Management System / Operations / Production Line / Location vis-à-vis last audit",
              "-"
            )}
            {detailField(
              "The information from “External Sources” (Public information), if any",
              responseData?.infoFromExternalSources
            )}
            </DashboardContainer>
          </Box>
          <Box pt={3}>
            <DashboardContainer>
            {heading("Existing Certification Status")}
            <Grid
              container
              spacing={4}
              alignItems={"baseline"}
              columns={{ xs: 2, sm: 12, md: 16 }}
              sx={{
                paddingTop: 2,
                paddingBottom: 2,
              }}
            >
              {detailField("Management System", responseData?.managementSystem)}
              {detailField("Certificate No.", responseData?.certifcateNo)}
              {detailField("Certificate expiry date", responseData?.expiryDate)}
              {detailField("Certification Body", responseData?.certificateBody)}
            </Grid>
            </DashboardContainer>
          </Box>
          <Box pt={3}>
            <DashboardContainer>
            {heading("Multisite Information")}
            <Grid
              container
              spacing={4}
              alignItems={"baseline"}
              columns={{ xs: 2, sm: 12, md: 16 }}
              sx={{
                paddingTop: 2,
                paddingBottom: 2,
              }}
            >
              {detailField(
                "Certification Audit",
                responseData?.certificaitonAudit
              )}
              {detailField(
                "Surveillance Audit 1",
                responseData?.surveillanceAuditOne
              )}
              {detailField(
                "Surveillance Audit 2",
                responseData?.surveillanceAuditTwo
              )}
            </Grid>
            </DashboardContainer>
          </Box>
          <Box pt={3}>
            <DashboardContainer>
            {heading("Level of Integration")}
            {responseData?.levelOfIntegration &&
              compareNames(
                levelOfIntegration,
                responseData?.levelOfIntegration
              ).map((item: any, index: any) => {
                return (
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography pt={1} fontSize={isMobile ? '0.7rem' : '1rem'}
                      sx={{ textDecoration: 'underline' }}>{item.label}</Typography>
                    <Typography fontWeight={FontWeight.MEDIUM} pt={1}>
                      {item.existsInAnotherList}
                    </Typography>
                  </Box>
                );
              })}
            </DashboardContainer>
          </Box>
          <Box pt={3} mb={4}>
            {detailField("Comments / Remarks ", responseData?.commentsRemarks)}
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography>Review not completed yet</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ApplicationReviewView;
