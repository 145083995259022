import { TextFieldType } from "../../../core/types";

export const createQuoation = [
  {
    type: TextFieldType.TEXT,
    name: "applicationFee",
    label: "Application Fee",
  },
  {
    type: TextFieldType.TEXT,
    name: "accreditationFee",
    label: "Accreditation Fee",
  },
  {
    type: TextFieldType.TEXT,
    name: "stageOneAuditFee",
    label: "Stage 1 Audit Fee",
  },
  {
    type: TextFieldType.TEXT,
    name: "stageTwoAuditFee",
    label: "Stage 2 Audit Fee",
  },
  {
    type: TextFieldType.TEXT,
    name: "documentReviewForRenuewalAudit",
    label: "Document Review For Renewal",
  },
  {
    type: TextFieldType.TEXT,
    name: "renewalAuditFree",
    label: "Renewal Audit Fee",
  },
  {
    type: TextFieldType.TEXT,
    name: "surverillianceAuditOneFree",
    label: "Surveillance Audit # 1 Fee",
  },
  {
    type: TextFieldType.TEXT,
    name: "surverillianceAuditTwoFree",
    label: "Surveillance Audit # 2 Fee",
  },
  {
    type: TextFieldType.TEXT,
    name: "specialAuditFee",
    label: "Special Audit Fee",
  },
  {
    type: TextFieldType.TEXT,
    name: "otherFees",
    label: "Other Fee i(f any)",
  },
];

export const quoationInitialValue = {
  applicationFee: null,
  accreditationFee: null,
  stageOneAuditFee: null,
  stageTwoAuditFee: null,
  documentReviewForRenuewalAudit: null,
  renewalAuditFree: null,
  surverillianceAuditOneFree: null,
  surverillianceAuditTwoFree: null,
  specialAuditFee: null,
  otherFees: null,
};
