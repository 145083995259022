import Box from "@mui/material/Box";
import SignUpForm from "./Components/SignUpForm";
import BaseAuthTemplate from "../../../core/components/CommonTemplate/BaseAuthTemplate";
import ImageContainer from "../Common/ImageContainer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";

const SignUpPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <BaseAuthTemplate>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <SignUpForm />
       {!isMobile && <ImageContainer />} 
      </Box>
    </BaseAuthTemplate>
  );
};

export default SignUpPage;
