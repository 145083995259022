import React, { useState } from "react";
import { Button, IconButton, Box, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const FileUploadWithPreview = ({onFileSelected}: any) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      setSelectedFile(file);
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
      onFileSelected(file);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    onFileSelected(null);
  };

  return (
    <Box>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="fileInput"
      />
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <label htmlFor="fileInput">
        <Button variant="contained" component="span">
          {selectedFile ? "Change Certificate" : "Upload Certificate"}
        </Button>
      </label>
      </Box>

      {previewUrl && (
        <Box mt={2}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Typography variant="h6">Certificate Preview:</Typography>
            <IconButton
              aria-label="remove file"
              onClick={handleRemoveFile}
              sx={{ mt: 2, color: "#f04235" }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
          <Box
            component="img"
            src={previewUrl}
            alt="Certificate Preview"
            sx={{
              width: "100%",
              height: "auto",
              border: "1px solid #ccc",
              padding: "10px",
              mt: 2,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default FileUploadWithPreview;
