import AppRoutes from "./router";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AutoLogout from "./core/utils/autoLoggout";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  return (
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AutoLogout>
            <AppRoutes />
          </AutoLogout>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

const THEME = createTheme({
  typography: {
    fontFamily:
      'Roboto, -apple-system, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

export default App;
