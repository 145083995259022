import { useLocation } from "react-router-dom";
import DashboardContainer from "../../../../core/components/DashboardContainer";
import AdminLayout from "../../components/AdminLayout";
import AuditFindingsTable from "../../../../core/components/AuditFindingsTable";
import { useState, useEffect } from "react";
import useAsyncRequest from "../../../../networking/useAsyncRequest";
import { getAuditAssessmentFindings } from "../../../../store/AuditorFlow/auditorListService";
import { useSelector } from "react-redux";
import Loader from "../../../../core/components/Loader";
import { Grid } from "@mui/material";
import TextWithLabel from "../../../../core/components/TextWithLabel";

const AuditFindingsUserView = () => {
  const location = useLocation();
  const { data } = location.state;
  const [findings, setFindings] = useState<any>([]);
  const [responseData, setResponseData] = useState<any>([]);
  const findingsResponse = useSelector(
    (state: any) => state.admin.userAuditFinings
  );

  const { applicationId } = data;

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      const { assesmentData } = data;
      setResponseData(assesmentData);
      const { detailOfFindings } = assesmentData;
      setFindings(detailOfFindings);
    }
  };

  const { execute, loading } = useAsyncRequest(getAuditAssessmentFindings);

  const payloadList = {
    applicationId: applicationId,
  };

  useEffect(() => {
    execute(payloadList, handleResponse);
  }, [applicationId, findingsResponse]);

  const detailField = (title: string, subtitle: any) => {
    const isBool = typeof subtitle === "boolean";
    let subTitle = "";
    if (isBool) {
      subTitle = subtitle === true ? "Yes" : "No";
    } else {
      subTitle = subtitle ? subtitle : "-";
    }
    return (
      <Grid item xs={2} sm={4} md={4}>
        {title && <TextWithLabel title={title} subTitle={subTitle} />}
      </Grid>
    );
  };

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={loading} />
        <Grid
          container
          spacing={4}
          alignItems={"baseline"}
          columns={{ xs: 2, sm: 12, md: 16 }}
          sx={{
            paddingTop: 4,
            paddingBottom: 2,
          }}
        >
          {detailField(
            "SCHEDULE OF ASSESSMENT",
            responseData.scheduleOfAssessment
          )}
          {detailField("DATE OF ASSESSMENT", responseData.dateOfAssessment)}
          {detailField("ASSESSMENT TEAM", responseData.assessmentTeam)}
        </Grid>
        <AuditFindingsTable
          findings={findings}
          applicationId={applicationId}
          onClick={(item: any, rowIndex: any) => console.log(item, rowIndex)}
        />
      </DashboardContainer>
    </AdminLayout>
  );
};

export default AuditFindingsUserView;
