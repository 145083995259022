import { apiClient } from "../../networking/apiClient";
import {
  GETADMINISTRATORLIST,
  GETAUDITORLIST,
} from "../../networking/endpoints";

export const getAuditorList = async () => {
  try {
    const response = await apiClient.get(GETADMINISTRATORLIST);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAuditorListDrop = async () => {
  try {
    const response = await apiClient.get(GETAUDITORLIST);
    return response.data;
  } catch (error) {
    throw error;
  }
};
