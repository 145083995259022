import { makeApiRequest } from "../../networking/apiClient";
import { AGREEMENT } from "../../networking/endpoints";

export const updateAgreement = async (payload: any) => {
  try {
    const response = await makeApiRequest(AGREEMENT, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};
