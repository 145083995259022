import {
  Box,
  Button,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { getAuditAssessmentFindings } from "../../../store/AuditorFlow/auditorListService";
import { useState, useEffect } from "react";
import { FontWeight } from "../../../core/types";
import Loader from "../../../core/components/Loader";
import {
  findingsApproval,
  findingsReject,
} from "../../../store/Application/applicationService";
import TextWithLabel from "../../../core/components/TextWithLabel";
import AttachmentsView from "../../../core/components/AttachmentsView";
import DashboardContainer from "../../../core/components/DashboardContainer";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AuditFindingsView = ({ data }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [findings, setFindings] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [responseData, setResponseData] = useState<any>([]);
  const [details, setDetails] = useState<any>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { execute, loading } = useAsyncRequest(getAuditAssessmentFindings);

  const { execute: executeApproval, loading: approvalLoading } =
    useAsyncRequest(findingsApproval);

  const { execute: executeReject, loading: rejectLoading } =
    useAsyncRequest(findingsReject);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      const { assesmentData } = data;
      setResponseData(assesmentData);
      const { detailOfFindings } = assesmentData;
      setFindings(detailOfFindings);
    } else {
      setResponseData(null);
    }
  };

  const payloadList = {
    applicationId: data,
  };

  useEffect(() => {
    execute(payloadList, handleResponse);
  }, [data]);

  const handleApprovalResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      handleClose();
      execute(payloadList, handleResponse);
    }
  };

  const handleRejectResponse = (resData: any) => {
    const { status } = resData;
    if (status == 200) {
      handleClose();
      execute(payloadList, handleResponse);
    }
  };

  const headLabel = (label: string) => {
    return (
      <Typography fontWeight={FontWeight.MEDIUM} fontSize={16} color={"#929396"}>
        {label}
      </Typography>
    );
  };

  const detailField = (title: string, subtitle: any) => {
    const isBool = typeof subtitle === "boolean";
    let subTitle = "";
    if (isBool) {
      subTitle = subtitle === true ? "Yes" : "No";
    } else {
      subTitle = subtitle ? subtitle : "-";
    }
    return (
      <Grid item xs={2} sm={4} md={4}>
        {title && <TextWithLabel title={title} subTitle={subTitle} />}
      </Grid>
    );
  };

  return (
    <Box>
      <Loader open={loading || approvalLoading || rejectLoading} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Approve or reject the Finding
          </Typography>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            pt={3}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ mr: 5 }}
              onClick={() => {
                const payload = {
                  assessmentId: responseData.id,
                  findingsId: details,
                };
                executeApproval(payload, handleApprovalResponse);
              }}
            >
              Approve
            </Button>
            <Button
              color="primary"
              onClick={() => {
                const payload = {
                  assessmentId: responseData.id,
                  findingsId: details,
                };
                executeReject(payload, handleRejectResponse);
              }}
            >
              Reject
            </Button>
          </Box>
        </Box>
      </Modal>
      {responseData ? (
        <Box>
        <DashboardContainer>
          <Typography pt={1} fontWeight={FontWeight.BOLD}>
            Records of Assessment findings (RAF)
          </Typography>
          {responseData && (
            <Grid
              container
              spacing={4}
              alignItems={"baseline"}
              columns={{ xs: 2, sm: 12, md: 16 }}
              sx={{
                paddingTop: 4,
                paddingBottom: 2,
              }}
            >
              {detailField(
                "SCHEDULE OF ASSESSMENT",
                responseData.scheduleOfAssessment
              )}
              {detailField(
                "DATE/S OF ASSESSMENT",
                responseData.dateOfAssessment
              )}
              {detailField("ASSESSMENT TEAM", responseData.dateOfAssessment)}
              {detailField("DISCLOSURE DATE", responseData.discloreDate)}
              {detailField(
                "ACKNOWLEDGEMENT DATE",
                responseData.acknowledgementDate
              )}
              {detailField("ACKNOWLEDGED BY", responseData.acknowledgedBy)}
              {responseData.prographOfAssessment &&
                responseData.prographOfAssessment.map((item: any) => {
                  return (
                    <Grid item xs={2} sm={4} md={4}>
                      <AttachmentsView label={item} />{" "}
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </DashboardContainer>
        <DashboardContainer>
          <Typography pt={1} fontWeight={FontWeight.BOLD}>
            Details of Finding
          </Typography>
          {isMobile ? 
          <Box>
             {findings.map((row: any, rowIndex: number) => (
               <Box p={2}>
                <Box mt={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography fontWeight={FontWeight.MEDIUM}>
                   {headLabel("S.No")}
                    </Typography>
                <Typography fontWeight={FontWeight.MEDIUM}>
                      {rowIndex + 1}
                    </Typography>
                </Box>
                <Box mt={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography fontWeight={FontWeight.MEDIUM}>
                   {headLabel("Grading")}
                    </Typography>
                <Typography fontWeight={FontWeight.MEDIUM}>
                      {row.grading ? row.grading : "-"}
                    </Typography>
                </Box>
                <Box  mt={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography fontWeight={FontWeight.MEDIUM}>
                   {headLabel("Reference Clause")}
                    </Typography>
                <Typography fontWeight={FontWeight.MEDIUM}>
                {row.referenceClause ? row.referenceClause : "-"}
                    </Typography>
                </Box>
                <Box  mt={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography fontWeight={FontWeight.MEDIUM}>
                   {headLabel("Assessment Team")}
                    </Typography>
                <Typography fontWeight={FontWeight.MEDIUM}>
                {row.assessementTeamMember
                        ? row.assessementTeamMember
                        : "-"}
                    </Typography>
                </Box>
                <Box  mt={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography fontWeight={FontWeight.MEDIUM}>
                   {headLabel("Remarks")}
                    </Typography>
                <Typography fontWeight={FontWeight.MEDIUM}>
                {row.remarks ? row.remarks : "-"}
                    </Typography>
                </Box>
                <Box  mt={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography fontWeight={FontWeight.MEDIUM}>
                   {headLabel("Status")}
                    </Typography>
                    <Box
                      sx={{
                        backgroundColor:
                          row.status == "Approved"
                            ? "green"
                            : "Approved"
                            ? "#f04235"
                            : "grey",
                        borderRadius: 2,
                        padding: 0.5,
                      }}
                    >
                <Typography fontWeight={FontWeight.MEDIUM} color={'white'}>
                {row.status ? row.status : "In Review"}
                    </Typography>
                    </Box>
                </Box>
               </Box>
            ))}
          </Box> :
          <Table
            sx={{
              minWidth: 650,
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">{headLabel("S.No")}</TableCell>
                <TableCell align="center">{headLabel("Grading")}</TableCell>
                <TableCell align="center">
                  {" "}
                  {headLabel("Reference Clause")}
                </TableCell>
                <TableCell align="center">
                  {headLabel("Assessment Team")}
                </TableCell>
                <TableCell align="center">{headLabel("Remarks")}</TableCell>
                <TableCell align="center">{headLabel("Status")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {findings.map((row: any, rowIndex: number) => (
                <TableRow key={rowIndex}>
                  <TableCell>
                    <Typography fontWeight={FontWeight.MEDIUM}>
                      {rowIndex + 1}.
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={FontWeight.MEDIUM}>
                      {row.grading ? row.grading : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={FontWeight.MEDIUM}>
                      {row.referenceClause ? row.referenceClause : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={FontWeight.MEDIUM}>
                      {row.assessementTeamMember
                        ? row.assessementTeamMember
                        : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={FontWeight.BOLD}>
                      {row.remarks ? row.remarks : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        backgroundColor:
                          row.status == "Approved"
                            ? "green"
                            : "Approved"
                            ? "red"
                            : "grey",
                        borderRadius: 2,
                        padding: 0.5,
                      }}
                    >
                      <Typography fontWeight={FontWeight.BOLD} color={"white"}>
                        {row.status ? row.status : "In Review"}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table> }
        </DashboardContainer>
        </Box>
      ) : (
        <Box>
          <DashboardContainer>
            <Typography>Findings not available yet</Typography>
          </DashboardContainer>
        </Box>
      )}
    </Box>
  );
};

export default AuditFindingsView;
