import { makeApiRequest } from "../../networking/apiClient";
import {
  ASSESSMENTFINDINGS,
  AUDITCHECKLISTFILL,
  AUDITORDASHBOARD,
  GETASSESSMENTFINDINGS,
  GETAUDITORCHECKLIST,
  GETAUDITPLANING,
  GETMESSAGES,
  GETREQUIREDLIST,
  OPENCLOSEMEETINGCHECK,
  REQUIREDCHECKLIST,
  SENDMESSAGE,
  STAGECHECKLIST,
  STANGEONEAUDITCHECKLIST,
} from "../../networking/endpoints";

const customHeaders = {
  "Content-Type": "multipart/form-data;",
};

export const stageOnecheckList = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      STANGEONEAUDITCHECKLIST,
      "POST",
      payload,
      customHeaders
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const requiredcheckList = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      REQUIREDCHECKLIST,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getStageCheckListByApplication = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      STAGECHECKLIST,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getrequiredList = async (payload: any) => {
  try {
    const response = await makeApiRequest(GETREQUIREDLIST, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const auditAssessment = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      ASSESSMENTFINDINGS,
      "POST",
      payload,
      customHeaders
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAuditAssessmentFindings = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      GETASSESSMENTFINDINGS,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const openclosemeetingcheck = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      OPENCLOSEMEETINGCHECK,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAuditPlainByApplicationId = async (payload: any) => {
  try {
    const response = await makeApiRequest(GETAUDITPLANING, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const auditCheckListFill = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      AUDITCHECKLISTFILL,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendMessage = async (payload: any) => {
  try {
    const response = await makeApiRequest(SENDMESSAGE, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMessage = async (payload: any) => {
  try {
    const response = await makeApiRequest(GETMESSAGES, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const auditorDashboard = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      AUDITORDASHBOARD,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAuditorCheckList = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      GETAUDITORCHECKLIST,
      "GET",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
