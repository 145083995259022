import { Grid, Typography, useMediaQuery } from "@mui/material";
import DashboardContainer from "../../core/components/DashboardContainer";
import AdminLayout from "../dashboard/components/AdminLayout";
import BasicTable from "../dashboard/components/Table";
import { useTheme } from "@mui/material/styles";


const ApplicationList = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AdminLayout>
      { !isMobile ? (
        <DashboardContainer>
          <Typography variant="h5" paddingBottom={1} marginLeft={'1.2rem'}>Application List</Typography>
          <BasicTable />
      </DashboardContainer>
      ) : ( 
        <>
          <Typography variant="h6" paddingBottom={1} marginLeft={'1.2rem'}>Application List</Typography>
          <BasicTable />
        </>
      )}
      
    </AdminLayout>
  );
};

export default ApplicationList;
