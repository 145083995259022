import { Alert, Avatar, Box, Button, Modal, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import { Formik } from "formik";
import {
  adminUserSchema,
  passwordChangeSchema,
} from "../../../core/validations/formSchema";
import CommonGridOnBoarding from "../../onBoarding/Components/common/CommonGridOnboarding";
import { admimUserDetails } from "../../users/constants";
import { useEffect, useState } from "react";
import TextInputLight from "../../../core/components/Input/TextInputLight";
import { TextFieldType } from "../../../core/types";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { updatePassword } from "../../../store/Auth/authService";
import Loader from "../../../core/components/Loader";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Profile = () => {
  const user = useSelector((state: any) => state.auth.user);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [incorrectPassword, setIncorrectPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { firstName, lastName, email, role, designation } = user;

  const { execute, loading: loading } = useAsyncRequest(updatePassword);

  const handleResponse = (res: any) => {
    const { status } = res;
    if (status === 200) {
      handleClose();
      setMessage("Password Changed Successfully");
      setSuccess(true);
    } else if (status === 400) {
      setIncorrectPassword("Incorrect Password");
      setSuccess(false);
    } else {
      setMessage("Something went wrong");
      setSuccess(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
      setIncorrectPassword("");
    }, 2000);
    return () => clearTimeout(timer);
  }, [message, success, incorrectPassword]);

  const changePassword = () => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Change Password
          </Typography>
          <Formik
            initialValues={{
              password: "",
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={passwordChangeSchema}
            onSubmit={(value: any) => {
              const payload = {
                email: email,
                password: value.password,
                newPassword: value.newPassword,
              };

              execute(payload, handleResponse);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextInputLight
                  type={TextFieldType.PASSWORD}
                  name={"password"}
                  label={"Password"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  textFieldHeigt={60}
                  error={
                    errors["password"] &&
                    touched["password"] &&
                    errors["password"]
                      ? true
                      : false
                  }
                  helperText={
                    errors["password"] &&
                    touched["password"] &&
                    errors["password"]
                  }
                />
                <TextInputLight
                  type={TextFieldType.PASSWORD}
                  name={"newPassword"}
                  label={"New Password"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  textFieldHeigt={60}
                  error={
                    errors["newPassword"] &&
                    touched["newPassword"] &&
                    errors["newPassword"]
                      ? true
                      : false
                  }
                  helperText={
                    errors["newPassword"] &&
                    touched["newPassword"] &&
                    errors["newPassword"]
                  }
                />
                <TextInputLight
                  type={TextFieldType.PASSWORD}
                  name={"confirmPassword"}
                  label={"Confirm New Password"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  textFieldHeigt={60}
                  error={
                    errors["confirmPassword"] &&
                    touched["confirmPassword"] &&
                    errors["confirmPassword"]
                      ? true
                      : false
                  }
                  helperText={
                    errors["confirmPassword"] &&
                    touched["confirmPassword"] &&
                    errors["confirmPassword"]
                  }
                />
                <Box
                  mb={2}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alignItems={"flex-end"}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2, mr: 2 }}
                  >
                    Change Password
                  </Button>
                </Box>
                {incorrectPassword && (
                  <Alert severity={"error"}>{incorrectPassword}</Alert>
                )}
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    );
  };

  return (
    <AdminLayout>
      <DashboardContainer>
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          flexDirection={"row"}
          sx={{ borderRadius: 5, boxShadow: 2, p: 2 }}
        >
          <Loader open={loading} />
          {changePassword()}
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Avatar
              alt={firstName}
              src="/static/images/avatar/2.jpg"
              style={{ color: "black" }}
              sx={{
                height: 100,
                width: 100,
                fontSize: 50,
              }}
            />
            <Typography fontSize={20} pt={2}>
              {role}
            </Typography>
            <Formik
              initialValues={{
                firstName: firstName,
                lastName: lastName,
                email: email,
                designation: designation ? designation : "-",
              }}
              validationSchema={adminUserSchema}
              onSubmit={(value: any) => {
                console.log(value);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                dirty,
              }) => (
                <form onSubmit={handleSubmit}>
                  <CommonGridOnBoarding
                    handleChange={handleChange}
                    value={values}
                    onBlur={handleBlur}
                    error={errors}
                    list={admimUserDetails}
                    touched={touched}
                    paddingTop={{ xs: 2, md: 4 }}
                  />
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                    flexDirection={"row"}
                  >
                    {/* <Button
                      type="submit"
                      variant="contained"
                      disabled={!dirty}
                      sx={{ mt: 2, mr: 2 }}
                    >
                      Update Profile
                    </Button> */}
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
        <Box>
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            flexDirection={"row"}
            mt={2}
          >
            <Button onClick={() => handleOpen()}>Change Password</Button>
          </Box>
          {message && (
            <Alert severity={success ? "success" : "error"}>{message}</Alert>
          )}
        </Box>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default Profile;
