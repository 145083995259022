import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import Loader from "../../../core/components/Loader";
import { getComplaintsByUser } from "../../../store/Application/applicationService";
import { FontWeight } from "../../../core/types";
import ComplaintsPopUp from "./Components/ComplaintsPopUp";

const Complaints = () => {
  const user = useSelector((state: any) => state.auth.user);

  const [complaintsList, setComplaints] = useState<any>([]);

  const { execute, loading } = useAsyncRequest(getComplaintsByUser);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      setComplaints(data?.complaints);
    }
  };


  const [open, setOpen] = useState<any>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const payload = {
      userEmail: user.email,
    };
    execute(payload, handleResponse);
  }, [open]);


  return (
    <AdminLayout>
      <DashboardContainer>
        <ComplaintsPopUp
          open={open}
          handleClose={handleClose}
          title={"Raise a Complaint"}
        />
        <Loader open={loading} />
        <Box display="flex" flexDirection="column" height="100%">
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography mt={2} fontSize={20} fontWeight="bold">
              My Complaints
            </Typography>
            <Button
              onClick={() => {
                handleClickOpen();
              }}
              variant="outlined"
            >
              Raise A Complaint
            </Button>
          </Box>
          {complaintsList &&
            complaintsList?.map((item: any) => {
              return (
                <Box boxShadow={1} p={2} mt={2} borderRadius={2}>
                  <Typography fontWeight={FontWeight.BOLD} mb={1}>Complaint Id : {item?._id}</Typography>
                  <Typography fontWeight={FontWeight.BOLD} mb={1}>Subject : {item?.subject}</Typography>
                  <Typography fontWeight={FontWeight.BOLD}>{item?.body}</Typography>
                </Box>
              );
            })}
        </Box>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default Complaints;
