import * as React from "react";
import Button from "@mui/material/Button";
import Loader from "../../../../core/components/Loader";
import useAsyncRequest from "../../../../networking/useAsyncRequest";
import { acceptAuditor } from "../../../../store/Application/applicationService";
import { Box, Modal, Typography } from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 2,
  borderRadius: '2rem'
};

const AuditorAccept = ({ applicationId, onSuccess }: any) => {
  const [open, setOpen] = React.useState(false);

  const { execute, loading } = useAsyncRequest(acceptAuditor);

  const handleReponse = (res: any) => {
    const { status } = res;
    if (status == 200) {
      handleClose();
      onSuccess();
    }
  };

  const handleSubmit = () => {
    const payload = {
      applicationId: applicationId,
    };
    execute(payload, handleReponse);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
    <Button variant="outlined" onClick={handleClickOpen}>
      Accept
    </Button>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Loader open={loading} />
        <Typography id="modal-title" variant="h6" component="h2">
          Please Confirm
        </Typography>
        <Typography id="modal-description" sx={{ mb: 2, textAlign: 'center' }}>
          Are you sure you want to accept?
        </Typography>
        <Button variant="contained" onClick={handleSubmit} fullWidth sx={{borderRadius: '2rem'}}>
          Submit
        </Button>
        <Button variant="outlined" onClick={handleClose} fullWidth sx={{borderRadius: '2rem'}}>
          Close
        </Button>
      </Box>
    </Modal>
  </React.Fragment>
  );
};

export default AuditorAccept;
