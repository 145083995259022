import {
  Box,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FontWeight } from "../../../core/types";

const ManDayDetails = ({ responseData }: any) => {
  const row = (
    titleOne: any,
    subTitleOne: any,
    titleTwo: any,
    subTitleTwo: any
  ) => {
    return (
      <TableRow>
        <TableCell align="center">
          <Box>
            <Typography>{titleOne}</Typography>
            <Typography>{subTitleOne}</Typography>
          </Box>
        </TableCell>
        <TableCell align="center">
          <Box>
            <Typography>{titleTwo}</Typography>
            <Typography>{subTitleTwo}</Typography>
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box>
      <Box>
        <TableContainer>
          <Table
            sx={{
              minWidth: 650,
              width: "100%",
              mb: 4,
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">StageOne ManDays</TableCell>
                <TableCell align="center">Stage Two/Renewal Mandays</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row(
                "Stage 1 Manday(s)",
                responseData.stage1Manday,
                "Stage 2 Manday(s)",
                responseData.stage2Manday
              )}
              {row(
                "Stage 1 Onsite",
                responseData.stage1Onsite,
                "Stage 2 / Renewal Onsite",
                responseData.stage2Onsite
              )}
              {row(
                "Audit Preparation OffSite",
                responseData.stageOneauditPreparationOffsite,
                "Audit Preparation OffSite",
                responseData.stageTwoauditPreparetionOffsite
              )}
              {row(
                "Audit Reporting OffSite",
                responseData.stageOneAuditReportingOffsite,
                "Audit Reporting Offsite",
                responseData.stageTwoAuditReportingOffsite
              )}
              <Typography pl={10} fontWeight={FontWeight.BOLD}>
                Surveillance Mandays
              </Typography>
              {row(
                "Surveillance Manday(s) (Overall)",
                responseData.surveillanceMandayOverall,
                "Surveillance Manday(s) (Each)",
                responseData.surveillanceOnsiteEach
              )}
              {row("Surveillance Onsite (Each)", "N/A", "", "")}
              <Typography pl={10} fontWeight={FontWeight.BOLD}>
                Surveillance Offsite (Each)
              </Typography>
              {row("Audit Preparation", "N/A", "Audit Reporting", "N/A")}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ManDayDetails;
