import * as React from "react";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  Alert,
  AppBar,
  Box,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  Modal,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";

import { useState } from "react";
import TextInput from "../../../../core/components/Input/TextInput";
import Loader from "../../../../core/components/Loader";
import {
  addAuditorSchema,
  addCertificateSchema,
  addEmailSchema,
} from "../../../../core/validations/formSchema";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import useAsyncRequest from "../../../../networking/useAsyncRequest";
import { registerComplaintEmails, userRegistration } from "../../../../store/Auth/authService";
import { toCamelCase } from "../../../../core/utils";
import { addCertification } from "../../../../store/Oboarding/cetificationService";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddUpdateCertifications = ({ trigger }: any) => {
  const [open, setOpen] = useState<any>(false);
  const [openAlert, setOpenAlert] = useState<any>(false);
  const [response, setResponse] = useState<any>({});
  const [checked, setChecked] = useState(false);

  const handleDisable = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { execute, loading } = useAsyncRequest(registerComplaintEmails);

  const onSubmit = async (data: any) => {
    await execute(data, handleResponse);
  };

  const handleResponse = (res: any) => {
    const { status } = res;
    if (status == 200) {
      setOpenAlert(true);
      setOpen(false);
      trigger({ data: "ajsdahgsf" });
    } else {
      setOpenAlert(true);
      setResponse({});
    }
  };

  const onClose = () => {
    setOpenAlert(false);
  };

  const alert = () => {
    return (
      <Modal
        open={openAlert}
        onClose={onClose}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        closeAfterTransition
      >
        <Box sx={style}>
          <Alert severity={response.status !== 200 ? "error" : "success"}>
            {response.status === 200 ? "Email Id added Successfully" :
              response.status === 409 ? "Email Id Already Added" : "Something went wrong"
              }
          </Alert>
        </Box>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen}>
        <ControlPointRoundedIcon
          sx={{ height: 35, width: 35, color: "green" }}
        />
      </IconButton>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Email Id
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          p={5}
        >
          {/* {alert()} */}
          <Loader open={loading} />
          <Formik
            initialValues={{
              firstName : "",
              lastName : "",
              emailId : "",
            }}
            validationSchema={addEmailSchema}
            onSubmit={(value) => {
              const payload = {
                ...value
              };
            
                onSubmit(payload);
            
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box width={420}>
                  <TextInput
                    label="Enter First Name"
                    onChange={handleChange}
                    name="firstName"
                    value={values.firstName}
                    onBlur={handleBlur}
                    error={
                      errors.firstName && touched.firstName && errors.firstName
                        ? true
                        : false
                    }
                    helperText={errors.firstName && touched.firstName && errors.firstName}
                    textFieldHeigt={40}
                  />
                  <TextInput
                    label="Enter Last Name"
                    onChange={handleChange}
                    name="lastName"
                    value={values.lastName}
                    onBlur={handleBlur}
                    error={
                      errors.lastName && touched.lastName && errors.lastName
                        ? true
                        : false
                    }
                    helperText={errors.lastName && touched.lastName && errors.lastName}
                    textFieldHeigt={40}
                  />
                  <TextInput
                    label="Enter Email Id"
                    onChange={handleChange}
                    name="emailId"
                    value={values.emailId}
                    onBlur={handleBlur}
                    error={
                      errors.emailId && touched.emailId && errors.emailId
                        ? true
                        : false
                    }
                    helperText={errors.emailId && touched.emailId && errors.emailId}
                    textFieldHeigt={40}
                  />
                </Box>
                <Box
                  pt={3}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Button type="submit" variant="contained">
                    Add
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default AddUpdateCertifications;
