import AdminLayout from "../../components/AdminLayout";
import DashboardContainer from "../../../../core/components/DashboardContainer";
import { Box, Typography } from "@mui/material";

const Payments = () => {
  return (
    <AdminLayout>
      <DashboardContainer>
        <Box
          sx={{
            color: "#a8a8a8",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
            textAlign: "center",
          }}
        >
          <Typography variant="h2">No Payments yet</Typography>
        </Box>
      </DashboardContainer>
    </AdminLayout>
  );
};
export default Payments;
