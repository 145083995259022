// import AdminLayout from "../../dashboard/components/AdminLayout";
// import DashboardContainer from "../../../core/components/DashboardContainer";
// import useAsyncRequest from "../../../networking/useAsyncRequest";
// import {
//   getAuditPlainByApplicationId,
//   getMessage,
//   sendMessage,
// } from "../../../store/AuditorFlow/auditorListService";
// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { Box, Button, Typography } from "@mui/material";
// import { FontWeight } from "../../../core/types";
// import Loader from "../../../core/components/Loader";
// import TextInputTextField from "../../../core/components/Input/TextInputTextField";

// const Communication = () => {
//   const user = useSelector((state: any) => state.auth.user);
//   const details = useSelector((state: any) => state.onBoarding.details);
//   const [chat, setChat] = useState<any>([]);
//   const [message, setMessage] = useState("");
//   const [senderId, setSenderId] = useState("");

//   const { application } = details;
//   const { applicationId } = application;

//   const { execute: exeMessage, loading: getMesageLoading } =
//     useAsyncRequest(getMessage);

//   const { execute, loading } = useAsyncRequest(sendMessage);

//   const handleResponse = (res: any) => {
//     const { status, data } = res;
//     if (status == 200) {
//       const payload = {
//         applicationId: applicationId,
//       };
//       exeMessage(payload, getMessageResponse);
//     }
//   };
//   useEffect(() => {
//     const payload = {
//       applicationId: applicationId,
//     };
//     exeMessage(payload, getMessageResponse);
//   }, []);

//   const getMessageResponse = (res: any) => {
//     const { status, data } = res;
//     if (status === 200) {
//       if (data.length != 0) {
//         setChat(data);
//         setSenderId(data[0].auditorId);
//       }
//     }
//   };

//   return (
//     <AdminLayout>
//       <DashboardContainer>
//         <Loader open={getMesageLoading || loading} />
//         <Box>
//           <Typography mt={4} fontSize={20} fontWeight={FontWeight.BOLD}>
//             Communication Messages
//           </Typography>
//           <Box mt={2} sx={{ height: 300, overflow: "auto" }}>
//             {chat &&
//               chat?.map((message: any) => {
//                 return (
//                   <Box pt={2}>
//                     <Typography fontWeight={FontWeight.BOLD}>
//                       {message.sendersName} :{" "}
//                     </Typography>
//                     <Typography>{message.message}</Typography>
//                   </Box>
//                 );
//               })}
//             <TextInputTextField
//               label="Enter Message"
//               onChange={(val: any) => {
//                 setMessage(val.target.value);
//               }}
//               mt={4}
//               labelHeight={0}
//               multiline
//               width={500}
//               row={3}
//             />
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={() => {
//                 const { email, firstName, lastName } = user;
//                 const payload = {
//                   applicationId: applicationId,
//                   auditorId: senderId,
//                   sendersName: firstName + " " + lastName,
//                   reciversId: senderId,
//                   message: message,
//                 };

//                 if (message) {
//                   execute(payload, handleResponse);
//                 }
//               }}
//               sx={{ mt: 10 }}
//             >
//               Send
//             </Button>
//           </Box>
//         </Box>
//       </DashboardContainer>
//     </AdminLayout>
//   );
// };

// export default Communication;

import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import {
  getMessage,
  sendMessage,
} from "../../../store/AuditorFlow/auditorListService";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Typography, Paper, TextField, IconButton } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Loader from "../../../core/components/Loader";

const Communication = () => {
  const user = useSelector((state: any) => state.auth.user);
  const details = useSelector((state: any) => state.onBoarding.details);
  const [chat, setChat] = useState<any>([]);
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState<File | null>(null);
  const [senderId, setSenderId] = useState("");
  const chatEndRef = useRef<HTMLDivElement>(null);

  const { application } = details;
  const { applicationId } = application;

  const { execute: exeMessage, loading: getMesageLoading } =
    useAsyncRequest(getMessage);
  const { execute, loading } = useAsyncRequest(sendMessage);

  const handleResponse = (res: any) => {
    const { status } = res;
    if (status === 200) {
      const payload = {
        applicationId: applicationId,
      };
      exeMessage(payload, getMessageResponse);
    }
  };

  useEffect(() => {
    const payload = {
      applicationId: applicationId,
    };
    exeMessage(payload, getMessageResponse);
  }, []);

  const getMessageResponse = (res: any) => {
    const { status, data } = res;
    if (status === 200 && data.length !== 0) {
      setChat(data);
      setSenderId(data[0].auditorId);
      scrollToBottom();
    }
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSendMessage = () => {
    const { firstName, lastName } = user;
    const payload = {
      applicationId: applicationId,
      auditorId: senderId,
      sendersName: `${firstName} ${lastName}`,
      reciversId: senderId,
      message: message,
      attachment: attachment,
    };

    if (message.trim() || attachment) {
      execute(payload, handleResponse);
      setMessage("");
      setAttachment(null); 
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setAttachment(file);
  };

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={getMesageLoading || loading} />
        <Box display="flex" flexDirection="column" height="100%">
          <Typography mt={4} fontSize={20} fontWeight="bold">
            Communication Messages
          </Typography>
          <Box
            flexGrow={1}
            sx={{
              mt: 2,
              p: 2,
              overflowY: "auto",
              backgroundColor: "#e5ddd5",
              borderRadius: 2,
            }}
          >
            {chat.map((message: any, index: number) => {
              const isUserMessage = message.auditorId === senderId;
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: isUserMessage ? "flex-end" : "flex-start",
                    mb: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#555",
                      alignSelf: "flex-start",
                      mb: 0.5,
                    }}
                  >
                    {message.sendersName}
                  </Typography>
                  <Paper
                    elevation={2}
                    sx={{
                      p: 1.5,
                      maxWidth: "60%",
                      borderRadius: "15px",
                      backgroundColor: isUserMessage ? "#DCF8C6" : "#fff",
                    }}
                  >
                    <Typography variant="body1">{message.message}</Typography>
                    {message.attachment && (
                      <Box mt={1}>
                        <a href={message.attachment} target="_blank" rel="noopener noreferrer">
                          <Typography variant="body2" color="blue">
                            View Attachment
                          </Typography>
                        </a>
                      </Box>
                    )}
                    <Typography
                      variant="caption"
                      sx={{
                        display: "block",
                        textAlign: "right",
                        color: "#888",
                        mt: 0.5,
                      }}
                    >
                      {new Date(message.timestamp).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </Typography>
                  </Paper>
                </Box>
              );
            })}
            <div ref={chatEndRef} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fff",
              p: 1,
              borderRadius: "0 0 15px 15px",
              boxShadow: "0 -1px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            <input
              accept="*/*"
              style={{ display: 'none' }}
              id="attachment-input"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="attachment-input">
              <IconButton
                color="primary"
                component="span"
                sx={{
                  backgroundColor: "#128C7E",
                  color: "#fff",
                  '&:hover': {
                    backgroundColor: "#075E54",
                  },
                  mr: 1,
                }}
              >
                <AttachFileIcon />
              </IconButton>
            </label>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type a message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              sx={{
                mr: 1,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px',
                },
              }}
            />
            <IconButton
              color="primary"
              onClick={handleSendMessage}
              disabled={!message.trim() && !attachment}
              sx={{
                backgroundColor: "#128C7E",
                color: "#fff",
                '&:hover': {
                  backgroundColor: "#075E54",
                },
              }}
            >
              <SendIcon />
            </IconButton>
          </Box>
        </Box>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default Communication;
