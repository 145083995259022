import { Backdrop, Box, CircularProgress } from "@mui/material";

const Loader = ({ open }: any) => {
  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Loader;
