import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useGoogleLogout } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../store/Auth/authSlice';
import { Box, Button, Modal, Typography } from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

interface AutoLogoutProps {
  children: React.ReactNode;
}

const AutoLogout: React.FC<AutoLogoutProps> = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.auth.token);

  const clientId =
    "830136945700-4sukjrick8hl7m10rlj7al7jhgfmntvo.apps.googleusercontent.com";

    const onLogoutSuccess = () => {
        console.log("Logout successful");
      };
    
      const onFailure = () => {
        console.log("Logout failed");
      };

  const logoutTimer = useRef<NodeJS.Timeout | null>(null);
  const modalLogoutTimer = useRef<NodeJS.Timeout | null>(null);

  const { signOut, loaded } = useGoogleLogout({
    clientId: clientId,
    onLogoutSuccess,
    onFailure,
    cookiePolicy: "single_host_origin",
    fetchBasicProfile: false, 
    accessType: "offline",
    scope: "",
  });

  const confirmLogout = useCallback(() => {
    setModalOpen(false);
    signOut();
    dispatch(logout());
    navigate("/");
  }, [navigate, signOut, dispatch]);

  const logoutUser = useCallback(() => {
    setModalOpen(true);

    // Start a secondary timer for auto-logout after 10 seconds if no action is taken
    if (modalLogoutTimer.current) {
      clearTimeout(modalLogoutTimer.current);
    }
    modalLogoutTimer.current = setTimeout(confirmLogout, 20000); // Auto-logout after 20 seconds
  }, [confirmLogout]);

  const handleStayLoggedIn = () => {
    setModalOpen(false);
    if (modalLogoutTimer.current) {
      clearTimeout(modalLogoutTimer.current); // Clear the modal timer if the user stays logged in
    }
    resetTimer();
  };

  const resetTimer = useCallback(() => {
    if(token){
    if (logoutTimer.current) {
      clearTimeout(logoutTimer.current);
    }
    logoutTimer.current = setTimeout(logoutUser, 300000);
}
  }, [logoutUser, token]);

  useEffect(() => {
    resetTimer();

    const activityEvents: (keyof WindowEventMap)[] = ['mousemove', 'keydown', 'scroll', 'click'];
    const handleActivity = () => resetTimer();

    activityEvents.forEach((event) =>
      window.addEventListener(event, handleActivity)
    );

    return () => {
      activityEvents.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
      if (logoutTimer.current) {
        clearTimeout(logoutTimer.current);
      }
      if (modalLogoutTimer.current) {
        clearTimeout(modalLogoutTimer.current); // Clear modal timer on unmount
      }
    };
  }, [resetTimer]);

  return <>
    {children}
    <Modal
        open={modalOpen}
        onClose={confirmLogout}
        aria-labelledby="auto-logout-modal"
        aria-describedby="auto-logout-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 345,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '25px',
          }}
        >
          <WarningRoundedIcon htmlColor='#f39c12' sx={{fontSize: '3rem'}} />
           <Typography variant='h4' pb={2}>Session Timeout</Typography>
          <Typography variant='body2' pb={2}>Your sesion will expire in 20 seconds.</Typography>
          <Typography variant='body2' pb={2}>Would you like to continue?</Typography>
         <Box pb={1}>
          <Button onClick={confirmLogout} variant="outlined" color="primary" sx={{width: '100%'}}>
            Sign me out
          </Button>
          </Box>
          <Box>
          <Button onClick={handleStayLoggedIn} variant="contained" color="primary" sx={{ width: '100%'}}>
            Stay Logged In
          </Button>
          </Box>
        </Box>
      </Modal>
  </>;
};

export default AutoLogout;
