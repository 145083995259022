import { Box, Button, Typography } from "@mui/material";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import { FontWeight } from "../../../core/types";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { stageOneAuditCheckListA } from "../../../core/validations/formSchema";
import { useEffect, useState } from "react";
import {
  applicableStandradsTechincalRequirement,
  halalFoodProductEvaluationChecklist,
  primaryProduction,
  location as loca,
  equipments,
  facilities,
  controlOfOperations,
  transportation,
  producInformationAndConsumerRequirements,
  trainingRequirements,
  personaleHygenine,
  maintainanceAndSanitations,
  hazardAnalysesAndCriticalControlPoints,
  keyElementsOfFoodSafetyManagement,
  premisesAndRooms,
} from "./constants";
import ThreeCheckComponent from "../components/threeCheckComponent";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { auditCheckListFill } from "../../../store/AuditorFlow/auditorListService";
import Loader from "../../../core/components/Loader";
import ThreeCheckComponentWithFeild from "../components/threeCheckComponentWithField";
import CheckRemarkComponent from "../components/CheckRemarksComponent";
import { REQUIREDLIST } from "../../../router/config";
import LabelCheckBox from "../components/labelCheckBox";

const AuditCheckList = () => {
  const navigate = useNavigate();
  const [checkList, setCheckList] = useState<any>({});
  const [checkItem, setCheckItem] = useState<any>({});
  const location = useLocation();
  const { data: applicationData, item, id, listId } = location.state;

  console.log(item, "asagasjdfaj");

  useEffect(() => {
    switch (item) {
      case "Halal Food Products Evaluation Checklist":
        setCheckList(halalFoodProductEvaluationChecklist);
        setCheckItem(1);
        break;
      case "Applicable Standard and Applicable Technical Requirement":
        setCheckList(applicableStandradsTechincalRequirement);
        setCheckItem(2);
        break;
      case "1.0 PRIMARY PRODUCTION":
        setCheckList(primaryProduction);
        setCheckItem(3);
        break;
      case "2.0. LOCATION":
        setCheckList(loca);
        setCheckItem(4);
        break;
      case "4.0. EQUIPMENT":
        setCheckList(equipments);
        setCheckItem(5);
        break;
      case "5.0. FACILITIES":
        setCheckList(facilities);
        setCheckItem(6);
        break;
      case "6.0. CONTROL OF OPERATION REQUIREMENTS":
        setCheckList(controlOfOperations);
        setCheckItem(7);
        break;
      case "7.0. ESTABLISHMENT MAINTENANCE AND SANITATION":
        setCheckList(maintainanceAndSanitations);
        setCheckItem(8);
        break;
      case "8.0. ESTABLISHMENT: PERSONNEL HYGIENE REQUIREMENTS":
        setCheckList(personaleHygenine);
        setCheckItem(9);
        break;
      case "9.0. TRANSPORTATION":
        setCheckList(transportation);
        setCheckItem(10);
        break;
      case "10.0. PRODUCT INFORMATION AND CONSUMER AWARENESS REQUIREMENTS":
        setCheckList(producInformationAndConsumerRequirements);
        setCheckItem(11);
        break;
      case "11.0. TRAINING REQUIREMENTS":
        setCheckList(trainingRequirements);
        setCheckItem(12);
        break;
      case "HAZARD ANALYSES AND CRITICAL CONTROL POINTS (HACCP)":
        setCheckList(hazardAnalysesAndCriticalControlPoints);
        setCheckItem(13);
        break;
      case "KEY ELEMENTS OF THE FOOD SAFETY MANAGEMENT SYSTEM":
        setCheckList(keyElementsOfFoodSafetyManagement);
        setCheckItem(14);
        break;
      case "3.0. PREMISES AND ROOMS":
        setCheckList(premisesAndRooms);
        setCheckItem(15);
        break;
    }
  }, [navigate]);

  const { execute, loading } = useAsyncRequest(auditCheckListFill);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      console.log(data);
      navigate(REQUIREDLIST, {
        replace: true,
        state: { data: applicationData },
      });
    }
  };

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={loading} />
        <Formik
          initialValues={{}}
          validationSchema={stageOneAuditCheckListA}
          onSubmit={(value: any) => {
            let list: any = [];
            Object.values(value).forEach((val: any) => {
              list.push(val);
            });
            let checkType = "";
            if (checkItem == 1 || checkItem == 2) {
              checkType = "checkList";
            } else {
              checkType = "fmsChecklist";
            }
            const payload = {
              id: id,
              listId: listId,
              checkedList: list,
              checkType: checkType,
            };

            execute(payload, handleResponse);
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Typography
                variant="h5"
                fontWeight={FontWeight.MEDIUM}
                pl={2}
                pt={3}
              >
                {checkList.heading}
              </Typography>
              {checkList?.checkList?.map((list: any) => {
                return (
                  <Box>
                    <Typography
                      variant="h6"
                      fontWeight={FontWeight.MEDIUM}
                      pl={2}
                      pt={3}
                    >
                      {list.checklistHeading}
                    </Typography>
                    {list.list.length !== 0 &&
                      (list.type === "one" ? (
                        <ThreeCheckComponent
                          heading={list.checklistHeading}
                          fieldName={list.headName}
                          rows={list.list}
                          setFieldValue={setFieldValue}
                        />
                      ) : list.type === "two" ? (
                        <ThreeCheckComponentWithFeild
                          fieldName={list.headName}
                          rows={list.list}
                          heading={list.checklistHeading}
                          setFieldValue={setFieldValue}
                        />
                      ) : list.type === "three" ? (
                        <CheckRemarkComponent
                          fieldName={list.headName}
                          rows={list.list}
                          heading={list.checklistHeading}
                          setFieldValue={setFieldValue}
                        />
                      ) : list.type === "CHECK" ? (
                        <LabelCheckBox
                          setFieldValue={setFieldValue}
                          rows={list.list}
                          heading={list.checklistHeading}
                          fieldName={list.headName}
                        />
                      ) : null)}
                  </Box>
                );
              })}
              <Box
                pt={7}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                width={"85%"}
              >
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default AuditCheckList;
