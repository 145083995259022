import { Box, Typography } from "@mui/material";
import DashboardContainer from "../../../../core/components/DashboardContainer";
import AdminLayout from "../../../dashboard/components/AdminLayout";
import { useLocation } from "react-router-dom";
import { FontWeight } from "../../../../core/types";

const CheckListView = () => {
  const location = useLocation();
  const { item } = location.state;

  const { checkedList } = item;

  return (
    <AdminLayout>
      <DashboardContainer>
        <Box>
          {checkedList?.map((item: any) => {
            return (
              <Box>
                <Typography
                  variant="h5"
                  fontWeight={FontWeight.MEDIUM}
                  pl={2}
                  pt={3}
                  pb={3}
                >
                  {item.heading}
                </Typography>
                {item?.list?.map((val: any) => {
                  return (
                    <Box>
                      {val != null && (
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          pr={2}
                          alignItems={"center"}
                          sx={{ backgroundColor: "#F5F6FA", borderRadius: 1 }}
                          mt={1}
                          mb={1}
                          pb={2}
                        >
                          <Box>
                            <Typography
                              fontSize={18}
                              fontWeight={FontWeight.MEDIUM}
                              pl={2}
                              pt={3}
                            >
                              {val?.label}
                            </Typography>
                            {val?.objectEvidence && (
                              <Typography
                                fontWeight={FontWeight.MEDIUM}
                                pl={2}
                                pt={3}
                              >
                                Object Evidence : {val?.objectEvidence}
                              </Typography>
                            )}
                            {val?.remarks && (
                              <Typography
                                fontWeight={FontWeight.MEDIUM}
                                pl={2}
                                pt={3}
                              >
                                Remarks : {val?.remarks}
                              </Typography>
                            )}
                            {val?.followUpFeedback && (
                              <Typography
                                fontWeight={FontWeight.MEDIUM}
                                pl={2}
                                pt={3}
                              >
                                Follow Up feedback : {val?.followUpFeedback}
                              </Typography>
                            )}
                          </Box>
                          {val?.value && (
                            <Typography
                              fontWeight={FontWeight.MEDIUM}
                              pl={2}
                              pt={3}
                              color={
                                val?.value === "pass"
                                  ? "green"
                                  : val?.value === "fail"
                                  ? "red"
                                  : "black"
                              }
                            >
                              {val?.value.toUpperCase()}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default CheckListView;
