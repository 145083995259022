export const auditorList = [
  {
    name: "Alphose P",
    email: "alphose@gmail.com",
    designation: "QMS Auditor",
  },
  {
    name: "John Kenny",
    email: "johnk@gmail.com",
    designation: "Technical Auditor",
  },
  {
    name: "Sachin H",
    email: "schanin@gmail.com",
    designation: "Technical Auditor",
  },
  {
    name: "Rahul K",
    email: "rahul@gmail.com",
    designation: "QMS Auditor",
  },
];

export const designationList = [
  {
    id: "qms",
    label: "QMS Auditor",
  },
  {
    id: "technical",
    label: "Technical Auditor",
  },
];

export const roleList = [
  {
    id: "ADMIN",
    label: "Admin",
  },
  {
    id: "AUDITOR",
    label: "Auditor",
  },
  {
    id: "DECISION",
    label: "Decision",
  },
];
