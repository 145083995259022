import { Route, Routes, Navigate } from "react-router-dom";
import * as RouthPath from "./config";
import { useSelector } from "react-redux";
import LandingPage from "../features/auth/LandingPage";
import SignIn from "../features/auth/SignIn";
import SignUpPage from "../features/auth/SignUp";
import ForgotPassword from "../features/auth/ForgetPassword";
import OnBoarding from "../features/onBoarding";
import CompanyDetails from "../features/onBoarding/Pages/CompayDetails";
import Quotation from "../features/onBoarding/Pages/Quotation";
import Review from "../features/onBoarding/Pages/Review";
import TermsAndConditions from "../features/onBoarding/Pages/TermAndConditions";
import Dashboard from "../features/dashboard";
import ApplicationDetail from "../features/applicationDetailScreen";
import CreateQuotation from "../features/createQuotationScreen";
import AdminReview from "../features/adminReview";
import ReviewStatus from "../features/reviewStatus";
import AuditPlaning from "../features/auditPlaning";
import AuditorList from "../features/auditor";
import ApplicationList from "../features/applicationList";
import ApplicationStatus from "../features/applicationStatus";
import StageOneCheckList from "../features/audiorFlow/StageOneCheckList";
import StageOneCheckListB from "../features/audiorFlow/StageOneCheckListB";
import RequiredCheckList from "../features/audiorFlow/RequiredCheckList";
import AssessmentFindings from "../features/audiorFlow/AssessmentFindings";
import OpenCloseMeetingInstructions from "../features/audiorFlow/openCloseMeetingInstructions";
import AuditFindings from "../features/audiorFlow/AuditFindings";
import DecisionApplicationDetailView from "../features/dashboard/components/DecisionLayout";
import RequiredList from "../features/audiorFlow/RequiredList";
import AuditReport from "../features/audiorFlow/AuditReport";
import AuditDarft from "../features/audiorFlow/AuditDarft";
import AuditCheckList from "../features/audiorFlow/AuditCheckList";
import CheckListView from "../features/audiorFlow/RequiredList/CheckListView";
import AuditorDashboard from "../features/dashboard/AuditorDashboard";
import AuditInProgress from "../features/audiorFlow/AuditInProgress";
import UpcommingAudit from "../features/audiorFlow/UpcomingAudits";
import AuditorNotifications from "../features/audiorFlow/Notifications";
import Profile from "../features/audiorFlow/Profile";
import DecisionDashboard from "../features/dashboard/DecisionDashboard";
import AuditCompleted from "../features/decisionFlow/AuditCompleted";
import DraftCreated from "../features/decisionFlow/DraftCreated";
import UserApplicationProgressDashboard from "../features/dashboard/userDashboard";
import AuditSchedule from "../features/userFlow/AuditSchedule";
import Communication from "../features/userFlow/Communication";
import AllApplications from "../features/AdminFlow/AllApplications";
import AllCertificationList from "../features/AdminFlow/CertificationList";
import AuditorCheckList from "../features/AdminFlow/AuditorCheckList";
import ApplicationSummary from "../features/dashboard/userDashboard/component/ApplicationSummary";
import ReviewSummary from "../features/dashboard/userDashboard/component/ReviewSummary";
import Payments from "../features/dashboard/userDashboard/component/Payments";
import ViewCert from "../features/dashboard/userDashboard/component/ViewCert";
import AuditFindingsUserView from "../features/dashboard/userDashboard/component/AuditFindingsView";
import RenewalApplications from "../features/applications/renewal";
import AdminList from "../features/users/Admin";
import DecisionList from "../features/users/Decision";
import ClientList from "../features/users/Client";
import UserUpdate from "../features/users/UserUpdates";
import OTPScreen from "../features/auth/ForgetPassword/otpScreen";
import CertificateUpload from "../features/decisionFlow/CertificateUpload";
import UserCertificates from "../features/userFlow/UserCertificates";
import Feedbacks from "../features/userFlow/Feedbacks";
import Complaints from "../features/userFlow/Complaints";
import AllComplaintsList from "../features/AdminFlow/AllComplaintsList";
import ComplaintsEmailList from "../features/AdminFlow/ComplaintsEmailList";
import ResetPasswordScreen from "../features/auth/ForgetPassword/resetPasswordScreen";

const AppRoutes = () => {
  const token = useSelector((state: any) => state.auth.token);
  return (
    <Routes>
      <Route path={RouthPath.ROOT} element={<LandingPage />} />
      <Route path={RouthPath.SIGNUP} element={<SignUpPage />} />
      <Route path={RouthPath.SIGNIN} element={<SignIn />} />
      <Route path={RouthPath.FORGOTPASSWORD} element={<ForgotPassword />} />
      <Route path={RouthPath.OTPSCREEN} element={<OTPScreen />} />
      <Route path={RouthPath.RESETPASSWORD} element={<ResetPasswordScreen />} />
      <Route path="*" element={<Navigate to="/" replace />} />
      {token && (
        <>
          <Route path={RouthPath.CERTIFICATION} element={<OnBoarding />} />
          <Route path={RouthPath.COMPANYDETAILS} element={<CompanyDetails />} />
          <Route path={RouthPath.QUOTATION} element={<Quotation />} />
          <Route path={RouthPath.REVIEW} element={<Review />} />
          <Route
            path={RouthPath.TERMSCONDITIONS}
            element={<TermsAndConditions />}
          />
          <Route path={RouthPath.DASHBOARD} element={<Dashboard />} />
          <Route
            path={RouthPath.AUDITORDASHBOARD}
            element={<AuditorDashboard />}
          />
          <Route
            path={RouthPath.AUDITORAPPLICATIONINPROGRESS}
            element={<AuditInProgress />}
          />
          <Route
            path={RouthPath.UPCOMMINGAUDITS}
            element={<UpcommingAudit />}
          />
          <Route
            path={RouthPath.AUDITORNOTIFICATION}
            element={<AuditorNotifications />}
          />
          <Route
            path={RouthPath.APPLICATIONDETAIL}
            element={<ApplicationDetail />}
          />
          <Route path={RouthPath.PROFILE} element={<Profile />} />
          <Route
            path={RouthPath.CREATEQUOTATION}
            element={<CreateQuotation />}
          />
          <Route path={RouthPath.ADMINREVIEW} element={<AdminReview />} />
          <Route path={RouthPath.REVIEWSTATUS} element={<ReviewStatus />} />
          <Route path={RouthPath.AUDITPLANING} element={<AuditPlaning />} />
          <Route path={RouthPath.AUDITORLIST} element={<AuditorList />} />
          <Route path={RouthPath.ADMINLIST} element={<AdminList />} />
          <Route path={RouthPath.DECISIONLIST} element={<DecisionList />} />
          <Route path={RouthPath.USERLIST} element={<ClientList />} />
          <Route path={RouthPath.USERUPDATE} element={<UserUpdate />} />
          <Route
            path={RouthPath.APPLICATIONLIST}
            element={<ApplicationList />}
          />
           <Route
            path={RouthPath.USERCERTIFICATES}
            element={<UserCertificates />}
          />
          <Route
            path={RouthPath.APPLICATIONSTATUS}
            element={<ApplicationStatus />}
          />
          <Route
            path={RouthPath.STAGEONECHECKLIST}
            element={<StageOneCheckList />}
          />
          <Route
            path={RouthPath.STAGEONECHECKLISTB}
            element={<StageOneCheckListB />}
          />
          <Route
            path={RouthPath.REQUIREDCHECKLIST}
            element={<RequiredCheckList />}
          />
          <Route
            path={RouthPath.ASSESSMENTFINDINGS}
            element={<AssessmentFindings />}
          />
          <Route
            path={RouthPath.OPENCLOSEMEETINGINSTRUSTIONS}
            element={<OpenCloseMeetingInstructions />}
          />
          <Route path={RouthPath.AUDITFINDIGS} element={<AuditFindings />} />
          <Route
            path={RouthPath.DECISIONDASHBOARD}
            element={<DecisionDashboard />}
          />
          <Route path={RouthPath.AUDITCOMPLETED} element={<AuditCompleted />} />
          <Route
            path={RouthPath.DRAFTCREATEDAPPLICATION}
            element={<DraftCreated />}
          />
          <Route
            path={RouthPath.RENEWALAPPLICATIONS}
            element={<RenewalApplications />}
          />
          <Route
            path={RouthPath.DECISIONAPPLICATIONVIEW}
            element={<DecisionApplicationDetailView />}
          />
          <Route
            path={RouthPath.USERDASHBOARD}
            element={<UserApplicationProgressDashboard />}
          />
          <Route
            path={RouthPath.ALLAPPLICATIONS}
            element={<AllApplications />}
          />
          <Route path={RouthPath.COMUNNICATIONS} element={<Communication />} />
          <Route path={RouthPath.FEEDBACKS} element={<Feedbacks />} />
          <Route path={RouthPath.COMPLAINTS} element={<Complaints />} />
          <Route path={RouthPath.ALLCOMPLAINTSLIST} element={<AllComplaintsList />} />
          <Route path={RouthPath.COMPLAINTSEMAILLIST} element={<ComplaintsEmailList />} />
          <Route path={RouthPath.AUDITPLAN} element={<AuditSchedule />} />
          <Route path={RouthPath.REQUIREDLIST} element={<RequiredList />} />
          <Route path={RouthPath.AUDITREPORT} element={<AuditReport />} />
          <Route path={RouthPath.AUDITDRAFT} element={<AuditDarft />} />
          <Route path={RouthPath.ISSUECERTIFICATE} element={<CertificateUpload />} />
          <Route path={RouthPath.AUDITCHECKLIST} element={<AuditCheckList />} />
          <Route
            path={RouthPath.AUDITCHECKLISTVIEW}
            element={<CheckListView />}
          />
          <Route
            path={RouthPath.ALLCERTIFICATIONS}
            element={<AllCertificationList />}
          />
          <Route path={RouthPath.CHECKLIST} element={<AuditorCheckList />} />
          <Route
            path={RouthPath.APPLICATIONSUMMARY}
            element={<ApplicationSummary />}
          />
          <Route path={RouthPath.REVIEWSUMMARY} element={<ReviewSummary />} />
          <Route path={RouthPath.PAYMENTS} element={<Payments />} />
          <Route path={RouthPath.VIEWCERT} element={<ViewCert />} />
          <Route
            path={RouthPath.VIEWAUDITFINDINGS}
            element={<AuditFindingsUserView />}
          />
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;
