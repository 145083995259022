export enum TextVarient {
  H6 = "h6",
}

export enum FontWeight {
  LIGHT = "light",
  REGULAR = "regular",
  MEDIUM = "medium",
  BOLD = "bold",
}

export enum TextFieldType {
  TEXT = "text",
  PASSWORD = "password",
  EMAIL = "email",
  DATE = "date",
}

export enum INPUTFIELDTYPE {
  TEXT = "TEXT",
  CHECKBOX = "CHECKBOX",
  RADIO = "RADIO",
  COMPANYDETAILCHECK = "COMPANYDETAILCHECK",
  DATE = "DATE",
}
