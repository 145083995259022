import { Typography, useMediaQuery } from "@mui/material";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { getActiveApplication } from "../../../store/Dashboard/dashboardService";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import OrderTable from "../../dashboard/AuditorDashboard/components/Recent";
import Loader from "../../../core/components/Loader";
import { createData } from "../../../core/utils";
import { useTheme } from "@mui/material/styles";

const RenewalApplications = () => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.user);
  const { role } = user;
  const [responseData, setResponseData] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { execute: executeAuditorList, loading: auditorListLoading } =
    useAsyncRequest(getActiveApplication);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      let list: any = [];
      const drafted = data.filter(
        (item: any) => item.certification === "renewal"
      );
      setResponseData(drafted);
      drafted.map((item: any) => {
        list.push(
          createData(
            item.applicationNo ? item.applicationNo : "-",
            item.companyDetail.nameOfCompany,
            item.createdDate ? item.createdDate : "-",
            item.certification ? item.certification : "-",
            item.activeStatus ? item.activeStatus : "Active"
          )
        );
      });
      setData(list);
    }
  };

  useEffect(() => {
    executeAuditorList("", handleResponse);
  }, [navigate]);

  return (
    <AdminLayout>
      {!isMobile ? (
        <DashboardContainer>
          <Loader open={auditorListLoading} />
          <Typography variant="h5" mb={3}>Draft created</Typography>
          <OrderTable data={data} resData={responseData} role={role} />
        </DashboardContainer>
      ) : (
        <>
          <Loader open={auditorListLoading} />
          <Typography variant="h5" mb={3}>Draft created</Typography>
          <OrderTable data={data} resData={responseData} role={role} />
        </>
      )}

    </AdminLayout>
  );
};

export default RenewalApplications;
