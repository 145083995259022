import { Box, useMediaQuery, useTheme } from "@mui/material";

const DashboardContainer = ({ children }: any) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  return (
    <Box
      sx={{
        flex: 1,
        flexGrow: 1,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        borderRadius: 5,
        padding: isMobile ? 2 :  3,
        mt: 2,
      }}
    >
      {children}
    </Box>
  );
};
export default DashboardContainer;
