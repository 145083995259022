import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import {
  deleteCertification,
  updateCertification,
} from "../../../store/Oboarding/cetificationService";
import { useEffect, useState } from "react";
import Loader from "../../../core/components/Loader";
import { Box,  Container,  IconButton, Typography } from "@mui/material";
import { FontWeight } from "../../../core/types";
import AddUpdateCertifications from "./addUpdateCerifications";
import { deleteComplaintsEmails, getAllComplaintsEmails } from "../../../store/Auth/authService";

const ComplaintsEmailList = () => {
  const [lists, setLists] = useState([]);

  useEffect(() => {
    execute({}, handleResponse);
  }, []);

  const handleResponse = (response: any) => {
    const { status, data } = response;
    if (status === 200) {
      setLists(data.mailList);
    }
  };

  const handleRes = (response: any) => {
    const { status, data } = response;
    if (status == 200) {
      execute({}, handleResponse);
    }
  };

  const { execute, loading } = useAsyncRequest(getAllComplaintsEmails);

  const { execute: deleteApi, loading: deleteLoading } =
    useAsyncRequest(deleteComplaintsEmails);

  const { execute: updateApi, loading: updateLoading } =
    useAsyncRequest(updateCertification);

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={loading || deleteLoading || updateLoading} />
        <Container>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"end"}
          pb={3}
        >
          <Typography fontWeight={FontWeight.BOLD} variant="h5" color="#483F3D">
            Complaince authorities
          </Typography>
          <AddUpdateCertifications
            trigger={() => {
              execute({}, handleResponse);
            }}
          />
        </Box>
        <Box>
          {lists &&
            lists.map((item: any, index: any) => {
              return (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  p={1}
                  borderBottom={"1px solid #9C6CA6"}
                >
                  <Box>
                    <Typography
                      fontWeight={FontWeight.MEDIUM}
                      sx={{
                        wordWrap: "break-word",
                        maxWidth: { xs: "155px", md: "700px" },
                        fontSize: { xs: "0.85rem", md: "1rem" },
                      }}
                    >
                      {item?.firstName} {item?.lastName}
                    </Typography>
                    <Typography
                      fontWeight={FontWeight.MEDIUM}
                      sx={{
                        wordWrap: "break-word",
                        maxWidth: { xs: "155px", md: "700px" },
                        fontSize: { xs: "0.85rem", md: "1rem" },
                      }}
                    >
                      {item?.emailId}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      sx={{ color: "red" }}
                      onClick={() => {
                        const payload = {
                          id: item.id,
                        };
                        deleteApi(payload, handleRes);
                      }}
                    >
                      <DeleteForeverRoundedIcon />
                    </IconButton>
                  </Box>
                </Box>
              );
            })}
        </Box>
        </Container>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default ComplaintsEmailList;
