import { Box, Button, Typography } from "@mui/material";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import { FontWeight } from "../../../core/types";
import { useLocation, useNavigate } from "react-router-dom";
import {
  applicableStandardTechincalRequirement,
  fsmsCheck,
  halaFoodProductsEvaluation,
} from "../constants";
import { Formik } from "formik";
import { stageOneAuditCheckListA } from "../../../core/validations/formSchema";
import LabelCheckBox from "../components/labelCheckBox";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import {
  getAuditorCheckList,
  requiredcheckList,
} from "../../../store/AuditorFlow/auditorListService";
import { REQUIREDLIST } from "../../../router/config";
import Loader from "../../../core/components/Loader";
import { useEffect, useState } from "react";

const RequiredCheckList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [checkList, setCheckList] = useState<any>([]);
  const [halaEvaluationCheck, setHalaEvaluationCheck] =
    useState<Boolean>(false);
  const [technicalRequirement, setTechnicalRequirement] =
    useState<Boolean>(false);
  const [fsmscheck, setFsmscheck] = useState<Boolean>(false);

  const { data: applicationData } = location.state;

  const { applicationId } = applicationData;

  const { execute, loading } = useAsyncRequest(requiredcheckList);

  const { execute: getList, loading: loadingList } =
    useAsyncRequest(getAuditorCheckList);

  const handleRes = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      setCheckList(data);
      data.map((item: any) => {
        switch (item.name) {
          case "halalFoodProductsEvaluationChecklist":
            setHalaEvaluationCheck(true);
            break;
          case "applicableStandard/sandApplicableTechnicalRequirement":
            setTechnicalRequirement(true);
            break;
          case "halalAuditFsmsChecklist":
            setFsmscheck(true);
            break;
        }
      });
    }
  };

  useEffect(() => {
    getList("", handleRes);
  }, [navigate]);

  const handleResponse = (res: any) => {
    const { status } = res;
    if (status == 200) {
      navigate(REQUIREDLIST, {
        state: { data: applicationData },
      });
    }
  };

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={loading || loadingList} />
        <Formik
          initialValues={{}}
          validationSchema={stageOneAuditCheckListA}
          onSubmit={(value: any) => {
            const checkListOne = value?.halaFoodProductsEvaluation
              ? value?.halaFoodProductsEvaluation
              : [];
            const checkListTwo = value?.applicableStandardTechincalRequirement
              ? value?.applicableStandardTechincalRequirement
              : [];

            const payload = {
              applicationId: applicationId,
              checkList: JSON.stringify([...checkListOne, ...checkListTwo]),
              fmsChecklist: value?.fsmsCheckList
                ? JSON.stringify(value.fsmsCheckList)
                : null,
            };

            execute(payload, handleResponse);
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              {checkList.length !== 0 ? (
                <Box>
                  <Typography
                    variant="h6"
                    fontWeight={FontWeight.BOLD}
                    pl={2}
                    pt={3}
                  >
                    Please select Required on-site checklist
                  </Typography>
                  {halaEvaluationCheck && (
                    <LabelCheckBox
                      setFieldValue={setFieldValue}
                      rows={halaFoodProductsEvaluation}
                      fieldName={"halaFoodProductsEvaluation"}
                    />
                  )}
                  {technicalRequirement && (
                    <LabelCheckBox
                      setFieldValue={setFieldValue}
                      rows={applicableStandardTechincalRequirement}
                      fieldName={"applicableStandardTechincalRequirement"}
                    />
                  )}
                  {fsmscheck && (
                    <Box>
                      <LabelCheckBox
                        setFieldValue={setFieldValue}
                        rows={fsmsCheck}
                        fieldName={"fsmsCheckList"}
                      />
                    </Box>
                  )}
                  <Box
                    pt={7}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    width={"85%"}
                  >
                    <Button variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  height={400}
                >
                  <ErrorOutlineRoundedIcon
                    sx={{ height: 100, width: 100, color: "red" }}
                  />
                  <Typography fontWeight={FontWeight.BOLD} fontSize={20} pt={2}>
                    No Checklist available, please contact admin
                  </Typography>
                </Box>
              )}
            </form>
          )}
        </Formik>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default RequiredCheckList;
