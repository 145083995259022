import {
  Box,
  Button,
  LinearProgress,
  LinearProgressProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FontWeight } from "../../../../core/types";
import { AUDITPLAN, DECISIONAPPLICATIONVIEW } from "../../../../router/config";
import { useNavigate } from "react-router-dom";
import FeedbackRoundedIcon from '@mui/icons-material/FeedbackRounded';

const ApplicationOverview = ({
  progress,
  applicationData,
  responseData,
}: any) => {
  const { application, companyDetail } = responseData;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", mt: 1, mb: 1 }}>
        <Box sx={{ width: !isMobile ? 400  : "100%", mr: 1 }}>
          <LinearProgress
            sx={{ height: 12, borderRadius: 5 }}
            variant="determinate"
            color={"success"}
            {...props}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography
            fontWeight={FontWeight.BOLD}
            variant="body2"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={!isMobile ? 'row' : 'column' }
      >
        <Box>
        <Typography
            color={"#0f1322"}
            fontSize={20}
            fontWeight={FontWeight.BOLD}
          >
            Application overview
          </Typography>
          <Typography pt={1} fontSize={isMobile ? '0.875rem': '1rem'} fontWeight={FontWeight.MEDIUM}>
            Application ID: {responseData?.application?.applicationNo}
          </Typography>
          <Typography pt={1} mb={2} fontSize={isMobile ? '0.875rem': '1rem'} fontWeight={FontWeight.MEDIUM}>
            Application status : {applicationData.activeStatus}
          </Typography>
          <LinearProgressWithLabel value={progress} />
          <Button
            variant="outlined"
            sx={{mt : 2}}
            onClick={() => {
              navigate(DECISIONAPPLICATIONVIEW, {
                state: { data: { ...application, companyDetail } },
              });
            }}
          >
            Application overview
          </Button>
        </Box>
        <Box>
          {applicationData.leadAuditor ? (
            <Box mr={4} sx={{marginTop : !isMobile ? 0  : 4}}>
              <Typography fontWeight={FontWeight.BOLD}>
                Audit is planned
              </Typography>
              {applicationData.leadAuditor !== null ? (
                <Box
                  alignSelf={"flex-start"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                >
                  <Box>
                    {applicationData.auditDate && (
                      <Typography mt={1} fontSize={isMobile ? '0.875rem': '1rem'} fontWeight={FontWeight.MEDIUM}>
                        Audit Date: {applicationData.auditDate}
                      </Typography>
                    )}
                    {applicationData.leadAuditor && (
                      <Typography fontSize={isMobile ? '0.875rem': '1rem'} fontWeight={FontWeight.MEDIUM} mt={1}>
                        Auditor: {applicationData.leadAuditor}
                      </Typography>
                    )}
                  </Box>
                  <Box pl={4}>
                    {applicationData?.auditPlanStatus && (
                      <Box
                        p={1}
                        border={1}
                        borderRadius={7}
                        borderColor={
                          applicationData?.auditPlanStatus === "rejected"
                            ? "#f04235"
                            : "green"
                        }
                      >
                        <Typography
                          color={
                            applicationData?.auditPlanStatus === "rejected"
                              ? "#f04235"
                              : "green"
                          }
                        >
                          {applicationData?.auditPlanStatus === "rejected"
                            ? "Rejected"
                            : applicationData?.auditPlanStatus === "updated"
                            ? "Updated"
                            : "Accepeted"}
                        </Typography>
                      </Box>
                    )}
                    {applicationData?.activeStatus === "Audit Planned" && (
                      <Button
                        sx={{fontSize : isMobile?  10 : 14}}
                        onClick={() => {
                          navigate(AUDITPLAN);
                        }}
                        variant="outlined"
                      >
                        Action required
                      </Button>
                    )}
                  </Box>
                </Box>
              ) : null}
              <Button
                variant="outlined"
                sx={{ mt: 3, width: "100%" }}
                onClick={() => {
                  navigate(AUDITPLAN);
                }}
              >
                Audit Plan
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default ApplicationOverview;
