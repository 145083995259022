import { Box, Button, Typography } from "@mui/material";
import { FontWeight } from "../../../../core/types";
import Logo from "../../../../Icons/svg/halalLogo.png";
import { useDispatch } from "react-redux";
import { logout } from "../../../../store/Auth/authSlice";
import { useGoogleLogout } from "react-google-login";

const OnBoardingHeader = () => {
  const dispatch = useDispatch();

  const clientId =
    "830136945700-4sukjrick8hl7m10rlj7al7jhgfmntvo.apps.googleusercontent.com";

  const onLogoutSuccess = () => {
    console.log("Logout successful");
  };

  const onFailure = () => {
    console.log("Logout failed");
  };

  const { signOut, loaded } = useGoogleLogout({
    clientId: clientId,
    onLogoutSuccess,
    onFailure,
    cookiePolicy: "single_host_origin",
    fetchBasicProfile: false, // optional, if you don’t need profile info
    accessType: "offline", // optional
    scope: "",
  });

  return (
    <Box
      sx={{
        padding: 1,
        paddingLeft: 3,
        paddingRight: 3,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img src={Logo} height={30} width={50} style={{ paddingRight: 10 }} />
        <Typography fontWeight={FontWeight.BOLD} variant="h4">
          Halal Point
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          sx={{ height: 30, width: 110 }}
          variant="contained"
          onClick={() => {
            signOut();
            dispatch(logout());
          }}
        >
          Sign Out
        </Button>
      </Box>
    </Box>
  );
};

export default OnBoardingHeader;
