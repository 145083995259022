import { useLocation } from "react-router-dom";
import CompanyDetailComponent from "../../../../core/components/CompanyDetailComponent";
import AdminLayout from "../../components/AdminLayout";
import DashboardContainer from "../../../../core/components/DashboardContainer";

const ApplicationSummary = () => {
  const location = useLocation();
  const { data } = location.state;
  return (
    <AdminLayout>
      <DashboardContainer>
        <CompanyDetailComponent data={data} />
      </DashboardContainer>
    </AdminLayout>
  );
};
export default ApplicationSummary;
