import { Box, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTimePicker as Picker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { FontWeight } from "../../types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Add plugins to dayjs
dayjs.extend(utc);
dayjs.extend(timezone);

const DateTimePicker = ({
  label,
  onChange,
  name,
  components = "DatePicker",
}: any) => {
  return (
    <Box>
      <Typography fontWeight={FontWeight.MEDIUM} fontSize={16} color={"#2c2c2c"}>
        {label}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={[components]}>
          {components === "DatePicker" ? (
            <DatePicker onChange={onChange} name={name} />
          ) : (
            <Picker
              format="DD/MM/YYYY hh:mm A"
              onChange={onChange}
              name={name}
              sx={{ width: 270 }}
            />
          )}
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
};

export default DateTimePicker;
