import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { useEffect, useState } from "react";

function createData(title: string, details: any, auditManDays: string) {
  return {
    title,
    details,
    auditManDays,
  };
}

const ManPowerCalculationTable = ({ manPowerCalculation }: any) => {
  const [data, setData] = useState<any>([]);

  const manPowerTitle = [
    "Basic on-site audit time ",
    "Number of HACCP Studies/Plan",
    "Number of audit days for absence of relevant management system ",
    "Number of Employees",
    "Additional Site",
    "Audit Preparation",
    "Audit Reporting",
    "Use of Translator",
    "Halal Audit",
  ];

  useEffect(() => {
    if (manPowerCalculation) {
      let list: any = [];
      manPowerCalculation.map((item: any, index: any) => {
        list.push(
          createData(
            manPowerTitle[index],
            item.details ? item.details : "-",
            item.auditManDays ? item.auditManDays : "-"
          )
        );
      });
      setData(list);
    }
  }, [manPowerCalculation]);

  return (
    <Box>
      <TableContainer>
        <Table
          sx={{
            minWidth: 650,
            width: "100%",
            mb: 4,
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Audit Time Factors</TableCell>
              <TableCell align="center">Details</TableCell>
              <TableCell align="center">Audit Man Days</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row: any, index: any) => (
                <TableRow key={row.name}>
                  <TableCell align="left">{row.title}</TableCell>
                  <TableCell align="center">{row.details}</TableCell>
                  <TableCell align="center">{row.auditManDays}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ManPowerCalculationTable;
