import * as React from "react";
import PropTypes from "prop-types";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Stack from "@mui/material/Stack";
import ClearIcon from '@mui/icons-material/Clear';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { createData, formatDatePickerDate } from "../../../../core/utils";
import { USERTYPE } from "../../../../core/constants";
import {
  REQUIREDCHECKLIST,
  REQUIREDLIST,
  AUDITFINDIGS,
  STAGEONECHECKLIST,
  DECISIONAPPLICATIONVIEW,
  REVIEWSTATUS,
  AUDITPLANING,
  ADMINREVIEW,
  APPLICATIONSTATUS,
  APPLICATIONDETAIL,
  CREATEQUOTATION,
} from "../../../../router/config";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  TextField,
  Grid,
  IconButton,
  useMediaQuery,
  Chip,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Paper,
  NativeSelect,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandCircleDownRounded from "@mui/icons-material/ExpandCircleDownRounded";

interface OrderTableProps {
  data: any; // Replace 'any' with a more specific type if possible
  resData: any; // Replace 'any' with a more specific type if possible
  role: any; // Replace 'any' with a more specific type if possible
  onChangeDate?: any; // Optional prop
}

const rows = [createData("Name", "Camera Lens", 40, 2, 40570)];

const headCells = [
  {
    id: "tracking_no",
    align: "left",
    disablePadding: false,
    label: "Date",
  },
  {
    id: "name",
    align: "left",
    disablePadding: true,
    label: "Application ID",
  },
  {
    id: "fat",
    align: "left",
    disablePadding: false,
    label: "Company Name",
  },
  {
    id: "carbs",
    align: "left",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "protein",
    align: "right",
    disablePadding: false,
    label: "Type",
  },
  {
    id: "protein",
    align: "right",
    disablePadding: false,
    label: "View",
  },
];

function OrderTableHead({ order, orderBy }: any) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{backgroundColor: "#ECF4FA"}}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

  function OrderStatus({ status, sx }: { status: string; sx?: any }) {
    let color: 'default' | 'primary' | 'secondary' | 'success' | 'error' | 'warning';
    let title: string;
  

  switch (status) {
    case "Audit In Progress":
      color = "warning";
      title = "In Progress";
      break;
    case "Audit Completed":
      color = "success";
      title = "Completed";
      break;
    case "Audit Planned":
      color = "primary";
      title = "Planned";
      break;
    case "Payment Pending":
      color = "primary";
      title = "Payment Pending";
      break;
    case "Payment Done":
      color = "success";
      title = "Payment Done";
      break;
    case "Quotation Created":
      color = "success";
      title = "Quotation Created";
      break;
    case "Quotation Accepted":
      color = "success";
      title = "Quotation Accepted";
      break;
    case "Quotation Updated":
      color = "success";
      title = "Quotation Updated";
      break;
    case "Quotation Change Request":
      color = "error";
      title = "Quotation Change Request";
      break;
    case "Audit Rejected":
      color = "error";
      title = "Audit Change Request";
      break;
    case "Audit Accepted":
      color = "success";
      title = "Audit Accepted";
      break;
    case "Audit Review":
      color = "primary";
      title = "Audit Review";
      break;
    case "Audit Updated":
      color = "primary";
      title = "Audit Updated";
      break;
    case "Certificate Issued":
      color = "success";
      title = "Certificate Issued";
      break;
    case "Active":
      color = "primary";
      title = "Active";
      break;
    case "Draft Created":
      color = "primary";
      title = "Draft Created";
      break;
    case "Application Accepted":
      color = "success";
      title = "Application Accepted";
      break;
    case "Draft Accepted":
      color = "success";
      title = "Draft Accepted";
      break;
    case "Draft Rejected":
      color = "error";
      title = "Draft Rejected";
      break;
    case "Decision Accepted":  
    color = "success";
      title = "Decision Accepted";
      break;
      case "Decision Rejected":  
      color = "error";
        title = "Decision Rejected";
        break;
    case "Draft Updated":
      color = "primary";
      title = "Draft Updated";
      break;
    case "Rejected":
      color = "error";
      title = "Rejected";
      break;
    default:
      color = "primary";
      title = "None";
  }

  return (
  <Stack direction="row" spacing={1} alignItems="center" sx={sx}>
    <Chip label={title} color={color} variant="filled" sx={{fontSize: '0.7rem', height: '23px'}} />
  </Stack>
  );
}

type RowData = {
  applicationDate: string;
  applicationId: string;
  name: string;
  status: string;
  type: string;
  tracking_no: string;
};

type Props = {
  data: RowData[];
  rows: RowData[];
  role: string;
  resData: RowData[];
  navigate: (path: string, options?: any) => void;
};

const statusOptions = [
  "Payment Pending",
  "Payment Done",
  "Quotation Accepted",
  "Quotation Created",
  "Quotation Change Request",
  "Quotation Updated",
  "Audit Planned",
  "Audit Rejected",
  "Audit Updated",
  "Audit Accepted",
  "Active",
  "Audit Completed",
  "Audit In Progress",
  "Certificate Issued"
];

const typeOptions = ["FRESH", "RENEWAL"];

 const OrderTable  = ({ data, resData, role } : any) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterDate, setFilterDate] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [filterStatus, setFilterStatus] = useState<string[]>([]);
  const [filterType, setFilterType] = useState<string[]>([]);
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:600px)");
  const order = "asc";
  const orderBy = "tracking_no";

  const handleDateChange = (e : any) => {
    setSelectedDate(e.target.value)
    const date = new Date(e.target.value);
    const formattedDate = date.toLocaleDateString('en-GB'); // Formats to 'DD/MM/YYYY'
    setFilterDate(formattedDate);
  };
  const handleClear = () => {
    setFilterDate('');
  };

  const filteredData = (data ? data : rows)
    .filter(
      (row: any) =>
        row.applicationId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
        row.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.type.toLowerCase().includes(searchTerm.toLowerCase())  
    )
    .filter(
      (row: any) =>
        (!filterDate || row.applicationDate === filterDate) &&
        (filterStatus.length === 0 || filterStatus.includes(row.status)) &&
        (filterType.length === 0 || filterType.includes(row.type.toUpperCase()))
    );

  const onClick = (application: any) => {
    const { activeStatus, auditStatus } = application;
    const navigateWithState = (route: string) =>
      navigate(route, { state: { data: application } });

    if (role === USERTYPE.AUDITOR) {
      if (
        [
          "Certificate Issued",
          "Application Accepted",
          "Certitificate Issued",
        ].includes(activeStatus)
      ) {
        navigateWithState(DECISIONAPPLICATIONVIEW);
      } else {
        switch (auditStatus) {
          case "CHECKLIST":
            navigateWithState(REQUIREDCHECKLIST);
            break;
          case "REQUIREDLIST":
          case "OPENCLOSINGINSTRUCTIONS":
            navigateWithState(REQUIREDLIST);
            break;
          case "AUDITFINDINGS":
            navigateWithState(AUDITFINDIGS);
            break;
          default:
            navigateWithState(STAGEONECHECKLIST);
        }
      }
    } else if ([USERTYPE.DECISION, USERTYPE.USER].includes(role)) {
      navigateWithState(DECISIONAPPLICATIONVIEW);
    } else {
      switch (activeStatus) {
        case "Payment Pending":
          navigateWithState(REVIEWSTATUS);
          break;
        case "Payment Done":
        case "Audit Rejected":
        case "Audit Updated":
        case "Audit Accepted":
          navigateWithState(AUDITPLANING);
          break;
        case "Quotation Accepted":
          navigateWithState(ADMINREVIEW);
          break;
        case "Quotation Created":
        case "Quotation Change Request":
        case "Quotation Updated":
          navigateWithState(CREATEQUOTATION);
          break;
        case "Audit Planned":
        case "Audit In Progress":
          navigate(APPLICATIONSTATUS);
          break;
        case "Active":
          navigateWithState(APPLICATIONDETAIL);
          break;
        case "Audit Completed":
        case "Draft Created":
        case "Draft Accepted":
        case "Draft Rejected":
        case "Draft Updated":
        case "Draft Updated":
        case "Application Accepted":
        case "Certificate Issued":
        case "Decision Accepted":  
        case "Decision Rejected":  
          navigateWithState(DECISIONAPPLICATIONVIEW);
          break;
        default:
          navigateWithState(APPLICATIONDETAIL);
      }
    }
  };

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
        mb={2}
      >
        <Grid item xs={12} sm={6} md={8}>
          <TextField
            label="Search by Application ID or Name or Status or Type"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="status-select-label">Filter by Status</InputLabel>
          <Select
            labelId="status-select-label"
            multiple
            value={filterStatus}
            onChange={(event) => setFilterStatus(event.target.value as string[])}
            renderValue={(selected) => selected.join(', ') || 'Select Status'}
          >
            {statusOptions.map((status) => (
              <MenuItem key={status} value={status}>
                <input
                  type="checkbox"
                  checked={filterStatus.indexOf(status) > -1}
                  readOnly
                  style={{marginRight: '0.5rem'}}
                />
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

{/* <Box sx={{ display: { xs: 'block', sm: 'none' }, position: 'relative' }}>
  <FormControl 
    fullWidth 
    variant="outlined" 
    sx={{
        border: '1px solid #ccc', // Add a light border
        borderRadius: '5px',       // Rounded corners with 5px radius
        overflow: 'hidden',        // Ensures the border radius applies fully
      }}>
    <InputLabel htmlFor="status-native-select">Filter by Status</InputLabel>
    <NativeSelect
      id="status-native-select"
      value={filterStatus.length > 0 ? filterStatus : ""}
      onChange={(event) => {
        const value = event.target.value;
        if (value === "") return; // Ignore the placeholder value
        setFilterStatus(Array.isArray(value) ? value : [value]);
      }}
      inputProps={{
        id: 'status-native-select',
      }}
     
    >
      <option value="" disabled>
       
      </option>
      {statusOptions.map((status) => (
        <option key={status} value={status}>
          {status}
        </option>
      ))}
    </NativeSelect>
  </FormControl>
  
  {filterStatus.length > 0 && (
    <IconButton
      onClick={() => setFilterStatus([])}  // Clear the selection
      sx={{
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
      }}
    >
      <ClearIcon />
    </IconButton>
  )}
</Box> */}
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Autocomplete
          multiple
          options={statusOptions}
          value={filterStatus}
          onChange={(event, newValue) => setFilterStatus(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Filter by Status"
            />
          )}
          fullWidth
        />
      </Box>
    </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Filter by Date"
            type="date"
            variant="outlined"
            value={selectedDate}
            onChange={handleDateChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClear}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            multiple
            options={statusOptions}
            value={filterStatus}
            onChange={(event, newValue) => setFilterStatus(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Filter by Status"
              />
            )}
            fullWidth
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={4}>
         
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["SingleInputDateRangeField"]} sx={{p:0}}> 
            
              <DateRangePicker
                slots={{ field: SingleInputDateRangeField }}
                name="allowedRange"
                label="Filter by Date"
                onChange={(val :any)=>{
                  const startDate = formatDatePickerDate(val[0]?.$d)
                  const endDate = formatDatePickerDate(val[1]?.$d)
                  onChangeDate({data : "adas"})
                  if (onChangeDate) {
                    onChangeDate({ start: startDate, end: endDate });
                  }
                }}
              />
              
             </DemoContainer>
          </LocalizationProvider>
          
        </Grid> */}

        {/* <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            multiple
            options={typeOptions}
            value={filterType}
            onChange={(event, newValue) => setFilterType(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Filter by Type"
              />
            )}
            fullWidth
          />
        </Grid> */}
      </Grid>
      {!isMobile ? (
      <Paper elevation={3} sx={{ borderRadius: 2 }}>
        <TableContainer
          sx={{
            width: "100%",
            overflowX: "auto",
            position: "relative",
            display: "block",
            maxWidth: "100%",
            "& td, & th": { whiteSpace: "nowrap" },
          }}
        >
          <Table aria-labelledby="tableTitle">
            <OrderTableHead order={order} orderBy={orderBy} />
            <TableBody>
              {filteredData.map((row: any, index: any) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const application = resData.find(
                  (item: any) => item.applicationNo === row.applicationId
                );
                
                return (
                  <TableRow
                    hover
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    tabIndex={-1}
                    key={row.tracking_no}
                  >
                    <TableCell
                      width={150}
                      component="th"
                      id={labelId}
                      scope="row"
                      onClick={() => {
                        onClick(application);
                      }}
                      sx={{ color: "#1C252E" }}
                    >
                      {row.applicationDate}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        onClick(application);
                      }}
                      width={200}
                      sx={{ color: "#1C252E" }}
                    >
                      {row.applicationId}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        onClick(application);
                      }}
                      width={250}
                      sx={{ color: "#1C252E" }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        onClick(application);
                      }}
                      align="right"
                      width={100}
                      sx={{ color: "#1C252E" }}
                    >
                      <OrderStatus status={row.status} />
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        onClick(application);
                      }}
                      align="right"
                      width={80}
                      sx={{ color: "#1C252E" }}
                    >
                      {row.type.toUpperCase()}
                    </TableCell>
                    <TableCell align="right" width={60}>
                      <IconButton
                        onClick={() => {
                          navigate(DECISIONAPPLICATIONVIEW, {
                            state: { data: application },
                          });
                        }}
                      >
                        <RemoveRedEyeRoundedIcon htmlColor="#003768" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        </Paper>
      ) : (
        <Box sx={{ width: "100%" }} mb={15}>
          {filteredData.map((row: any, index: any) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            const application = resData.find(
              (item: any) => item.applicationNo === row.applicationId
            );

            const backgroundColor = index % 2 === 0 ? 'white' : '#ECF4FA';

            return (
              <Accordion key={row.tracking_no} sx={{ backgroundColor }}>
                <AccordionSummary
                  expandIcon={<ExpandCircleDownRounded htmlColor="#2196f3" />}
                  aria-controls={`panel-content-${index}`}
                  id={`panel-header-${index}`}
                  sx={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    ml={2}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                        Application ID
                      </Typography>
                      <Typography variant="body2">
                        {row.applicationId}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                        Status
                      </Typography>
                      <OrderStatus status={row.status} />
                    </Box>
                  </Box>
                </AccordionSummary>

                <AccordionDetails>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      gap: "8px",
                      marginLeft: "2.3rem",
                    }}
                  >
                    {/* Company Name */}
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      onClick={() => {
                        onClick(application);
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                        Company Name
                      </Typography>
                      <Typography variant="body2">{row.name}</Typography>
                    </Box>
                    {/* Date */}
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      onClick={() => {
                        onClick(application);
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                        Date
                      </Typography>
                      <Typography variant="body2">
                        {row.applicationDate}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      onClick={() => {
                        onClick(application);
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                        Type
                      </Typography>
                      <Typography variant="body2">
                        <Typography variant="body2">{row.type}</Typography>
                      </Typography>
                    </Box>

                    {/* View Icon */}
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      onClick={() => {
                        onClick(application);
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                        View
                      </Typography>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(DECISIONAPPLICATIONVIEW, {
                            state: { data: application },
                          });
                        }}
                      >
                        <RemoveRedEyeRoundedIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      )}
    </Box>
  );
}

OrderTableHead.propTypes = { order: PropTypes.any, orderBy: PropTypes.string };

OrderStatus.propTypes = { status: PropTypes.number };


export default OrderTable