import { apiClient, makeApiRequest } from "../../networking/apiClient";
import {
  ADDCERTIFICATION,
  ALLCERTIFICATION,
  CERTIFICATIONLIST,
  CREATEAPPLICATION,
  DELETECERTIFICATION,
  UPDATECERTIFICATION,
} from "../../networking/endpoints";

export const getCertificationList = async () => {
  try {
    const response = await apiClient.get(CERTIFICATIONLIST);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllCertificationList = async () => {
  try {
    const response = await apiClient.get(ALLCERTIFICATION);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addCertification = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      ADDCERTIFICATION,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCertification = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      DELETECERTIFICATION,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateCertification = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      UPDATECERTIFICATION,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createApplication = async (payload: any) => {
  try {
    const response = await apiClient.post(CREATEAPPLICATION, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
