export const auditTypes = [
  {
    label: "Initial Audit",
    name: "initialAudit",
  },
  {
    label: "Surveillance Audit",
    name: "surveillanceAudit",
  },
  {
    label: "Renewal Audit",
    name: "renewalAudit",
  },
  {
    label: "On-site Audit",
    name: "onSiteAudit",
  },
  {
    label: "Remote Audit",
    name: "remoteAudit",
  },
];
