import { Box } from "@mui/material"
import { feedbackQuestion } from "../constants"
import TextInputLight from "../../../../core/components/Input/TextInputLight";

const FeedbackQuestions = ({
    value,
    setFieldValue
}:any)=>{
    return <Box>
            {feedbackQuestion.map((item: any, index : any) => {
                  const { label, name , row } = item;
                  return (
                    <TextInputLight
                      textFieldHeigt={50}
                      label={label}
                      name={name}
                      multiline
                      row={row}
                      onChange={(val : any)=> {
                        setFieldValue(`feedbacks.${[index]}.value` , val.target.value )
                        setFieldValue(`feedbacks.${[index]}.label` , label )
                    }}
                      value={value[name]}
                    />
                  );
                })}
    </Box>
}

export default FeedbackQuestions