import { Box, Button, Typography} from "@mui/material";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import { FontWeight } from "../../../core/types";
import { useLocation, useNavigate } from "react-router-dom";
import {
  closeMeetingInstrustions,
  openMeetingInstrustions,
} from "../constants";
import { Formik } from "formik";
import { stageOneAuditCheckListA } from "../../../core/validations/formSchema";
import LabelCheckBox from "../components/labelCheckBox";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { openclosemeetingcheck } from "../../../store/AuditorFlow/auditorListService";
import Loader from "../../../core/components/Loader";

const OpenCloseMeetingInstructions = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { data: info } = location.state;

  const { applicationId } = info;

  const { execute, loading } = useAsyncRequest(openclosemeetingcheck);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      navigate(-1);
    }
  };

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={loading} />
        <Formik
          initialValues={{}}
          validationSchema={stageOneAuditCheckListA}
          onSubmit={(value: any) => {
            const payload = {
              applicationId: applicationId,
              openMeetingInstrustions: JSON.stringify(
                value.openMeetingInstrustions
              ),
              closeMeetingInstrustions: JSON.stringify(
                value.closeMeetingInstrustions
              ),
            };
            execute(payload, handleResponse);
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Typography
                variant="h6"
                fontWeight={FontWeight.BOLD}
                pl={2}
                pt={3}
              >
                Opening/Closing Meeting Instructions
              </Typography>
              <Typography
                variant="h6"
                fontWeight={FontWeight.BOLD}
                pl={2}
                pt={3}
              >
                OPENING MEETING : TOPICS TO BE ADDRESSED
              </Typography>

              <LabelCheckBox
                setFieldValue={setFieldValue}
                rows={openMeetingInstrustions}
                fieldName={"openMeetingInstrustions"}
              />
              <Typography
                variant="h6"
                fontWeight={FontWeight.BOLD}
                pl={2}
                pt={3}
              >
                CLOSING MEETING : TOPICS TO BE ADDRESSED
              </Typography>

              <LabelCheckBox
                setFieldValue={setFieldValue}
                rows={closeMeetingInstrustions}
                fieldName={"closeMeetingInstrustions"}
              />
              <Box
                pt={7}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                width={"85%"}
              >
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default OpenCloseMeetingInstructions;
