import * as React from "react";
import Button from "@mui/material/Button";
import Loader from "../../../core/components/Loader";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { Box, Grid, Modal, Typography } from "@mui/material";
import DropdownMenu from "../../../core/components/DropDownMenu";
import { useState } from "react";
import { getEmailByName } from "../../../core/utils";
import { updatedAuditor } from "../../../store/Application/applicationService";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  gap: 2,
  borderRadius: '2rem'
};

const UpdateAuditor = ({ applicationId, onSuccess, auditorData }: any) => {
  const [open, setOpen] = useState(false);
  const [leadAuditor, setLeadAuditor] = useState("");
  const [auditor, setAuditor] = useState("");

  const { execute, loading } = useAsyncRequest(updatedAuditor);

  const handleReponse = (res: any) => {
    const { status } = res;
    if (status == 200) {
      handleClose();
      onSuccess();
    }
  };

  const handleSubmit = () => {
    const payload = {
      applicationId: applicationId,
      leadAuditorEmail: getEmailByName(leadAuditor, auditorData),
      auditorEmail: getEmailByName(auditor, auditorData),
      auditor: auditor,
      leadAuditor: leadAuditor,
      reason: "updated auditor",
    };
    execute(payload, handleReponse);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const auditorDropDown = (
    label: any,
    menuLabel: any,
    handleChange?: any,
    value?: any,
    name?: any
  ) => {
    return (
      <Grid item xs={2} sm={4} md={4}>
        <DropdownMenu
          label={label}
          menuLabel={menuLabel}
          handleChange={(val: any) => handleChange(val)}
          value={value}
          width={'100%'}
          name={name}
          option={auditorData}
        />
      </Grid>
    );
  };

  return (
    <React.Fragment>
    <Button variant="outlined" onClick={handleClickOpen} sx={{ marginTop: '1rem' }}>
      Update Auditor
    </Button>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Loader open={loading} />
        <Typography id="modal-title" variant="h6" component="h2">
          Please Select Auditor
        </Typography>
        <Typography id="modal-description" sx={{ mb: 2 }}>
          Are you sure you want to accept?
        </Typography>
        {auditorDropDown(
          "Lead Auditor",
          "Select Auditor",
          (val: any) => setLeadAuditor(val.target.value),
          leadAuditor,
          "leadAuditor"
        )}
        {auditorDropDown(
          "Auditor",
          "Select Auditor",
          (val: any) => setAuditor(val.target.value),
          auditor,
          "auditor"
        )}
        <Button variant="contained" onClick={handleSubmit} fullWidth sx={{borderRadius: '2rem'}}>
          Submit
        </Button>
        <Button variant="outlined" onClick={handleClose} fullWidth sx={{borderRadius: '2rem'}}>
          Close
        </Button>
      </Box>
    </Modal>
  </React.Fragment>
  );
};

export default UpdateAuditor;
