import { apiClient, makeApiRequest } from "../../networking/apiClient";
import { CREATEQUOTATION } from "../../networking/endpoints";

export const createQuotationApi = async (payload: any) => {
  try {
    const response = await makeApiRequest(CREATEQUOTATION, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};
