export const halalFoodProductEvaluationChecklist = {
  heading: "Halal Food Products Evaluation Checklist",
  subheading: "",
  checkList: [
    {
      checklistHeading: "General Requirements for Halal Food",
      headName: "generateRequirements",
      type: "one",
      list: [
        {
          name: "1",
          label: "Application Form",
        },
        {
          name: "2",
          label:
            "Declaration of Conformity by the Applicant on the Product(s) for Registration using the company’s Official Letterhead.",
        },
        {
          name: "3",
          label: "Valid Industry/Trade License (For Companies within UAE).",
        },
        {
          name: "4",
          label:
            "Certificate of Export and/or Authenticated Free Sale Certificate from the country of origin.",
        },
        {
          name: "5",
          label:
            "Product composition & ingredients concentration report issued by the Manufacturer.",
        },
        {
          name: "6",
          label:
            "Documented proof of using any of the internationally accepted quality management systems, in addition to any of the product safety management systems, or Good Manufacturing Practices (GMP) as per UAE.S GSO ISO 22716 or any other GMP approved by ESMA related to the Halal product that are globally applicable and acceptable to the Authority.",
        },
        {
          name: "7",
          label:
            "Test Report from recognized laboratory (accredited ISO 17025) as per the requirement of applicable UAE.S GSO standards for the product",
        },
        {
          name: "8",
          label:
            "Facility Map / Factory Lay out / Farm Map standards for the product.",
        },
        {
          name: "9",
          label: "Labeling Artwork",
        },
        {
          name: "10",
          label: "Certificate for Organic / Halal/ or any other claims.",
        },
        {
          name: "11",
          label:
            "Brief Description of Manufacturing Process & Plant Equipment Lay-out in the form of a Halal System Plan (HSP).",
        },
        {
          name: "12",
          label:
            "Certificates of Raw Materials & Packaging Materials from the suppliers ensuring their Halal Origin.",
        },
      ],
    },
  ],
};

export const applicableStandradsTechincalRequirement = {
  heading: "Applicable Standard and Applicable Technical Requirement",
  subheading:
    "UAE.S 2055-1:2015 Board Chairman Resolution No.(37/2014) Halal Product Registration – General Requirements for Halal food",
  checkList: [
    {
      checklistHeading: "General Requirements for Halal Food",
      headName: "generalRequirements",
      type: "two",
      list: [
        {
          name: "1",
          label:
            "All foods, their derivatives, products, parts and extracts shall be subject to the provisions of Islamic Rules in terms of allowance or prohibition, as per Annex (1).",
        },
        {
          name: "2",
          label:
            "The procedures derived from Islamic Rules shall be adhered to in all phases of food chainof Halal food products, including receipt, preparation, packaging, labeling, transportation,distribution, storage, display and Halal food service.",
        },
        {
          name: "3",
          label:
            "All Food additives and raw materials used for the production of Halal food should be freeof any non-Halal component; this should be supported by legalized official documents explaining its components including packaging materials.",
        },
        {
          name: "4",
          label:
            "All Halal food should not contain any toxic substances and hazardous pollutants whichmay considered harmful to health.",
        },
        {
          name: "5",
          label:
            "All Halal food should be devoid from Najasah (impurity) contamination that is forbidden by Islamic rules.",
        },
        {
          name: "6",
          label:
            "All non-Halal products should be completely separated from the Halal productsthroughout the food chain in order to ensure their differentiation and non-mixing with eachother’s or pollution with others.",
        },
        {
          name: "7",
          label:
            "The official authorities may take all necessary procedures to verify compliance ofproducts with the special requirements of Halal products . and may take the appropriateprocedures in accordance with the other local legislation.",
        },
        {
          name: "8",
          label:
            "At the production of meat or its products, health requirements specified in item (2.4) shallbe adhered to.",
        },
        {
          name: "9",
          label:
            "Animal slaughtering (Tazkeya) requirements should be strictly followed in accordance with the Islamic Rules as set in the standard mentioned in item (2.1).",
        },
        {
          name: "10",
          label:
            "The general health requirements of foods as stated in the standard mentioned in item(2.5) shall be adhered to.",
        },
        {
          name: "11",
          label:
            "All devices, tools, production lines and associated materials used for Halal food preparation should be clean, and it shouldn’t be made or contain non- Halal material.",
        },
        {
          name: "12",
          label:
            "When transforming any appliances, tools or production lines that have been used or in touch with non-Halal foods, they shall be cleaned according to general cleaning rules tore move traces of non-Halal products completely. Shifting from non- Halal to Halal procedure should not be repeating on an ongoing basis.",
        },
        {
          name: "13",
          label:
            "When cleaning or maintaining machinery or devices that goes in touch with Halal foods,there shall be no use of any detergent liquids, greases, oils or fats that contain non-Halal components or materials.",
        },
      ],
    },
    {
      checklistHeading: "Packaging and Rapping Materials",
      headName: "packagingAndRapping",
      type: "two",
      list: [
        {
          name: "14",
          label:
            "Packaging and rapping materials shall be free from any non-Halal materials.",
        },
        {
          name: "15",
          label:
            "Packaging and rapping materials shall not be prepared or manufactured by equipment that is contaminated with non-Halal materials during preparation, storage or transportation. It shall be physically separated from any other non- Halal materials.",
        },
        {
          name: "16",
          label:
            "All Food additives and raw materials used for the production of Halal food should be freeof any non-Halal component; this should be supported by legalized official documents explaining its components including packaging materials.",
        },
        {
          name: "17",
          label:
            "The packaging materials shall not contain any material that is considered hazardous on human health.",
        },
      ],
    },
    {
      checklistHeading: "Halal Food Retail, Handling and Service Outlets",
      headName: "foodRetailHandlingAndServiveOutlets",
      type: "two",
      list: [
        {
          name: "18",
          label:
            "They shall deal only with Halal products that meet provisions of halal food specified in this standard",
        },
        {
          name: "19",
          label:
            "The tools, equipment and utensils used during the service handling, and sale of Halal food products should be totally separated from those used for non- Halal food.",
        },
        {
          name: "20",
          label:
            "If a   facility or place of service usually deals in non-Halal products, but intends to switch to Halal production, it should go through cleaning process in accordance with Islamic rules before commencing Halal production.",
        },
        {
          name: "21",
          label:
            "It’s not allowed to serve non-Halal food and drinks at the same place.",
        },
      ],
    },
    {
      checklistHeading: "Storage, Display and Transportation",
      headName: "storageDisplayAndTransporation",
      type: "two",
      list: [
        {
          name: "23",
          label:
            "All Halal food stored, displayed or transported shall be separated categorized and labelled as Halal to prevent them from being mixed with non-Halal products.",
        },
        {
          name: "24",
          label:
            "Suitable means of transportation should be used to avoid mixing with non- Halal products.",
        },
      ],
    },
    {
      checklistHeading: "Labeling",
      headName: "labeling",
      type: "two",
      list: [
        {
          name: "25",
          label: "Name and type of the product.",
        },
        {
          name: "26",
          label:
            "A list of ingredients, which reflects undoubtedly clearance from all prohibitions of Rules. Sources of actual ingredients shall be announced clearly and explicitly.",
        },
        {
          name: "27",
          label:
            "Products containing fats, oils, meat derivatives or extracts such as gelatin or rennet, shalldeclare its sources.",
        },
        {
          name: "28",
          label:
            "If there is a willing to affix 'Halal' slogan on the label, the requirements under the standard in item (2.2) shall be adhered to.",
        },
        {
          name: "29",
          label:
            "The requirements in the standard in item (1.2) regarding the labels of slaughtered animal sand their products shall be adhered to.",
        },
        {
          name: "30",
          label:
            "Food additives should be declared as required in reference standard in item (6.2).",
        },
        {
          name: "31",
          label:
            "All kinds of fish with scales, shrimp and fish egg of fish with scales including their by products shall be properly labelled as “scaled fish”. All other aquatic animals including their by products shall be clearly labelled as “non-scaled fish” as the case may be.",
        },
      ],
    },
    {
      checklistHeading: "E. Evaluator’s Recommendation",
      headName: "evaluationRecommendation",
      type: "CHECK",
      list: [
        {
          name: "32",
          label: "Recommended for Product Certification",
        },
        {
          name: "33",
          label:
            "Not complying and needs further Corrective Action (See Corrective Action Request",
        },
        {
          name: "34",
          label: "Not complying or fails to comply with the requirement",
        },
      ],
    },
  ],
};

export const primaryProduction = {
  heading: "Halal Audit FSMS Checklist",
  subheading: "GOOD MANUFACTURING PRACTICES (GMP)",
  checkList: [
    {
      checklistHeading: "1.0 PRIMARY PRODUCTION",
      headName: "primaryProducation",
      type: "three",
      list: [
        {
          name: "1",
          label: "1.1. ENVIRONMENTAL HYGIENE",
        },
        {
          name: "2",
          label: "1.2. HYGIENIC PRODUCTION OF FOOD SOURCES",
        },
        {
          name: "3",
          label: "1.3. HANDLING, STORAGE AND TRANSPORT",
        },
        {
          name: "4",
          label: "1.4. CLEANING, MAINTENANCE AND PERSONNEL HYGIENE",
        },
      ],
    },
  ],
};

export const location = {
  heading: "Halal Audit FSMS Checklist",
  subheading: "",
  checkList: [
    {
      checklistHeading: "2.0. LOCATION",
      headName: "location",
      type: "three",
      list: [
        {
          name: "1",
          label: "2.1. ESTABLISHMENTS",
        },
        {
          name: "2",
          label: "2.2. EQUIPMENT",
        },
      ],
    },
  ],
};

export const premisesAndRooms = {
  heading: "Halal Audit FSMS Checklist",
  subheading: "",
  checkList: [
    {
      checklistHeading: "3.0. PREMISES AND ROOMS",
      headName: "premisesAndRooms",
      type: "three",
      list: [
        {
          name: "1",
          label: "3.1. DESIGN AND LAY-OUT",
        },
        {
          name: "2",
          label: "3.2. INTERNAL STRUCTURES AND FITTINGS",
        },
        {
          name: "3",
          label: "3.3. TEMPORARY/MOBILE PREMISES AND VENDING MACHINES",
        },
      ],
    },
  ],
};

export const equipments = {
  heading: "Halal Audit FSMS Checklist",
  subheading: "",
  checkList: [
    {
      checklistHeading: "4.0. EQUIPMENT",
      headName: "equipments",
      type: "three",
      list: [
        {
          name: "1",
          label: "4.1. GENERAL",
        },
        {
          name: "2",
          label: "4.2. FOOD CONTROL AND   MONITORING EQUIPMENT",
        },
        {
          name: "3",
          label: "4.3. CONTAINERS FOR WASTE AND INEDIBLE SUBSTANCES",
        },
      ],
    },
  ],
};

export const facilities = {
  heading: "Halal Audit FSMS Checklist",
  subheading: "",
  checkList: [
    {
      checklistHeading: "5.0. FACILITIES",
      headName: "facilities",
      type: "three",
      list: [
        {
          name: "1",
          label: "5.1. WATER SUPPLY",
        },
        {
          name: "2",
          label: "5.2. DRAINAGE AND WASTE DISPOSAL",
        },
        {
          name: "3",
          label: "5.3. CLEANING",
        },
        {
          name: "4",
          label: "5.4. PERSONNEL HYGIENE FACILITIES AND TOILETS",
        },
        {
          name: "5",
          label: "5.5. TEMPERATURE CONTROL",
        },
        {
          name: "6",
          label: "5.6. AIR QUALITY AND VENTILATION",
        },
        {
          name: "7",
          label: "5.7. LIGHTING",
        },
        {
          name: "8",
          label: "5.8. STORAGE",
        },
      ],
    },
  ],
};

export const controlOfOperations = {
  heading: "Halal Audit FSMS Checklist",
  subheading: "CONTROL OF OPERATION   REQUIREMENTS",
  checkList: [
    {
      checklistHeading: "6.1. CONTROL OF FOOD HAZARDS",
      headName: "controlOfFoodHazards",
      type: "three",
      list: [
        {
          name: "1",
          label: "6.2.1. TIME AND TEMPERATURE CONTROL",
        },
        {
          name: "2",
          label: "6.2.2. SPECIFIC PROCESS STEPS",
        },
        {
          name: "3",
          label: "6.2.3. MICROBIOLOGICAL AND OTHER SPECIFICATIONS",
        },
        {
          name: "4",
          label: "6.2.4. MICROBIOLOGICAL CROSS-CONTAMINATION",
        },
        {
          name: "5",
          label: "6.2.5. PHYSICAL AND CHEMICAL CONTAMINATION",
        },
        {
          name: "6",
          label: "6.3. INCOMING MATERIAL REQUIREMENTS",
        },
        {
          name: "7",
          label: "6.4. PACKAGING",
        },
      ],
    },
    {
      checklistHeading: "6.5. WATER",
      headName: "water",
      type: "three",
      list: [
        {
          name: "1",
          label: "6.5.1. IN CONTACT WITH FOOD",
        },
        {
          name: "2",
          label: "6.5.2. AS AN INGREDIENT",
        },
        {
          name: "3",
          label: "6.5.3. ICE AND STEAM",
        },
        {
          name: "4",
          label: "6.6. MANAGEMENT AND SUPERVISION",
        },
        {
          name: "5",
          label: "6.7. DOCUMENTATION AND RECORDS",
        },
        {
          name: "6",
          label: "6.8. RECALL PROCEDURES",
        },
      ],
    },
  ],
};

export const maintainanceAndSanitations = {
  heading: "Halal Audit FSMS Checklist",
  subheading: "7.0. ESTABLISHMENT MAINTENANCE AND SANITATION",
  checkList: [
    {
      checklistHeading: "7.1. MAINTENANCE AND CLEANING",
      headName: "maintananceAndCleaning",
      type: "three",
      list: [
        {
          name: "1",
          label: "7.1.1. GENERAL",
        },
        {
          name: "2",
          label: "7.1.2. CLEANING PROCEDURES AND METHODS",
        },
        {
          name: "3",
          label: "7.2. CLEANING PROGRAMS",
        },
      ],
    },
    {
      checklistHeading: "7.3. PEST CONTROL SYSTEMS",
      headName: "pestControlSystems",
      type: "three",
      list: [
        {
          name: "1",
          label: "7.3.1. GENERAL",
        },
        {
          name: "2",
          label: "7.3.2. PREVENTING ACCESS",
        },
        {
          name: "3",
          label: "7.3.3. HARBORAGE AND INFESTATION",
        },
        {
          name: "4",
          label: "7.3.4. MONITORING AND   DETECTION",
        },
        {
          name: "5",
          label: "7.3.5. ERADICATION",
        },
        {
          name: "6",
          label: "7.4. WASTE MANAGEMENT",
        },
        {
          name: "7",
          label: "7.5. MONITORING EFFECTIVENESS",
        },
      ],
    },
  ],
};

export const personaleHygenine = {
  heading: "Halal Audit FSMS Checklist",
  subheading: "",
  checkList: [
    {
      checklistHeading: "8.0. ESTABLISHMENT: PERSONNEL HYGIENE REQUIREMENTS",
      headName: "personalHygiene",
      type: "three",
      list: [
        {
          name: "1",
          label: "8.1. HEALTH STATUS",
        },
        {
          name: "2",
          label: "8.2. ILLNESS AND INJURIES",
        },
        {
          name: "3",
          label: "8.3. PERSONAL CLEANLINESS",
        },
        {
          name: "4",
          label: "8.4. PERSONAL BEHAVIOUR",
        },
        {
          name: "5",
          label: "8.5. VISITORS",
        },
      ],
    },
  ],
};

export const transportation = {
  heading: "Halal Audit FSMS Checklist",
  subheading: "",
  checkList: [
    {
      checklistHeading: "9.0. TRANSPORTATION",
      headName: "transportation",
      type: "three",
      list: [
        {
          name: "1",
          label: "9.1. GENERAL",
        },
        {
          name: "2",
          label: "9.2. REQUIREMENTS",
        },
        {
          name: "3",
          label: "9.3. USE AND MAINTENANCE",
        },
      ],
    },
  ],
};

export const producInformationAndConsumerRequirements = {
  heading: "Halal Audit FSMS Checklist",
  subheading: "",
  checkList: [
    {
      checklistHeading:
        "10.0. PRODUCT INFORMATION AND CONSUMER AWARENESS REQUIREMENTS",
      headName: "productInformationAndCusumerAwarness",
      type: "three",
      list: [
        {
          name: "1",
          label: "10.1. LOT IDENTIFICATION",
        },
        {
          name: "2",
          label: "10.2. PRODUCT INFORMATION",
        },
        {
          name: "3",
          label: "10.3. LABELLING",
        },
        {
          name: "4",
          label: "10.4. CONSUMER EDUCATION",
        },
      ],
    },
  ],
};

export const trainingRequirements = {
  heading: "Halal Audit FSMS Checklist",
  subheading: "",
  checkList: [
    {
      checklistHeading: "11.0. TRAINING REQUIREMENTS",
      headName: "trainingRequirements",
      type: "three",
      list: [
        {
          name: "1",
          label: "11.1. AWARENESS AND RESPONSIBILITIES",
        },
        {
          name: "2",
          label: "11.2. TRAINING PROGRAMS",
        },
        {
          name: "3",
          label: "11.3. INSTRUCTION AND SUPERVISION",
        },
        {
          name: "4",
          label: "111.4. REFRESHER TRAINING",
        },
      ],
    },
  ],
};

export const hazardAnalysesAndCriticalControlPoints = {
  heading: "Halal Audit FSMS Checklist",
  subheading: "",
  checkList: [
    {
      checklistHeading: "HAZARD ANALYSES AND CRITICAL CONTROL POINTS (HACCP)",
      headName: "haccp",
      type: "three",
      list: [
        {
          name: "1",
          label: "12. ASSEMBLE HACCP TEAM",
        },
        {
          name: "2",
          label: "13. DESCRIBE PRODUCT",
        },
        {
          name: "3",
          label: "14. IDENTIFY THE INTENDED USE",
        },
        {
          name: "4",
          label: "15. CONSTRUCT FLOW DIAGRAM",
        },
        {
          name: "5",
          label: "16. VERIFY THE PROCESS FLOW DIAGRAM",
        },
        {
          name: "6",
          label: "17. HAZARDS ANALYSES",
        },
        {
          name: "7",
          label: "18. CRITICAL LIMITS",
        },
        {
          name: "8",
          label: "19. MONITORING",
        },
        {
          name: "9",
          label: "20. CORRECTIVE ACTION",
        },
        {
          name: "10",
          label: "21. VERIFICATION",
        },
        {
          name: "11",
          label: "22. DOCUMENTATION AND RECORD",
        },
        {
          name: "12",
          label: "23. TRAINING",
        },
      ],
    },
  ],
};

export const keyElementsOfFoodSafetyManagement = {
  heading: "Halal Audit FSMS Checklist",
  subheading: "",
  checkList: [
    {
      checklistHeading: "KEY ELEMENTS OF THE FOOD SAFETY MANAGEMENT SYSTEM",
      headName: "elementsOfFoodSafety",
      type: "three",
      list: [
        {
          name: "1",
          label: "24. GENERAL REQUIREMENTS",
        },
        {
          name: "2",
          label: "25. FOOD SAFETY POLICY",
        },
        {
          name: "3",
          label: "26. FOOD SAFETY MANUAL",
        },
        {
          name: "4",
          label: "27. MANAGEMENT RESPONSIBILITYM",
        },
        {
          name: "5",
          label: "28. MANAGEMENT COMMITMENT",
        },
        {
          name: "6",
          label: "29. MANAGEMENT REVIEW (INCLUDING HACCP VERIFICATION)",
        },
        {
          name: "7",
          label: "30. RESOURCE MANAGEMENT",
        },
        {
          name: "8",
          label: "31. GENERAL DOCUMENTATION REQUIREMENTS",
        },
        {
          name: "9",
          label: "33. PROCEDURES",
        },
        {
          name: "10",
          label: "34. INTERNAL AUDIT",
        },
        {
          name: "11",
          label: "35. CORRECTIVE ACTION",
        },
        {
          name: "12",
          label: "36. CONTROL OF NON-CONFORMITY",
        },
        {
          name: "13",
          label: "37. PRODUCT RELEASE",
        },
        {
          name: "14",
          label: "38. PURCHASING",
        },
        {
          name: "15",
          label: "39. SUPPLIER PERFORMANCE MONITORING",
        },
        {
          name: "16",
          label: "40. TRACEABILITY",
        },
        {
          name: "17",
          label: "41. COMPLAINT HANDLING",
        },
        {
          name: "18",
          label: "42. PRODUCT RECALL",
        },
        {
          name: "19",
          label: "43. CONTROL OF MEASURING AND MONITORING DEVICES",
        },
        {
          name: "20",
          label: "44. PRODUCT ANALYSIS",
        },
      ],
    },
  ],
};
