import {
  Alert,
  Box,
  Button,
  Grid,
  Modal,
  Rating,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import InsertInvitationRoundedIcon from "@mui/icons-material/InsertInvitationRounded";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { getApplicationbyEmail } from "../../../store/Auth/authService";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../core/components/Loader";
import AuditFindingsComponent from "./component/AuditFindings";
import AdminLayout from "../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import TextWithLabel from "../../../core/components/TextWithLabel";
import WelcomeComponent from "./component/WelcomeComponent";
import ApplicationOverview from "./component/ApplicationOverview";
import { FeedbackRounded } from "@mui/icons-material";
import { feedbacks } from "../../../store/Application/applicationService";
import { Formik } from "formik";
import FeedbackQuestions from "./component/FeedbackQuestions";



const UserApplicationProgressDashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
  };

  const [progress, setProgress] = useState(10);
  const [alertOpen, setAlertOpen] = useState(false);
  const [feedbackMessagee, setFeedbackMessage] = useState("");
  const [rating, setRating] = useState(0);
  const [responseData, setResponseData] = useState<any>({});
  const [applicationData, setApplicationData] = useState<any>({});
  const [stepOne, setStepOne] = useState<any>(1);
  const [stepTwo, setStepTwo] = useState<any>();
  const [stepThree, setStepThree] = useState<any>(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const handleOpenAlert = () => {
    setRating(0);
    setFeedbackMessage("");
    setAlertOpen(true);
  };
  const handleCloseAlert = () => setAlertOpen(false);

  const user = useSelector((state: any) => state.auth.user);
  const findings = useSelector((state: any) => state.admin.userAuditFinings);

  const { execute: getApplication, loading: loadingApplication } =
    useAsyncRequest(getApplicationbyEmail);

  const { execute: feedback, loading: feedbackLoading } =
    useAsyncRequest(feedbacks);

  const handleResponse = (res: any) => {
    const { status } = res;
    if (status == 200) {
      setSuccess(true);
      setMessage("Feedback sent SuccessFully");
      handleCloseAlert();
      getCurrentApplication();
    } else {
      setSuccess(false);
      setMessage("Something went wrong");
      handleCloseAlert();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 2000);
    return () => clearTimeout(timer);
  }, [message, success]);

  const handelApplicationResponse = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      const { application } = data;
      setApplicationData(application);
      setResponseData(data);
      const { activeStatus, applicationId } = application;

      const payload = {
        applicationId: applicationId,
      };

      switch (activeStatus) {
        case "Audit Planned":
          setStepOne(2);
          setProgress(50);
          break;
        case "Audit Review":
          setStepOne(3);
          setStepTwo(0);
          setProgress(60);
          break;
        case "Audit In Progress":
          setStepOne(3);
          setStepTwo(1);
          setProgress(70);
          break;
        case "Audit Completed":
          setProgress(80);
          setStepOne(3);
          setStepTwo(3);
          break;
        case "Draft Created":
          setProgress(90);
          setStepOne(3);
          setStepTwo(3);
          break;
        case "Draft Accepted":
          setProgress(90);
          setStepOne(3);
          setStepTwo(3);
          break;
        case "Draft Rejected":
          setProgress(90);
          setStepOne(3);
          setStepTwo(3);
          break;
        case "Draft Updated":
          setProgress(90);
          setStepOne(3);
          setStepTwo(3);
          break;
        case "Certificate Issued":
          setProgress(100);
          setStepOne(3);
          setStepTwo(3);
          setStepThree(true);
          break;
      }
    }
  };

  const getCurrentApplication = () => {
    const payload = {
      userEmail: user.email,
    };
    getApplication(payload, handelApplicationResponse);
  };

  useEffect(() => {
    getCurrentApplication();
  }, [findings]);

  const detailField = (title: string, subtitle: any) => {
    const isBool = typeof subtitle === "boolean";
    let subTitle = "";
    if (isBool) {
      subTitle = subtitle === true ? "Yes" : "No";
    } else {
      subTitle = subtitle ? subtitle : "-";
    }
    return (
      <Grid item xs={2} sm={4} md={4}>
        {title && <TextWithLabel title={title} subTitle={subTitle} />}
      </Grid>
    );
  };

  const feedbackPopUp = () => {
    return (
      <Modal
        open={alertOpen}
        onClose={handleCloseAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{width : isMobile ? '100%' : 'auto'}}
      >
        <Box sx={style} mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Share Your Feedback
          </Typography>
          <Formik
            initialValues={{
              rating: 0,
            }}
            onSubmit={(value) => {
              const payload = {
                applicationId: applicationData.applicationId,
                ...value,
              };
              feedback(payload, handleResponse);
            }}
          >
            {({
              values,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Rating
                  sx={{ mt: 2, mb: 2 }}
                  name="simple-controlled"
                  value={values.rating}
                  onChange={(event, newValue: any) => {
                    setFieldValue("rating", newValue);
                  }}
                />
                <FeedbackQuestions
                  value={values}
                  setFieldValue={setFieldValue}
                />
                <Box
                  display={"flex"}
                  alignItems={"flex-end"}
                  justifyContent={"flex-end"}
                  mt={6}
                  gap={1}
                >
                  <Button onClick={() => handleCloseAlert()} variant="outlined">Cancel</Button>
                  <Button type="submit" disabled={values.rating == 0} variant="contained">Submit</Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    );
  };

  return (
    <>
      <AdminLayout>
        <>
          <Loader open={loadingApplication || feedbackLoading} />
          <WelcomeComponent user={user} />
          {feedbackPopUp()}
          {responseData?.rejectReason && (
            <Typography>
              Application is reject for the reason :{" "}
              {responseData?.rejectReason}
            </Typography>
          )}
          <Box
            p={3}
            width={"100%"}
            borderRadius={3}
            boxShadow={2}
            sx={{ backgroundColor: "white" }}
          >
            <ApplicationOverview
              progress={progress}
              applicationData={applicationData}
              responseData={responseData}
            />
            {stepTwo == 3 && <AuditFindingsComponent data={responseData} />}
            {stepThree ? (
              <Box>
                <Grid
                  container
                  spacing={isMobile ? 1 : 4}
                  alignItems={"baseline"}
                  columns={{ xs: 2, sm: 12, md: 16 }}
                  sx={{
                    paddingTop: 2,
                  }}
                >
                  {detailField(
                    "Status",
                    responseData.application?.activeStatus
                  )}
                  {detailField(
                    "Certificate Issued Date",
                    responseData.application?.certificateIssuedDate
                  )}
                  {detailField(
                    "Effective Date",
                    responseData.application?.effectiveDate
                  )}
                  {detailField(
                    "Expiry Date",
                    responseData.application?.expiryDate
                  )}
                  {detailField(
                    "Surveillance One",
                    responseData?.surveillanceOne
                  )}
                  {detailField(
                    "Surveillance Two",
                    responseData?.surveillanceTwo
                  )}
                </Grid>
              </Box>
            ) : (
              <Box></Box>
            )}
          </Box>

          <Box
            mt={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexDirection={!isMobile ? "row" : "column"}
          >
            <Box
              p={2}
              mt={2}
              width={"100%"}
              borderRadius={3}
              boxShadow={2}
              alignItems={"center"}
              justifyContent={"center"}
              m={1}
              sx={{ backgroundColor: "white" }}
            >
              <Box display={"flex"} alignItems={"center"}>
                <NotificationsActiveRoundedIcon
                  sx={{ height: 60, width: 60 }}
                />
                <Box ml={2}>
                  <Typography>Notifications</Typography>
                  <Typography>0</Typography>
                </Box>
              </Box>
            </Box>
            {applicationData?.feedback &&
            <Box
              p={2}
              mt={2}
              width={"100%"}
              borderRadius={3}
              boxShadow={2}
              alignItems={"center"}
              justifyContent={"center"}
              m={1}
              sx={{ backgroundColor: "white" }}
              onClick={() => {
                handleOpenAlert();
              }}
            >
              <Box display={"flex"} alignItems={"center"}>
                <FeedbackRounded sx={{ height: 60, width: 60 }} />
                <Box ml={2}>
                  <Typography>Share your Feedback</Typography>
                </Box>
              </Box>
            </Box>
              }
            <Box
              p={2}
              mt={2}
              width={"100%"}
              borderRadius={3}
              boxShadow={2}
              alignItems={"center"}
              justifyContent={"center"}
              m={1}
              sx={{ backgroundColor: "white" }}
              mb={isMobile ? 12 : 0}
            >
              <Typography></Typography>
              <Box display={"flex"} alignItems={"center"}>
                <InsertInvitationRoundedIcon sx={{ height: 60, width: 60 }} />
                <Box ml={2}>
                  <Typography>Upcoming Events</Typography>
                  <Typography>
                    On Site Audit schedule on {applicationData?.auditDate}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {message && (
            <Alert severity={success ? "success" : "error"}>{message}</Alert>
          )}
        </>
      </AdminLayout>
    </>
  );
};

export default UserApplicationProgressDashboard;
