import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import DateTimePicker from "../../../core/components/DatePicker";
import { FontWeight, TextFieldType } from "../../../core/types";
import moment from "moment";
import TextInputLight from "../../../core/components/Input/TextInputLight";

const headingLabel = (label: any) => {
  return (
    <Typography fontWeight={FontWeight.MEDIUM} fontSize={16}>
      {label}
    </Typography>
  );
};

const TwoRowTable = ({ headings, rows, setFieldValue, name }: any) => {
  const [row, seRow] = useState<any>(rows);

  const addFieldToRow = (rowIndex: any) => {
    seRow((prevRows: any) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex].fields.push(
        updatedRows[rowIndex].fields.length + 1
      );
      return updatedRows;
    });
  };

  return (
    <Table
      sx={{
        minWidth: 650,
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none",
        },
      }}
    >
      <TableHead>
        <TableRow>
          {headings ? (
            headings.map((heading: string, index: number) => (
              <TableCell key={index} align={index === 0 ? "left" : "center"}>
                {headingLabel(heading)}
              </TableCell>
            ))
          ) : (
            <Box></Box>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {row.map((row: any, rowIndex: number) => (
          <TableRow key={rowIndex}>
            <TableCell sx={{ width: "20%" }}>
              <DateTimePicker
                components="DateTimePicker"
                label=""
                value={row.dateTime}
                onChange={(val: any) => {
                  setFieldValue(`${name}.${[rowIndex]}.label`, `${row.labels}`);
                  setFieldValue(
                    `${name}.${[rowIndex]}.date`,
                    moment(val.$d).format("MM/DD/YYYY HH:mm A")
                  );
                }}
              />
            </TableCell>
            <TableCell align="center" sx={{ width: "20%" }}>
              {row.auditor && <Typography>{row.auditor}</Typography>}
            </TableCell>
            <TableCell align="center">
              {row.type === "text" ? (
                <Box>
                  {row.fields &&
                    row.fields.map((item: any, index: any) => {
                      return (
                        <Box mb={4}>
                          <TextInputLight
                            type={TextFieldType.TEXT}
                            name={row.textName}
                            label=""
                            multiline
                            width={"100%"}
                            textFieldHeigt={30}
                            row={2}
                            onChange={(val: any) => {
                              setFieldValue(
                                `${name}.${[rowIndex]}.label`,
                                row.auditor
                              );
                              setFieldValue(
                                `${name}.${[rowIndex]}.fileds.${[index]}.item`,
                                val.target.value
                              );
                            }}
                          />
                        </Box>
                      );
                    })}
                  {row.fields && (
                    <Box
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"end"}
                      mt={4}
                    >
                      <Button
                        onClick={() => {
                          addFieldToRow(rowIndex);
                        }}
                      >
                        + Add More
                      </Button>
                    </Box>
                  )}
                </Box>
              ) : (
                headingLabel(row.labels)
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TwoRowTable;
