export const conformityTerms = (companyName: any) => {
  const terms1 = [
    {
      title: "",
      terms: [
        `1. ${companyName} here after referred to as ${companyName}., grants the right to use the Certification Body Halal  Mark  on  the  certified  products  manufactured/traded  by ,hereafter	referred	to	as	the	licensee,	after successfully obtaining the Certificate of Conformity`,
        "2.	The licensee may use the mark for sales promotion of the product. It may be used in advertisements and on stationery together with the mark or the name of the manufacturer or the license provided that it is not used in such a manner that Certification Body. may consider as misleading. The mark shall be used for every piece of the product. It may be also used on the following subject to Certification Body approval:",
        "a.	principal display panel of the certified products",
        "b.	secondary or tertiary packaging whichever is directly visible to the buyer during display",
        "c.	company publicity materials such as brochures, company profiles, reports, exhibition materials, flyers, banners, roll-ups etc.",
        "d.	corporate electronic media such as in website, internet etc.",
        "e.	company vehicles",
        "f.	company communication documents such as letterheads, stationeries etc.",
        "3.	The mark shall be reproduced exactly the same color and proportion whenever it is possible.",
        "4.	The mark is the exclusive property of 	 and its correct use is a contractual obligation. Intentional misuse of the mark maybe grounds for actions that may include but not limited to withdrawing the Certificate of Conformity.",
        `5.${companyName} shall implement market monitoring for ensuring correct use of the Certification Body Halal mark.`,
        "6.	The licensee shall not use its product certification in such a manner as to bring Certification Body. into disrepute and not make any statement regarding its product certification that Certification Body may consider misleading or unauthorized.",
        "7.	In making reference to its product certification in communication media, a supplier of certified products must comply with Certification Body.. requirements. A supplier may publish that it has been authorized to apply Certification Body. Halal certification mark to products to which the certification applies. In all cases, the supplier shall take sufficient care of in its publications and advertisements that no confusion arises between certified and non-certified products. If a supplier wishes to publish a test report or evaluation report, the report shall be reproduced in full, unless specific authorization is granted by ………………………….. to publish part(s) of the report. A supplier shall not specify function, or claim or the like in its use information that could mislead purchasers to believe that performances of the products or its use are covered by the certification when in fact they are not.",
        "8.	Instructions or other user information accompanying the product and related to the certification scheme shall be approved by Certification Body.. Advertisements containing Certification Body.. Halal certification mark or reference to certification shall be approved by Certification Body...",
        "9.	In case of any doubts regarding the use of the mark, prior written approval shall be obtained from Certification Body to prevent misuse and subsequent corrective action",
      ],
    },
    {
      title:
        "Corrective Action for Defective Products or Misuse of Certification Mark",
      terms: [
        `1. ${companyName} shall require the licensee to implement corrective action according to ISO/IEC Guide 27 after identification of defective products or conclusive misuse of license, certificates and marks. The corrective action could be one or more of the following:`,
        "a.	Notification of parties authorized and responsible for instituting a recall of defective products",
        "b.	Removal of the mark from the defective products, provided such action is done in collaboration with regulatory authorities who shall ultimately decide whether to accept or reject the products",
        "c.	Replacement and scrapping of defective products",
        "d.	Reconstruction of the product to comply with the governing certification requirements",
        "e.	Issuance of notice to the general public about the hazard from using the product and corresponding action to be taken",
        "2.	The corrective measures and period of implementation shall be decided by Certification Body depending on the extent of misuse of license, certificate and mark",
        "3.	When there is conclusive proof, the regulatory authorities shall be notified immediately by Certification Body of the misuse of license, certificate and Certification Body. Halal mark, and the certification shall be put under warning, suspension or withdrawal, where appropriate.",
        "4.	Withdrawal of right to certification may lead to legal actions by Certification Body.., when deemed necessary after consultation with legal counsel, and notification of appropriate governmental, regulatory and public bodies.",
        "5.	The licensee shall be properly and officially notified of any action taken by Certification Body. against the defective products, the reason for such actions and any conditions or corrective measures to be implemented by the licensee",
        "6.	When the corrective action has been resolved by the licensee to the satisfaction of Certification Body.. verified through re-evaluation of the product to the extent necessary, the licensee, regulatory authorities and all parties previously notified of the offence shall be given a second notification on the reinstatement of the product certification. This notification shall summarize the corrective action taken by the licensee, the effectivity date of the reinstatement of certification, scope of certification, and when applicable the new marking required for corrected products.",
        "7.	Shall the licensee refuse to take corrective action identified by Certification Body.., the certification shall be withdrawn and the appropriate governmental, regulatory and public bodies shall be duly notified.",
      ],
    },
  ];
  return terms1;
};

export const certificationAgressment = (
  companyName: any,
  address: any,
  scope: any
) => {
  const terms1 = [
    {
      title: "",
      terms: [
        `This Certification Agreement (‘Agreement’) is made and entered into on this (Day Date Year) in the Republic of India. by and between ${companyName}., a company duly incorporated under the laws of the said Republic of India (AAO-1940) with its registered address at ${address}. (hereinafter referred to as the ‘Certifier’) and (Applicant Name), a company duly incorporated under the laws of the Republic of India (Corporate Identity Number ) and having its registered office at Address. (hereinafter referred to as the ‘Applicant’)`,
        "This is a legally enforceable agreement maintained by the Certifier & Applicant for the provision of certification activities defined in Certification Scheme & Quality Manual. This agreement states the responsibilities of Certifier and Applicant, and shall be read, understood and accepted by the Applicant as an initial step for product certification, along with Certification Scheme requirements & Certification Procedures. This agreement shall be signed between Certifier and Applicant after the acceptance of Quote by the Applicant and before the Application Review.",
      ],
    },
    {
      title: "1. SCOPE OF CERTIFICATION",
      terms: [
        "This agreement covers the following scope for the certification of the product category as mentioned below:",
        `Name of the site where the products are manufactured: ${companyName}`,
        `Location of Site: ${address}`,
        `Scope of Certification : ${scope}`,
      ],
    },
    {
      title: "2.	RESPONSIBILITIES AND OBLIGATION",
      terms: [],
    },
    {
      title: "2.1	RESPONSIBILITIES OF THE APPLICANT ",
      terms: [],
    },
    {
      title: "The Applicant accepts and undertakes:",
      terms: [
        "2.1.1	To review and understand the purpose of requirements of Certification Scheme",
        "2.1.2	To meet the Certification Standard requirements, Scheme requirements, Food Safety Requirements as mentioned in clause 1 of this document, including the implementation of appropriate modifications when notified by Certification Body.",
        "2.1.3	Gives consent to Certifier to outsource any certification audit activity related to this application, provided that there is no perceived risk to confidentiality, impartiality and integrity of Certifier certification process. Otherwise, the Applicant shall raise an objection to the outsourced certification activity in writing prior to conduct of the particular activity.",
        "2.1.4	To provide certification documents to Certifier as specified in the applicable certification scheme;",
        "2.1.5	To ensure that consultants, if any involved in the development of their management system, do not participate in the audit",
        "2.1.6	To provide:",
        "2.1.6.1 For the conduct of application review, initial certification audit, surveillance (such as inspections and audits), re-certification audit & other audit (such as witness audit, follow up audit, short-notice audit, extension of scope audit, etc) as applicable, by the auditors",
        "2.1.6.2 With facilities needed by the Audit team",
        "2.1.6.3 Certification Body.. auditors with access to all processes, production areas, personnel, applicable documents, records, organization structure, policy and procedures, sub- contractors",
        "2.1.6.4 Other members of Audit team such as technical experts, auditors in training, audit observers, accreditation body representatives and other relevant authorities, with access similar to Auditors of Certification Body. conducting Audit",
        "2.1.6.5 With guides to audit team with responsibilities to take the auditors to different functions, introduce the auditors to the auditee and resolve any communication issues during the audit. Guides will not participate in the audit",
        "2.1.7 To cooperate for short notice audits for: a.Investigation of any complaint received from the interested parties, b.	Response to any changes c.	Follow up on suspended certificate of compliance",
        "2.1.8 To allow Accreditation Body/Recognition Authorities accompanying Certification Body. audit team to carryout witnessing of Certification Body. audit process as per the accreditation requirements and shall co operate and provide access to accreditation body assessors/auditors for witnessing",
        "2.1.9 To provide with Corrective Action report for Non-conformance raised during Audit",
        "2.1.10	To agree for follow-up audit for verification of corrective action, if recommended by the Lead Auditor during closing meeting of the Audit and / or as per the requirement of the Certification Decision Committee",
        "2.1.11	To ensure that the certified product & unit maintains relevant Certification standards mentioned in Scheme along with the applicable product standard / regulation",
        "2.1.12	To keep record of all known complaints related to compliance to certification requirements, make such record available to Certifier upon request and undertake:",
        "2.1.12.1 appropriate actions with reference to such complaints and any fault affecting the certification requirements identified in the products;",
        "2.1.12.2 documentation of the actions done and their effectiveness;",
        "2.1.13	To promptly inform Certifier of any modification likely to affect its ability to meet certification requirements. This may involve:",
        "2.1.13.1 juridical, commercial, organizational or ownership status;",
        "2.1.13.2 the organization and management (for instance, key management positions, decision-makers or technical staff);",
        "2.1.13.3 the product or production methods;",
        "2.1.13.4 addresses of contact persons and production sites;",
        "2.1.13.5 significant changes in the management system ",
        "2.1.13.6 changes in scope of operations",
        "2.1.13.7 breaches of legal obligations",
        "2.1.14	To make claims regarding certification consistent with the certification scope;",
        "2.1.15	To not copy the granted  certificate in a way that would hinder its legibility nor to tamper the original copies or photocopies of the certificate",
        "2.1.16	To not translate the certificate and/or other documents provided by Applicant to other languages without prior review and consent from Certifier.",
        "2.1.17	To refrain from using its certification in a way leading the Certifier into disrepute and shall not make any statement on its certification likely to be considered misrepresentations or unauthorized by the Certifier;",
        "2.1.18	To comply with Certifier requirements as per the certification scheme specifications",
        "2.1.18.1	when referring to its product certification in the media such as in documents, website, leaflets, or advertising material;",
        "2.1.18.2	with reference to mark of conformity and information related to the product;",
        "2.1.19	To discontinue use of all advertising material containing any reference thereto, and undertake the actions required by the certification scheme (for instance, the return of certification documents) and implement any other measure required, in case of certification suspension, withdrawal, termination or expiry (where provided);",
        "2.1.20	Give consent to be held accountable as per Certification Body rules and take relevant actions, in case of violation of Certification Agreement or Scheme requirements or failure to maintain the  ………….Certification status of the company.",
        "2.1.21	To have the certificate withdrawn incase of its non-commitment for Surveillance / Surprise Audit / Short notice audit",
        "2.1.22	To implement changes to the requirements in Certification as and when notified by the Certifier and to allow for audit to verify the implementation of changes",
        "2.1.23	To cooperate in complaint investigation when any complaint is received against the certified client",
        "2.1.24	To accept the actions taken by certifier when the complaint is justified after the investigation",
        "2.1.25	To agree to sign new certification agreement in case of amendment / changes in existing forms",
      ],
    },
    {
      title: "2.2	RESPONSIBILITIES OF THE CERTIFIER",
      terms: [
        "The Certifier is responsible",
        "2.2.1	To complete various steps of the certification activities, including audits, issuance of certificates, surveillance and re-certification",
        "2.2.2	To maintain all information obtained or created during the performance of certification activities, except for the information that the Applicant makes publicly available or when agreed between Certifier and Applicant, as confidential.",
        "2.2.3	To assure that the assessors or the Certifier do not give any information and documents related to the applicant to third parties without getting permission from the applicant, except for legal necessities by force of law.",
        "2.2.4	To provide only accurate & factually valid information to applicant and not provide partial/ misleading information regarding any certification activity or about its accreditation status",
        "2.2.5	To inform applicant in-case of delay in renewal of accreditation of certifier past the expiry date",
        "2.2.6	To inform applicant in-case of suspension or withdrawal of accreditation along with the appropriate time line taken for reinstatement of accreditation",
        "2.2.7	To maintain any information about the applicant obtained from sources other than the client (e.g. complainant, regulators) as confidential",
        "2.2.8	To update the applicant regarding changes to certification requirements and provide sufficient time period to implement changes in system",
        "2.2.9	To provide the applicant with",
        "I.	Certification Scheme",
        "II. procedures for certification including the application, application review, initial certification audits,",
        "III. procedures for granting, maintaining,	reducing, extending, suspending, reinstatement, withdrawing certification and recertification;",
        "IV. procedures for complaints & appeals",
        "V.	Fee structure for initial certification, recertification, other activities taken for maintaining certification",
        "VI. Reference of Certification Standards",
        "VII.	Procedure on use of certificate &  Mark",
      ],
    },
    {
      title: "3. FEES ",
      terms: [
        "Fees related to the certification activities under the scope of this agreement is charged according to the quotation sent to Applicant.",
        "3.1. The Applicant shall pay the fees to Certifier before the Audit activities is initiated.",
        "3.2. Food, Transportation and Accommodation shall be organized and borne/paid by Applicant for each audit.",
        "3.3.	For Short-notice audit, where in audit is planned & arranged on short notice, the applicant shall reimburse the expenses incurred by the Audit team. Fee for Audit activities shall be charged as that of initial audit charges.",
      ],
    },
    {
      title: "4. VALIDITY OF THIS AGREEMENT ",
      terms: [
        "4.1. The agreement shall come into force upon signing by both the Certifier and the Applicant.",
        "4.2. The agreement is valid until the expiry of the certificate issued initially by the Certifier",
        "4.3. If the recertification is conducted, then the certified client & certifier shall re-sign the certification agreement.",
        "4.4. When a Certification agreement is updated, the certifier & certified client shall re-sign the certification agreement and the existing agreement shall become invalid.",
      ],
    },
    {
      title: "5. CONFIDENTIALITY",
      terms: [
        "Both the Certifier and Applicant undertake to maintain the confidentiality of data exchanged between them in the form of Mail communication or Documents or others, as a result of entering this agreement.",
        "This agreement shall be governed and construed in accordance with law applicable in India. Any and all disputes that may arise in connection with this agreement are to be settled in accordance with the complaints and appeal procedure of Certifier. By signing this agreement, the Applicant acknowledges and accepts the procedures of handling complaints and appeals as per the procedure of Certifier, which is publicly available and shall be shared upon request by the Applicant.",
        "By signing this agreement, the Applicant acknowledges and accepts terms and conditions of this agreement and the mentioned scheme",
      ],
    },
  ];
  return terms1;
};

export const declarationTerms = (companyName: any) => {
  const terms1 = [
    {
      title: "2.	RESPONSIBILITIES AND OBLIGATION",
      terms: [
        `1.	${companyName} hereafter referred to as ${companyName}., grants the right to use the Certification Body Halal  Mark  on  the  certified  products  manufactured/traded by, hereafter referred to as the licensee,	after successfully obtaining the Certificate of Conformity.`,
        `2.	The licensee may use the mark for sales promotion of the product. It may be used in advertisements and on stationery together with the mark or the name of the manufacturer or the licensee provided that it is not used in such a manner that Certification Body. may consider as misleading. The mark shall be used for every piece of the product. It may be also used on the following subject to Certification Body approval:`,
        "a.	principal display panel of the certified products",
        "b.	secondary or tertiary packaging whichever is directly visible to the buyer during display",
        "c.	company publicity materials such as brochures, company profiles, reports, exhibition materials, flyers, banners, roll-ups etc.",
        "d.	corporate electronic media such as in website, internet etc.",
        "e.	company vehicles",
        "f.	company communication documents such as letterheads, stationeries etc.",
        "3.	The mark shall be reproduced exactly the same color and proportion whenever it is possible.",
        "4.	The mark is the exclusive property of 	 and its correct use is a contractual obligation. Intentional misuse of the mark maybe grounds for actions that may include but not limited to withdrawing the Certificate of Conformity.",
        "5.	Certification Body. shall implement market monitoring for ensuring correct use of the Certification Body Halal mark.",
        "6.	The licensee shall not use its product certification in such a manner as to bring Certification Body. into disrepute and not make any statement regarding its product certification that Certification Body may consider misleading or unauthorized.",
        "7.	In making reference to its product certification in communication media, a supplier of certified products must comply with Certification Body.. requirements. A supplier may publish that it has been authorized to apply Certification Body. Halal certification mark to products to which the certification applies. In all cases, the supplier shall take sufficient care of in its publications and advertisements that no confusion arises between certified and non-certified products. If a supplier wishes to publish a test report or evaluation report, the report shall be reproduced in full, unless specific authorization is granted by ………………………….. to publish part(s) of the report. A supplier shall not specify function, or claim or the like in its use information that could mislead purchasers to believe that performances of the products or its use are covered by the certification when in fact they are not.",
        "8.	Instructions or other user information accompanying the product and related to the certification scheme shall be approved by Certification Body.. Advertisements containing Certification Body.. Halal certification mark or reference to certification shall be approved by Certification Body...",
        "9.	In case of any doubts regarding the use of the mark, prior written approval shall be obtained from Certification Body to prevent misuse and subsequent corrective action.",
        "",
      ],
    },
    {
      title:
        "Corrective Action for Defective Products or Misuse of Certification Mark",
      terms: [
        `1.	${companyName} shall require the licensee to implement corrective action according to ISO/IEC Guide 27 after identification of defective products or conclusive misuse of license, certificates and marks. The corrective action could be one or more of the following:`,
        "a.	Notification of parties authorized and responsible for instituting a recall of defective products",
        "b.	Removal of the mark from the defective products, provided such action is done in collaboration with regulatory authorities who shall ultimately decide whether to accept or reject the products",
        "c.	Replacement and scrapping of defective products",
        "d.	Reconstruction of the product to comply with the governing certification requirements",
        "e.	Issuance of notice to the general public about the hazard from using the product and corresponding action to be taken",
        "2.	The corrective measures and period of implementation shall be decided by Certification Body depending on the extent of misuse of license, certificate and mark.",
        "3.	When there is conclusive proof, the regulatory authorities shall be notified immediately by Certification Body of the misuse of license, certificate and Certification Body. Halal mark, and the certification shall be put under warning, suspension or withdrawal, where appropriate.",
        "4.	Withdrawal of right to certification may lead to legal actions by Certification Body.., when deemed necessary after consultation with legal counsel, and notification of appropriate governmental, regulatory and public bodies.",
        "5.	The licensee shall be properly and officially notified of any action taken by Certification Body. against the defective products, the reason for such actions and any conditions or corrective measures to be implemented by the licensee.",
        "6.	When the corrective action has been resolved by the licensee to the satisfaction of Certification Body.. verified through re-evaluation of the product to the extent necessary, the licensee, regulatory authorities and all parties previously notified of the offence shall be given a second notification on the reinstatement of the product certification. This notification shall summarize the corrective action taken by the licensee, the effectivity date of the reinstatement of certification, scope of certification, and when applicable the new marking required for corrected products.",
        "7.	Shall the licensee refuse to take corrective action identified by Certification Body.., the certification shall be withdrawn and the appropriate governmental, regulatory and public bodies shall be duly notified.",
        "/We hereby declare that I fully understood all the requirements stated in this agreement and we commit to comply with the requirements and conditions set forth in this agreement.",
      ],
    },
  ];
  return terms1;
};
