import {
  Box,
  Button,
  Modal,
  Alert,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { gapi } from "gapi-script";
import BaseAuthTemplate from "../../../core/components/CommonTemplate/BaseAuthTemplate";
import { homebg } from "../../../core/Images";
import { keyframes, useTheme } from "@mui/system";
import { useState, useEffect } from "react";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { verifyEmail } from "../../../store/Auth/authService";
import Loader from "../../../core/components/Loader";
import SignInForm from "../SignIn/Components/SigninForm";
import { USERTYPE } from "../../../core/constants";
import useMediaQuery from "@mui/material/useMediaQuery";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const zoomIn = keyframes`
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

const LandingPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<any>(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isUserLogin, setIsUserLogin] = useState(true);

  const emailToken = searchParams.get("emailToken");

  const { execute, loading } = useAsyncRequest(verifyEmail);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleResponse = (res: any) => {
    const { status } = res;
    if (status === 200) {
      setMessage("Verification Successful, Please login to continue");
      setOpen(true);
      setStatus(true);
    } else {
      setMessage("Verification Failed, Please try again");
      setOpen(true);
      setStatus(false);
    }
  };

  useEffect(() => {
    if (emailToken) {
      const payload = {
        emailToken: emailToken,
      };

      execute(payload, handleResponse);
    }
  }, [emailToken]);

  const alert = () => {
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        closeAfterTransition
      >
        <Box p={60} sx={style}>
          <Alert severity={status ? "success" : "error"}>{message}</Alert>
          <Button onClick={onClose} sx={{ marginTop: 4 }} variant="contained">
            Close
          </Button>
        </Box>
      </Modal>
    );
  };

  const clientId =
    "830136945700-4sukjrick8hl7m10rlj7al7jhgfmntvo.apps.googleusercontent.com";

  const onClose = () => {
    setOpen(false);
  };

  const onSuccess = (res: any) => {
    console.log("Success", res);
  };

  const onFailure = (res: any) => {
    console.log("Success", res);
  };

  useEffect(() => {
    const start = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", start);
  }, []);

  const handleToggleChange = (event: any) => {
    setIsUserLogin(event.target.checked);
  };

  return (
    <>
      <Loader open={loading} />
      {alert()}

      <Box
        sx={{
          backgroundImage: `url(${homebg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: 'no-repeat',
          position: 'fixed', // Cover the whole screen
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          animation: `${fadeIn} 1s ease-in-out`,
        }}
      >
        <Box
          p={isMobile ? 3 : 10}
          pt={isMobile ? '54px' : ''}
          pb={isMobile ? '54px' : ''}
          width={isMobile ? "90%" : "20%"}
          minWidth={320}
          sx={{
            margin: isMobile ? '1rem' : 0,
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            backdropFilter: isMobile ? "none" : "blur(4px) saturate(169%)",
            WebkitBackdropFilter: isMobile ? "none" : "blur(4px) saturate(169%)",
            backgroundColor: 'rgba(255, 255, 255, 0.4)',
            borderRadius: '100px',
            border: '1px solid rgba(209, 213, 219, 0.3)',
          }}
        >
          {/* <SignInForm type={USERTYPE.USER} /> */}
          {isMobile && ( // Show toggle only on mobile screens
            <FormControlLabel
              control={
                <Switch
                  checked={isUserLogin}
                  onChange={handleToggleChange}
                  color="primary"
                />
              }
              label={
                isUserLogin ? "Switch to Admin Login" : "Switch to User Login"
              }
              sx={{ mb: 2 }}
            />
          )}

          {/* Render only the relevant SignInForm based on the toggle */}
          <SignInForm type={isUserLogin ? USERTYPE.USER : undefined} />
        </Box>
        {!isMobile && (
          <Box display="flex" alignItems="center"  justifyContent="center"  height="60vh">
            <Box p={3} />
          </Box>
        )}
        {!isMobile && (
          <Box
            p={isMobile ? 3 : 10}
            width={"20%"}
            minWidth={320}
            height={485}
            sx={{
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              backdropFilter: 'blur(4px) saturate(169%)',
              WebkitBackdropFilter: 'blur(4px) saturate(169%)',
              backgroundColor: 'rgba(255, 255, 255, 0.4)',
              borderRadius: '100px',
              border: '1px solid rgba(209, 213, 219, 0.3)',
            }}
          >
            <SignInForm />
          </Box>
        )}
      </Box>
      </>
  );
};

export default LandingPage;
