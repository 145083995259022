import { MuiOtpInput } from "mui-one-time-password-input";
import { useState } from "react";
import BaseAuthTemplate from "../../../core/components/CommonTemplate/BaseAuthTemplate";
import { Box, Button, Typography } from "@mui/material";
import { FontWeight } from "../../../core/types";
import { useLocation, useNavigate } from "react-router-dom";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { verifyOtp } from "../../../store/Auth/authService";
import Loader from "../../../core/components/Loader";
import { RESETPASSWORD } from "../../../router/config";

const OTPScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location?.state;
  
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState('')

  const { execute, loading } = useAsyncRequest(verifyOtp);

  const handleChange = (newValue: any) => {
    setOtp(newValue);
  };

  const handleResponse = (res : any) =>{
    const {status, data} = res
    if(status === 200){
      navigate(RESETPASSWORD,
        {
          state: { email: email },
          replace: true
      });
    }else if (status === 240){
      setErrorMessage(res.message)
    }
  }

  return (
    <BaseAuthTemplate>
      <Box
        mt={20}
        mb={40}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <Loader open={loading}/>
        <Typography
          padding={1}
          mb={5}
          color={"grey"}
          fontWeight={FontWeight.MEDIUM}
          fontSize={28}
        >
          Please enter the OTP sent to you E-mail ID
        </Typography>
        <MuiOtpInput width={400} value={otp} onChange={handleChange} />

        <Button
          sx={{ marginTop: 5 }}
          variant="outlined"
          disabled={otp.length !== 4}
          onClick={()=>{
            const paylaod = {
              email : email,
              otp : otp
            }
            setErrorMessage("")
            execute(paylaod, handleResponse)
          }}
        >
          Submit
        </Button>
        {errorMessage && <Typography color={'red'} mt={2}>{errorMessage}</Typography>}
      </Box>
    </BaseAuthTemplate>
  );
};

export default OTPScreen;
