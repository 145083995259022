import { FC } from "react";
import { Box, Typography } from "@mui/material";
import {
  stepperActive,
  stepperCompleted,
  stepperFont,
  stepperInActive,
} from "../../colors";
import StepperActiveIcon from "../../../../Icons/StepperIcon";
import StepperCompleteIcon from "../../../../Icons/StepperCompleteIcon";
import { FontWeight } from "../../../types";

interface StepperProps {
  list: string[];
  active: number;
}

const OnBoardingStepper: FC<StepperProps> = ({ list, active }) => {
  const Stepper = (label: any, index: number, active: number) => {
    const getColor = () => {
      if (index + 1 < active) return stepperCompleted;
      if (index + 1 === active) return stepperActive;
      return stepperInActive;
    };

    return (
      <Box
        sx={{
          width: "100%",
          paddingRight: { xs: 0, sm: 1 },
          marginBottom: { xs: 2, sm: 0 },
        }}
      >
        <Box
          sx={{
            height: 10,
            background: getColor(),
            borderRadius: 5,
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingTop: 1,
          }}
        >
          {getColor() === stepperCompleted ? (
            <StepperCompleteIcon />
          ) : (
            <StepperActiveIcon fill={getColor()} />
          )}
          <Typography
            color={stepperFont}
            pl={1}
            fontWeight={FontWeight.MEDIUM}
            fontSize={{ xs: "0.75rem", sm: "1rem" }}
          >
            {label}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: { xs: "center", sm: "flex-start" },
        alignItems: { xs: "flex-start", sm: "center" },
      }}
    >
      {list.map((value: string, index: number) => {
        return Stepper(value, index, active);
      })}
    </Box>
  );
};

export default OnBoardingStepper;
