import { TextFieldType } from "../../core/types";

export const stageOneInitialValue = {
  auditMode: "onSite",
};
export const stageOneInitialValueB = {};

export const assessmentInitialValues = {
  scheduleOfAssessment : "",
  dateOfAssessment : "",
  assessmentTeam : ""
};

export const applicationDocument = [
  {
    name: "applicationForm",
    label: "Application Form",
  },
  {
    name: "certificateAgreement",
    label: "Certification Agreement",
  },
  {
    name: "declarationOFConformity",
    label: "Declaration of Conformity",
  },
  {
    name: "declarationOFundertaking",
    label: "Declaration of Undertaking",
  },
  {
    name: "tradeIndustrialSlaighterHouseLicense",
    label: "Trade/Industrial/Slaughterhouse License",
  },
  {
    name: "freeSaleCertificate",
    label: "Free-sale Certificate",
  },
  {
    name: "productDetails",
    label: "Product Details",
  },
  {
    name: "rmDetails",
    label: "RM Details",
  },
  {
    name: "processFlowchart",
    label: "Process Flowchart",
  },
  {
    name: "mapFacilityLayout",
    label: "Map of Facility or Layout",
  },
  {
    label: "GMP / HACCP / FSMS Documents (manual)",
    name: "gmpHaccpFsmsDocumentsManual",
  },
  {
    label:
      "Test Reports (used water/products etc) and/or Certificate of Analysis (COA) for quality checks",
    name: "testReportsUsedWaterProductsEtcAndOrCertificateOfAnalysisCoaForQualityChecks",
  },
  { label: "PM Details", name: "pmDetails" },
  {
    label: "Product Labels/Artwork and secondary containers",
    name: "productLabelsArtworkAndSecondaryContainers",
  },
  {
    label: "Halal Certificates/Declarations for critical materials",
    name: "halalCertificatesDeclarationsForCriticalMaterials",
  },
  {
    label: "Health Certificates (previous)",
    name: "healthCertificatesPrevious",
  },
  {
    label: "Country of Origin Certificate (previous)",
    name: "countryOfOriginCertificatePrevious",
  },
  {
    label: "List of Slaughter persons and their competency requirements",
    name: "listOfSlaughterPersonsAndTheirCompetencyRequirements",
  },
  {
    label:
      "Details of Person in charge of Halal implementation and their training",
    name: "detailsOfPersonInChargeOfHalalImplementationAndTheirTraining",
  },
  {
    label: "Others (give details in Remarks)",
    name: "othersGiveDetailsInRemarks",
  },
];

export const stageOneCheckListB = [
  { label: "Application Details", name: "applicationDetails" },
  { label: "Supplier Approval Procedure", name: "supplierApprovalProcedure" },
  { label: "Approved Supplier list", name: "approvedSupplierList" },
  { label: "Food Safety Scope", name: "foodSafetyScope" },
  { label: "Food Safety Policy", name: "foodSafetyPolicy" },
  { label: "PRPs Identification", name: "prpsIdentification" },
  {
    label: "Implementation of HACCP System",
    name: "implementationOfHaccpSystem",
  },
  {
    label:
      "Hazard Identification & Analysis, HACCP Plan, CCP, identification and Validation of Critical Limits",
    name: "hazardIdentificationAnalysisHaccpPlanCcpIdentificationAndValidationOfCriticalLimits",
  },
  { label: "HACCP Verification Program", name: "haccpVerificationProgram" },
  {
    label:
      "Applicable statutory and regulatory requirements (Trainings, Specific Product Standards etc.)",
    name: "applicableStatutoryAndRegulatoryRequirementsTrainingsSpecificProductStandardsEtc",
  },
  { label: "Pest Control", name: "pestControl" },
  { label: "Labeling", name: "labeling" },
  { label: "Internal Audit", name: "internalAudit" },
  { label: "Management Review", name: "managementReview" },
  {
    label: "Awareness of Halal Standards Requirement",
    name: "awarenessOfHalalStandardsRequirement",
  },
  {
    label:
      "The animal is free of any diseases, which would make the meat harmful.",
    name: "theAnimalIsFreeOfAnyDiseasesWhichWouldMakeTheMeatHarmful",
  },
  {
    label: "Segregation of non halal items",
    name: "segregationOfNonHalalItems",
  },
  {
    label: "Scope of certification is valid (activity & site/s)",
    name: "scopeOfCertificationIsValidActivityAndSites",
  },
  {
    label: "Stage 2 Audit Man-days adequate?",
    name: "stage2AuditManDaysAdequate",
  },
];

export const summaryStageOneAudit = [
  {
    label: "Participants",
    name: "participants",
  },
  {
    label: "Summary of Audit",
    name: "summaryOfAudit",
  },
  {
    label: "Points of Concern",
    name: "pointsOfConcern",
  },
];

export const auditorsRecommendation = [
  { label: "Recommended for stage-2 audit", name: "recommendedForStage2Audit" },
  {
    label:
      "Not complying and needs to rectify points of concern (See Section D)",
    name: "notComplyingAndNeedsToRectifyPointsOfConcernSeeSectionD",
  },
  {
    label: "Not complying or fails to comply with the requirement",
    name: "notComplyingOrFailsToComplyWithTheRequirement",
  },
];

export const halaFoodProductsEvaluation = [
  {
    name: "halaFoodProductsEvaluation",
    label: "Halal Food Products Evaluation Checklist",
  },
];

export const applicableStandardTechincalRequirement = [
  {
    name: "applicableStandardTechincalRequirement",
    label: "Applicable Standard and Applicable Technical Requirement",
  },
];

export const fsmsCheck = [
  {
    name: "fsmsChecklist",
    label: "Halal Audit FSMS Checklist",
  },
];

export const fsmsCheckList = [
  {
    name: "primaryProducation",
    label: "1.0 PRIMARY PRODUCTION",
  },
  {
    name: "location",
    label: "2.0. LOCATION",
  },
  {
    name: "premisesAndRooms",
    label: "3.0. PREMISES AND ROOMS",
  },
  {
    name: "equiment",
    label: "4.0. EQUIPMENT",
  },
  {
    name: "facilities",
    label: "5.0. FACILITIES",
  },
  {
    name: "controlOfOperationRequirements",
    label: "6.0. CONTROL OF OPERATION REQUIREMENTS",
  },
  {
    name: "restablishmentMaintainanceAndSanitation",
    label: "7.0. ESTABLISHMENT MAINTENANCE AND SANITATION",
  },
  {
    name: "establishmentPersonnelHygiene",
    label: "8.0. ESTABLISHMENT: PERSONNEL HYGIENE REQUIREMENTS",
  },
  {
    name: "transportation",
    label: "9.0. TRANSPORTATION",
  },
  {
    name: "productInformationAndConsumerAwarness",
    label: "10.0. PRODUCT INFORMATION AND CONSUMER AWARENESS REQUIREMENTS",
  },
  {
    name: "trainingRequirements",
    label: "11.0. TRAINING REQUIREMENTS",
  },
  {
    name: "hazardAnalysesAndCriticalContronalPoints",
    label: "HAZARD ANALYSES AND CRITICAL CONTROL POINTS (HACCP)",
  },
  {
    name: "elementsFoddSafetyManagementSystem",
    label: "KEY ELEMENTS OF THE FOOD SAFETY MANAGEMENT SYSTEM",
  },
];

export const fsmsCheckRequestList = [
  {
    value: true,
    label: "1.0 PRIMARY PRODUCTION",
  },
  {
    value: true,
    label: "2.0. LOCATION",
  },
  {
    value: true,
    label: "3.0. PREMISES AND ROOMS",
  },
  {
    value: true,
    label: "4.0. EQUIPMENT",
  },
  {
    value: true,
    label: "5.0. FACILITIES",
  },
  {
    value: true,
    label: "6.0. CONTROL OF OPERATION REQUIREMENTS",
  },
  {
    value: true,
    label: "7.0. ESTABLISHMENT MAINTENANCE AND SANITATION",
  },
  {
    value: true,
    label: "8.0. ESTABLISHMENT: PERSONNEL HYGIENE REQUIREMENTS",
  },
  {
    value: true,
    label: "9.0. TRANSPORTATION",
  },
  {
    value: true,
    label: "10.0. PRODUCT INFORMATION AND CONSUMER AWARENESS REQUIREMENTS",
  },
  {
    value: true,
    label: "11.0. TRAINING REQUIREMENTS",
  },
  {
    value: true,
    label: "HAZARD ANALYSES AND CRITICAL CONTROL POINTS (HACCP)",
  },
  {
    value: true,
    label: "KEY ELEMENTS OF THE FOOD SAFETY MANAGEMENT SYSTEM",
  },
];

export interface AssessmentFeilds {
  type?: TextFieldType;
  name?: string;
  label?: string;
  value?: string;
  row?: number;
  multiline?: boolean;
}

export const assessmentDetails: AssessmentFeilds[] = [
  {
    type: TextFieldType.DATE,
    name: "scheduleOfAssessment",
    label: "SCHEDULE OF ASSESSMENT",
    value: "",
  },
  {
    type: TextFieldType.DATE,
    name: "dateOfAssessment",
    label: "DATE/S OF ASSESSMENT",
  },
  {
    type: TextFieldType.TEXT,
    name: "assessmentTeam",
    label: "ASSESSMENT TEAM",
  },
  // {
  //   type: TextFieldType.DATE,
  //   name: "discloreDate",
  //   label: "DISCLOSURE DATE",
  // },
  // {
  //   type: TextFieldType.DATE,
  //   name: "acknowledgementDate",
  //   label: "ACKNOWLEDGEMENT DATE",
  // },
  // {
  //   type: TextFieldType.TEXT,
  //   name: "acknowledgedBy",
  //   label: "ACKNOWLEDGED BY",
  // },
  // {
  //   type: TextFieldType.TEXT,
  //   name: "acknowledgedByDesignation",
  //   label: "DESIGNATION",
  // },
];

export const openMeetingInstrustions = [
  {
    name: "open1",
    label:
      "Self introduction by Audit team leader and Check availability of the Organization Head/ M R, If not present call on mobile and ensure timely start of the audit activity. If there is extreme delay again call the organization Head/M R and warn that more delay would result in abandonment of the Audit. Abandon if there is further delay and inform office – otherwise resume the audit.",
  },
  {
    name: "open2",
    label:
      "Introduce yourself and audit team to the organization’s representative. Ask for key personnel for brief opening meeting. Team leader to extend thanks for inviting for audit, introduce, Confirm audit site, audit scope, Exclusions, Audit plan and requirement of change in audit sequence, if any. Assure the client about Confidentiality commitments of each auditor. Confirm language to be used during audit.",
  },
  {
    name: "open3",
    label:
      "Take brief introduction of the Opening meeting participants, and get the attendance sheet signed .",
  },
  {
    name: "open4",
    label: "Audit team leader to explain about audit procedure to be followed",
  },
  {
    name: "open5",
    label:
      "Audit is also on sampling basis, hence if no conformity has been identified; it does not necessarily prove that everything is absolutely fine with the organization and vice versa.",
  },
  {
    name: "open6",
    label:
      "Explain Classification of Major, minor non conformities and their impact on certification decision and mode of eliminating these non conformities.",
  },
  {
    name: "open7",
    label:
      "Explain about Observations and their impact on certification. And mode of elimination.",
  },
  {
    name: "open8",
    label:
      "About Client’s right to Complain against staff behavior and right to Appeal against audit findings and decision.",
  },
  {
    name: "open9",
    label:
      "About Auditor team’s role & authority, impact of Recommendation & decision of Sharia & Technical committee. Inform about 3 years audit cycle and surveillance audits after certification.",
  },
  {
    name: "open10",
    label:
      "Narrate audit plan as agreed by the client – inform about requirement of Guides for each auditor.",
  },
  {
    name: "open11",
    label:
      "Inform about requirement of short working lunch, communication facilities and about privacy in the evening for writing audit findings. Inform about Closing meeting.",
  },
];

export const closeMeetingInstrustions = [
  {
    name: "close1",
    label:
      "Team leader to start closing meeting, by extending thanks for Hospitality, transparency and support. Take closing meeting attendanc",
  },
  {
    name: "close2",
    label:
      "Ask audit members to narrate their audit findings, explain that these are on sample basis. Each auditor to share his / her findings, highlighting both positive and negative findings. Avoid  taking names except when making positive remarks. Classify negative findings as Major NC / Minor NC/ Observations",
  },
  {
    name: "close3",
    label:
      "Audit team leader to narrate his/ her findings and at the end explain the client about mode of closure.",
  },
  {
    name: "close4",
    label:
      "Ask the M R, to   put his/ her sign as acknowledgement of the corrective action Requests made   by the audit team.-non conformities",
  },
  {
    name: "close5",
    label:
      "Resolve any dispute regarding audit findings, and again inform about their right to make appeal against audit findings and audit decision. Explain about the mode of making appeal.",
  },
  {
    name: "close6",
    label:
      "Inform the client to submit corrective action plan if possible immediately or latest within one week regarding all Major and Minor non conformities.",
  },
  {
    name: "close7",
    label:
      "Inform the client that all major non conformities have to be closed within 90 days and all minor non conformities have to be closed within 30 days.",
  },
  {
    name: "close8",
    label:
      "Depending upon nature of non conformities Inform the client about mode of closure verification.  Closure of All major NCs, except legal ones to be verified at audit site.",
  },
  {
    name: "close9",
    label: "In case of minor NC write on the NC form mode of closure.",
  },
  {
    name: "close10",
    label:
      "Team leader to state result of stage-2  audit \n 1.Recommended for award of certificate as no NC was identified \n 1.Recommended for award of certificate on the basis of your corrective action plan submitted in writing, verification to be done during coming surveillance audit.\n 3.Recommended for award of certificate subject to acceptance of corrective action evidence to be submitted to the office within one month.\n 4.Recommendation held up, decision to be made on the basis of major NC closure evidence.",
  },
  {
    name: "close11",
    label:
      "If yes -   Final Verification of Scope. inform about requirement of intimation to  whenever major change occurs in the organization. Inform about Use of Logo.",
  },
];

export const gradingOptions = ["Critical", "Major", "Minor", "Observation"];
