import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import useAsyncRequest from "../../../../networking/useAsyncRequest";
import {
  getActiveApplication,
  getActiveApplicationAuditor,
} from "../../../../store/Dashboard/dashboardService";
import Loader from "../../../../core/components/Loader";
import { useSelector } from "react-redux";
import { USERTYPE } from "../../../../core/constants";
import OrderTable from "../../AuditorDashboard/components/Recent";
import { createData } from "../../../../core/utils";
import { getApplicationByUser } from "../../../../store/Application/applicationService";

export default function BasicTable() {
  const user = useSelector((state: any) => state.auth.user);
  const [data, setData] = useState<any[]>([]);
  const [responseData, setResponseData] = useState<any[]>([]);

  const { role, email } = user;

  const { execute, loading } = useAsyncRequest(getActiveApplication);

  const { execute: executeAuditorList, loading: auditorListLoading } =
    useAsyncRequest(getActiveApplicationAuditor);

    const { execute: executeUserApplications, loading: applicationLoading } =
    useAsyncRequest(getApplicationByUser);
    

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      setResponseData(data);
      let list: any = [];
      data.map((item: any) => {
        list.push(
          createData(
            item.applicationNo ? item.applicationNo : "-",
            item.companyDetail.nameOfCompany,
            item.createdDate ? item.createdDate : "-",
            item.certification ? item.certification : "-",
            item.activeStatus ? item.activeStatus : "Active"
          )
        );
      });
      setData(list);
    }
  };

  useEffect(() => {
    switch (role) {
      case USERTYPE.ADMIN:
        execute("", handleResponse);
        break;
      case USERTYPE.DECISION:
        execute("", handleResponse);
        break;
      case USERTYPE.AUDITOR:
        const payload = {
          email: email,
        };
        executeAuditorList(payload, handleResponse);
        break;
        case USERTYPE.USER:
        const userPayload = {
          userEmail: email,
        };
        executeUserApplications(userPayload, handleResponse);
        break;
    }
  }, []);

  return (
    <Box p={2} borderRadius={5} bgcolor={"white"}>
      <Loader open={loading || auditorListLoading || applicationLoading} />
      <OrderTable data={data} resData={responseData} role={role} />
    </Box>
  );
}
