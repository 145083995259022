import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { SIGNIN } from "../../../../router/config";
import React from "react";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    location.pathname === "/signIn"
      ? navigate(-1)
      : navigate(SIGNIN, {
          state: { type: "user" },
        });

    //setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ background: "#fff" }}>
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
              height: 50,
              display: "flex",
              alignItems: "center",
              // Placeholder for logo:
              // backgroundImage: `url(${logo})`,
              // backgroundRepeat: `no-repeat`,
              // backgroundSize: `230px 50px`,
              // height: 50,
              // width: 230,
            }}
          >
            {/* Replace with actual logo or text */}
            <Typography variant="h6" color="rgb(33, 150, 243)">
              Halal Point
            </Typography>
          </Box>
          <Box pr={4}>
            {/* <IconButton color="inherit">
              <Button color="inherit">About</Button>
            </IconButton>
            <IconButton color="inherit">
              <Button color="inherit">Pricing</Button>
            </IconButton> */}
            {/* <IconButton color="inherit">
              <Button
                sx={{
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
                color="inherit"
                onClick={handleClick}
              >
                <Typography variant="body2" color="#fff">
                  {location.pathname === "/signIn" ? "Sign In" : "Sign Up"}
                </Typography>
              </Button>
            </IconButton> */}

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                sx: {
                  "& .MuiMenuItem-root": {
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#e0f7fa",
                    },
                  },
                  borderRadius: 0,
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate(SIGNIN, {
                    state: { type: "user" },
                    replace: true,
                  });
                  handleClose();
                }}
              >
                User Login &gt;&gt;
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate(SIGNIN, {
                    state: { type: "admin" },
                    replace: true,
                  });
                  handleClose();
                }}
              >
                Admin Login &gt;&gt;
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
