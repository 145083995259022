import { TextFieldType } from "../../../core/types";



export const feedbackQuestion = [
    {
      type: TextFieldType.TEXT,
      name: "applicationJourney",
      label: "How was your application journey",
      row : 1
    },
    {
      type: TextFieldType.TEXT,
      name: "auditorFeedback",
      label: "How was the auditor's action and communication?",
      row : 1
    },
    {
      type: TextFieldType.TEXT,
      name: "adminsFeedback",
      label: "How was the admin's action and communication?",
      row : 1
    },
    {
      type: TextFieldType.TEXT,
      name: "decisionsFeedback",
      label: "How was the decision committee's action and communication?",
      row : 1
    },
    {
        type: TextFieldType.TEXT,
        name: "other",
        label: "If Other, specify",
        row : 2
      },
  ];