import {
  Alert,
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import { FontWeight } from "../../../core/types";
import { useLocation, useNavigate } from "react-router-dom";
import { dummySignature, logo } from "../../../core/Images";
import { handleDownloadPdf } from "../../../core/utils";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import {
  createDraft,
  updateDraft,
  userAcceptDraft,
  userRejectDraft,
} from "../../../store/Application/applicationService";
import Loader from "../../../core/components/Loader";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { USERTYPE } from "../../../core/constants";
import TextInputLight from "../../../core/components/Input/TextInputLight";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: '10px'
};

const AuditDarft = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: any) => state.auth.user);
  const { role } = user;
  const { data } = location.state;
  const [rejectOpen, setRejectOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertAcceptOpen, setAlertAcceptOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [rejectReason, setRejectReason] = useState("");

  const handleOpenAlert = () => setAlertOpen(true);
  const handleCloseAlert = () => setAlertOpen(false);

  const handleOpenAcceptAlert = () => setAlertAcceptOpen(true);
  const handleCloseAcceptAlert = () => setAlertAcceptOpen(false);
  const handleOpenRejectAlert = () => {
    setRejectReason("");
    setRejectOpen(true);
  };
  const handleCloseRejectAlert = () => setRejectOpen(false);

  const { companyDetail, applicationId } = data;

  const { nameOfCompany, address } = companyDetail;

  const { execute, loading } = useAsyncRequest(createDraft);

  const { execute : update, loading : updateLoading } = useAsyncRequest(updateDraft);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      setSuccess(true);
      setMessage("Draft Created Succesfully");
      navigate(-1)
      handleCloseAlert();
    } else {
      setSuccess(false);
      handleCloseAlert();
      setMessage("Something Went Wrong");
    }
  };

  const { execute: acceptDraft, loading: acceptLoading } =
    useAsyncRequest(userAcceptDraft);

  const { execute: rejectDraft, loading: rejectLoading } =
    useAsyncRequest(userRejectDraft);

  const handleAcceptResponse = (res: any) => {
    const { status } = res;
    if (status == 200) {
      setSuccess(true);
      setMessage("Accepeted SuccessFully");
      handleCloseAcceptAlert();
      navigate(-1)
    } else {
      setSuccess(false);
      setMessage("Something went wrong");
      handleCloseAcceptAlert();
    }
  };

  const rejectHandleResponse = (res: any) => {
    const { status } = res;
    if (status == 200) {
      setSuccess(true);
      setMessage("Rejected SuccessFully");
      handleCloseRejectAlert();
      navigate(-1)
    } else {
      setSuccess(false);
      setMessage("Something went wrong");
      handleCloseRejectAlert();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 2000);
    return () => clearTimeout(timer);
  }, [message, success]);

  const acceptAlert = () => {
    return (
      <Modal
        open={alertAcceptOpen}
        onClose={handleCloseAcceptAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please confirm, are you sure want to accept draft
          </Typography>
          <Box
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <Button onClick={() => handleCloseAcceptAlert()} variant="outlined">No</Button>
            <Button
              onClick={() => {
                const payload = {
                  applicationId: applicationId,
                };
                acceptDraft(payload, handleAcceptResponse);
              }}
              variant="contained"
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  const rejectAlert = () => {
    return (
      <Modal
        open={rejectOpen}
        onClose={handleCloseRejectAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please confirm, are you sure want to reject draft
          </Typography>
          <TextInputLight
            textFieldHeigt={50}
            label="Reason"
            value={rejectReason}
            onChange={(val: any) => {
              setRejectReason(val.target.value);
            }}
          />
          <Box
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <Button onClick={() => handleCloseRejectAlert()} variant="outlined">No</Button>
            <Button
              disabled={!rejectReason}
              onClick={() => {
                const payload = {
                  applicationId: applicationId,
                  reason: rejectReason,
                };
                rejectDraft(payload, rejectHandleResponse);
              }}
              variant="contained"
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  const commonText = (
    text = "name",
    fontSize = 16,
    pt = 2,
    fontWeight = FontWeight.BOLD
  ) => {
    return (
      <Typography
        sx={{ textAlign: "center" }}
        fontSize={fontSize}
        fontWeight={fontWeight}
        pl={1}
        pt={pt}
      >
        {text}
      </Typography>
    );
  };

  const createDraftCall = () => {
    const payload = {
      applicationId: applicationId,
    };

    execute(payload, handleResponse);
  };

  const updateDraftCall = () => {
    const payload = {
      applicationId: applicationId,
    };

    update(payload, handleResponse);
  };

  const confirmAlert = () => {
    return (
      <Modal
        open={alertOpen}
        onClose={handleCloseAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          {data?.draftStatus === 'REJECTED' ? "Please confirm, are you sure want to update draft" :  "Please confirm, are you sure want to create draft" }  
          </Typography>
          <Box
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <Button onClick={() => handleCloseAlert()} variant="outlined">No</Button>
            <Button
              onClick={() => {
                data?.draftStatus === 'REJECTED' ?  updateDraftCall() :
                createDraftCall();
              }}
              variant="contained"
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={loading || acceptLoading || rejectLoading || updateLoading} />
        {confirmAlert()}
        {acceptAlert()}
        {rejectAlert()}
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"baseline"}
        >
          <IconButton onClick={() => handleDownloadPdf("page-content")}>
            <Typography mr={1}>Download</Typography>
            <FileDownloadRoundedIcon />
          </IconButton>
        </Box>

        <Box id="page-content" sx={{ padding: 5, backgroundColor: "white" }}>
          <Box
            pt={7}
            pl={2}
            pr={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            width={"100%"}
            sx={{ backgroundColor: "white" }}
          >
            <Box
              sx={{
                backgroundImage: `url(${logo})`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `200px 50px`,
                height: 50,
                width: 200,
              }}
            />
            <Typography
              fontSize={24}
              fontWeight={FontWeight.BOLD}
              pl={2}
              pt={3}
            >
              Halal Registration Certificate Draft
            </Typography>
            {commonText(
              "This is to certify that the establishment described below has been inspected and assessed by Halal Point and found to comply with Halal requirement.",
              16,
              4
            )}
            {commonText(nameOfCompany, 20, 4)}
            {commonText(address, 18, 4, FontWeight.REGULAR)}
            {commonText("Scope : Food Products", 18, 4, FontWeight.REGULAR)}
            {commonText(
              "Registration No. : JUG/4765/OOOTTEH/XXX",
              18,
              4,
              FontWeight.REGULAR
            )}
            {commonText("Issued on : 24-04-2024", 18, 4, FontWeight.REGULAR)}
            {commonText(
              "Validity : 24-04-2024 to 23-05-2025",
              18,
              4,
              FontWeight.REGULAR
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              pt: 5,
              pl: 5,
              backgroundColor: "white",
            }}
          >
            {commonText("Authorized Signatory", 18, 4, FontWeight.BOLD)}
            <Box
              sx={{
                backgroundImage: `url(${dummySignature})`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `150px 150px`,
                height: 150,
                width: 150,
              }}
            />
            {commonText("Rajeev KR", 18, 0, FontWeight.BOLD)}
            {commonText("Secretary", 14, 0, FontWeight.REGULAR)}
          </Box>
        </Box>

         {/* ADMIN CREATE BUTTON */}
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          {role === USERTYPE.ADMIN && (
            <Box>
              {(success || data?.draftStatus === null ||  data?.draftStatus === undefined) && (
                <Button
                  onClick={() => {
                    handleOpenAlert();
                  }}
                  variant="contained"
                >
                     Create Draft
                </Button>
              )}
            </Box>
          )}
            {/* ADMIN UPDATE BUTTON */}
          {(role === USERTYPE.ADMIN && (data?.draftStatus === "REJECTED" || success )) && (
            <Box>
                <Button
                  onClick={() => {
                    handleOpenAlert();
                  }}
                  variant="contained"
                >
                  Update Draft
                </Button>
            </Box>
          )}

          {role === USERTYPE.USER &&
          <Box>
          {(success || data.draftStatus === "CREATED" || data.draftStatus === "UPDATED") && (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Button
                  onClick={() => {
                    handleOpenAcceptAlert();
                  }}
                  variant="outlined"
                  sx={{ mr: 2, color: "green", borderColor: "green" }}
                >
                  Accept
                </Button>
                <Button
                  onClick={() => {
                    handleOpenRejectAlert();
                  }}
                  variant="outlined"
                  sx={{ mr: 2, color: "red", borderColor: "red" }}
                >
                  Reject
                </Button>
              </Box>
            )}
            </Box>}
        </Box>
        {data.draftStatus === "REJECTED" && (
          <Box mt={2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Typography color={'red'}>Draft is Rejected. Reason : {data?.draftRejectReason} </Typography>
          </Box>
        )}
        {message && (
          <Alert severity={success ? "success" : "error"}>{message}</Alert>
        )}
      </DashboardContainer>
    </AdminLayout>
  );
};

export default AuditDarft;
