import { makeApiRequest } from "../../networking/apiClient";
import {
  ACCEPTQUOTATION,
  GETQUOTATION,
  REQUESTCHANGEINQUOTATION,
} from "../../networking/endpoints";

export const getQuoation = async (payload: any) => {
  try {
    const response = await makeApiRequest(GETQUOTATION, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const acceptQuotation = async (payload: any) => {
  try {
    const response = await makeApiRequest(ACCEPTQUOTATION, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const requestChange = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      REQUESTCHANGEINQUOTATION,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
