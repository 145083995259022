import AdminLayout from "../components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import { Box, Grid, Typography, useMediaQuery, IconButton } from "@mui/material";
import Loader from "../../../core/components/Loader";
import { useEffect, useState } from "react";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { createData, getCurrentDateFormatted, getDateMinusMonths } from "../../../core/utils";
import { adminDashboard } from "../../../store/Dashboard/dashboardService";
import OrderTable from "../AuditorDashboard/components/Recent";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import SummaryChart from "../components/SummaryChart";
import {
  AccountBalanceWallet as WalletIcon,
  CheckCircle as CheckCircleIcon,
  PendingActions as PendingIcon,
  AssignmentTurnedIn as CompletedIcon,
  HourglassBottom as InProgressIcon,
  Drafts as DraftsIcon,
  Receipt as QuoteIcon,
  Payments as PaymentsIcon,
  EventAvailable as UpcomingIcon
} from "@mui/icons-material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const itemsPerPage = 4;

const AdminDashboard = () => {
  const user = useSelector((state: any) => state.auth.user);
  const { role } = user;
  const [recent, setRecent] = useState<any[]>([]);
  const [responseData, setResponseData] = useState<any>({});
  const [data, setData] = useState<any[]>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { execute, loading: loading } = useAsyncRequest(adminDashboard);

  const [currentPage, setCurrentPage] = useState(0);

  const cards = [
    { title: "All Applications", value: responseData?.total, color: "#3f51b5", icon: <WalletIcon style={{ color: "#3f51b5" }} />},
    { title: "Active applications", value: responseData?.active, color: "#4caf50", icon: <CheckCircleIcon style={{ color: "#4caf50" }} /> },
    { title: "Completed audits", value: responseData?.completed, color: "#ff9800", icon: <CompletedIcon style={{ color: "#ff9800" }} /> },
    { title: "Upcoming audits", value: responseData?.upcoming, color: "#00bcd4", icon: <UpcomingIcon style={{ color: "#00bcd4" }} /> },
    { title: "Draft created", value: responseData?.drafts, color: "#9c27b0", icon: <DraftsIcon style={{ color: "#9c27b0" }} /> },
    { title: "In-progress audits", value: responseData?.inprogress, color: "#f44336", icon: <InProgressIcon style={{ color: "#f44336" }} /> },
    { title: "Quote created", value: responseData?.quoted, color: "#ffc107", icon: <QuoteIcon style={{ color: "#ffc107" }} /> },
    { title: "Payments done", value: responseData?.paymentDoneBy, color: "#673ab7", icon: <PaymentsIcon style={{ color: "#673ab7" }} /> },
    { title: "Decision pending", value: responseData?.decisionPending, color: "#607d8b", icon: <PendingIcon style={{ color: "#607d8b" }} /> },
  ];

  const totalPages = Math.ceil(cards.length / itemsPerPage);
  const displayedCards = cards.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      const { recentList } = data;
      let list: any = [];
      setRecent(recentList);
      setResponseData(data);
      recentList.map((item: any) => {
        list.push(
          createData(
            item.applicationNo ? item.applicationNo : "-",
            item.companyDetail.nameOfCompany,
            item.createdDate ? item.createdDate : "-",
            item.certification ? item.certification : "-",
            item.activeStatus ? item.activeStatus : "Active"
          )
        );
      });
      setData(list);
    }
  };

  const onChangeDate = (val : any) =>{
    console.log(val)
  }

  useEffect(() => {

    const payload = {
      startDate : getDateMinusMonths(6),
      endDate :  getCurrentDateFormatted()
    }

    execute(payload, handleResponse);
  }, []);

  const card = (title: string, subTitle: string, borderColor: string, Icon: JSX.Element, iconColor: string) => {
    return (
      <Box
        sx={{
          height: 100,
          width: isMobile ? "100%" : 267,
          letterSpacing: '0.5px',
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          p: 2,
          backgroundColor: "white",
          borderRadius: '26px',  
          color: "#72777a" ,
          mx: 1,
          border: `1px solid ${iconColor}`,
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.05)",
            "& .icon": {
              transform: "translateX(5px)", 
        },
      },
        }}
      >
         <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: 'space-between' }}>
        <Typography variant="body2" fontWeight={600}>
          {title}
        </Typography>
        {Icon}
      </Box>
      <Typography fontWeight={700}>{subTitle}</Typography>
    </Box>
    );
  };

  return (
    <>
    <AdminLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <Box
          sx={{
            flex: 6,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
         {!isMobile ? (
           <DashboardContainer>
           <Typography variant="h6">Status</Typography>
               <Box
                 sx={{
                   display: "flex",
                   flexWrap: "wrap",
                   gap: 2,
                   mb: 4,
                   mt:2,
                 }}
               >
                  {card("All Applications", responseData?.total, "#111111", <WalletIcon style={{ color: "#3f51b5" }} />, "#3f51b5")}
                  {card("Active applications", responseData?.active, "#fff", <CheckCircleIcon style={{ color: "#4caf50" }} />, "#4caf50")}
                  {card("Completed audits", responseData?.completed, "#fff", <CompletedIcon style={{ color: "#ff9800" }} />, "#ff9800")}
                  {card("Upcoming audits", responseData?.upcoming, "#fff", <UpcomingIcon style={{ color: "#00bcd4" }} />, "#00bcd4")}
                  {card("Draft created", responseData?.drafts, "#fff", <DraftsIcon style={{ color: "#9c27b0" }} />, "#9c27b0")}
                  {card("In-progress audits", responseData?.inprogress, "#fff", <InProgressIcon style={{ color: "#f44336" }} />, "#f44336")}
                  {card("Quote created", responseData?.quoted, "#fff", <QuoteIcon style={{ color: "#ffc107" }} />, "#ffc107")}
                  {card("Payments done", responseData?.paymentDoneBy, "#fff", <PaymentsIcon style={{ color: "#673ab7" }} />, "#673ab7")}
                  {card("Decision pending", responseData?.decisionPending, "#fff", <PendingIcon style={{ color: "#607d8b" }} />, "#607d8b")}
               </Box>
         </DashboardContainer>
         ): (<>
          <Typography variant="h6">Status</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 1,
                    justifyContent: "center",
                  }}
                >
                 {displayedCards.map((cardData) =>
                    card(cardData.title, cardData.value, cardData.color, cardData.icon, cardData.color)
                  )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IconButton onClick={handlePrevious} disabled={currentPage === 0}>
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <IconButton onClick={handleNext} disabled={currentPage === totalPages - 1}>
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              </>
         )}
        </Box>

        <Box
          sx={{
            flex: 4,
          }}
        >
         { !isMobile ? (
          <DashboardContainer>
          <Box sx={{ pb: 2 }}>
            <SummaryChart data={responseData} />
          </Box>
        </DashboardContainer>
      ): (
        <Box sx={{ pb: 2 }}>
          <SummaryChart data={responseData} />
        </Box>
      )}
        </Box>
      </Box>

      { !isMobile ? (
         <DashboardContainer>
         <Loader open={loading} />
 
         <Grid item xs={12} md={7} lg={8}>
           <Grid container alignItems="center" justifyContent="space-between">
             <Grid item>
               <Typography variant="h6" mb={3}>Recent Applications</Typography>
             </Grid>
             <Grid item />
           </Grid>
           <OrderTable data={data} resData={recent} role={role} />
         </Grid>
       </DashboardContainer>
      ) : (
        <>
        <Loader open={loading} />

        <Grid item xs={12} md={7} lg={8}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h6" mb={3} mt={2}>Recent applications</Typography>
            </Grid>
            <Grid item />
          </Grid>
          <OrderTable data={data} resData={recent} role={role}/>
        </Grid>
        </>
      )}    
    </AdminLayout>
    </>
  );
};

export default AdminDashboard;