import { Box, Typography } from "@mui/material";
import DashboardContainer from "../../core/components/DashboardContainer";
import AdminLayout from "../dashboard/components/AdminLayout";
import AuditorListTable from "./components/Table";
import AddAuditor from "./addAuditor";
import { useState } from "react";

const AuditorList = () => {
  const [trigger, setTrigger] = useState(false);

  return (
    <AdminLayout>
      <DashboardContainer>
        <Box display={"flex"} justifyContent={"space-between"} pr={2}>
          <Typography variant="h6" pl={2}>
            Auditor list
          </Typography>
          <AddAuditor
            trigger={() => {
              setTrigger(!trigger);
            }}
          />
        </Box>
        <AuditorListTable trigger={trigger} />
      </DashboardContainer>
    </AdminLayout>
  );
};

export default AuditorList;
