import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextInputTextField from "../../../../../core/components/Input/TextInputTextField";
import useAsyncRequest from "../../../../../networking/useAsyncRequest";
import { requestChange } from "../../../../../store/Oboarding/quotationServices";
import Loader from "../../../../../core/components/Loader";

const RequestChange = ({ applicationId, onSuccess }: any) => {
  const [open, setOpen] = React.useState(false);
  const [remarks, setRemarks] = React.useState(false);

  const { execute, loading } = useAsyncRequest(requestChange);

  const handleReponse = (res: any) => {
    const { status } = res;
    if (status == 200) {
      handleClose();
      onSuccess();
    }
  };

  const handleSubmit = () => {
    const payload = {
      applicationId: applicationId,
      changeRemark: remarks,
    };
    execute(payload, handleReponse);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Request Change in Quotation
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Loader open={loading} />
        <DialogTitle id="alert-dialog-title">
          {"Request Change in Quoatation"}
        </DialogTitle>
        <DialogContent sx={{ p: 5 }}>
          <DialogContentText id="alert-dialog-description">
            Please specify your requirement breify
          </DialogContentText>
          <TextInputTextField
            label=""
            row={2}
            multiline
            onChange={(value: any) => {
              console.log(setRemarks(value.target.value));
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default RequestChange;
