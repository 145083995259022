import { Box, Typography, useMediaQuery } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from "chart.js";
import { useTheme } from "@mui/material/styles";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

interface SummaryChartProps {
  data: {
    total: number;
    active: number;
    completed: number;
    upcoming: number;
    drafts: number;
    inprogress: number;
    quoted: number;
    paymentDoneBy: number;
  };
}


const SummaryChart = ({ data }: SummaryChartProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const chartData = {
    labels: [
      'Total Applications',
      'Active Applications',
      'Completed Audits',
      'Upcoming Audits',
      'Drafts Created',
      'In Progress Audits',
      'Quotes Created',
      'Total Payment Done'
    ],
    datasets: [
      {
        label: 'Summary Data',
        data: [
          data.total,
          data.active,
          data.completed,
          data.upcoming,
          data.drafts,
          data.inprogress,
          data.quoted,
          data.paymentDoneBy
        ],
        backgroundColor: [
          '#3f51b5',
          '#4caf50',
          '#ff9800',
          '#00bcd4',
          '#9c27b0',
          '#f44336',
          '#ffc107',
          '#673ab7'
        ],
        borderWidth: 1,
      }
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false // Disable the default legend
      }
    }
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', height: 400 }}>
       <Typography variant="h6" sx={{ mb: 2 }}>
        Summary Overview
      </Typography>
      {/* Centered chart */}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:  !isMobile ? '80%': '70%', width: !isMobile ? '90%': '100%' }}>
        <Doughnut data={chartData} options={options} />
      </Box>

    { !isMobile ? (
      <Box sx={{ position: 'absolute', bottom: 10, right: 10, fontSize: '0.5rem', }}>
      {chartData.labels.map((label, index) => (
        <Typography key={label} sx={{ display: 'flex', alignItems: 'center', mb: 1, fontSize: '0.5rem' }}>
          <Box
            sx={{
              width: 12,
              height: 12,
              backgroundColor: chartData.datasets[0].backgroundColor[index],
              mr: 1,
              borderRadius: "50%",
            }}
          />
          {label}
        </Typography>
      ))}
    </Box>
    ) : (<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, fontSize: '0.5rem', mt: 2 }}>
    {chartData.labels.map((label, index) => (
      <Typography key={label} sx={{ display: 'flex', alignItems: 'center', mb: 1, fontSize: '0.5rem' }}>
        <Box
          sx={{
            width: 12,
            height: 12,
            backgroundColor: chartData.datasets[0].backgroundColor[index],
            mr: 1,
            borderRadius: "50%",
          }}
        />
        {label}
      </Typography>
    ))}
  </Box>)}
     
    </Box>
  );
};

export default SummaryChart;
