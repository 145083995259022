import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Button,
  CssBaseline,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useGoogleLogout } from "react-google-login";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../store/Auth/authSlice";
import {
  adminDrawerList,
  auditorDrawerList,
  decisionDrawerList,
  userDrawerList,
} from "../../constants";
import { useLocation } from "react-router-dom";
import { USERTYPE } from "../../../../core/constants";
import DrawerMenu from "./components/Drawer";
import MobileBottomNavbar from "../../../../core/components/MobileBottomNavbar";


const AdminLayout = ({ children }: any) => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [drawerList, setDrawerList] = useState<any>(adminDrawerList);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const user = useSelector((state: any) => state.auth.user);

  const { role } = user;

  const dispatch = useDispatch();

  const toggleDrawer = (open: any) => () => {
    setDrawerOpen(open);
  };

  const drawerRef = useRef<any>(null);

  useEffect(() => {
    setDrawerOpen(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    switch (role) {
      case USERTYPE.ADMIN:
        setDrawerList(adminDrawerList);
        break;
      case USERTYPE.AUDITOR:
        setDrawerList(auditorDrawerList);
        break;
      case USERTYPE.DECISION:
        setDrawerList(decisionDrawerList);
        break;
      case USERTYPE.USER:
        setDrawerList(userDrawerList);
        break;
    }
  }, [role]);

  useEffect(() => {
    const currentPath = location.pathname;
    const currentIndex = drawerList.findIndex(
      (item: any) => item.nav === currentPath
    );
    setSelectedIndex(currentIndex !== -1 ? currentIndex : 0);
  }, [location.pathname, drawerList]);

  const clientId =
    "830136945700-4sukjrick8hl7m10rlj7al7jhgfmntvo.apps.googleusercontent.com";

  const onLogoutSuccess = () => {
    console.log("Logout successful");
  };

  const onFailure = () => {
    console.log("Logout failed");
  };

  const { signOut, loaded } = useGoogleLogout({
    clientId: clientId,
    onLogoutSuccess,
    onFailure,
    cookiePolicy: "single_host_origin",
    fetchBasicProfile: false, // optional, if you don’t need profile info
    accessType: "offline", // optional
    scope: "",
  });

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        maxWidth: "100%",
        height: "100vh",
        backgroundColor: "white",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{
          width: isMobile ? "100%" : "100%",
          background: "rgba(253, 251, 251, 0.25)",
          boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
          backdropFilter: "blur(7.5px)",
          WebkitBackdropFilter: "blur(7.5px)",
          borderRadius: "10px",
          border: "1px solid rgba(255, 255, 255, 0.18)",
        }}
      >
        <Toolbar>
          {isMobile && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon style={{ color: "black" }} />
            </IconButton>
          )}
          <Typography variant="h6" style={{ flexGrow: 1 }}></Typography>
          <Avatar
            alt={user.firstName}
            src="/static/images/avatar/2.jpg"
            style={{ color: "black" }}
          />
          <Button
            color="inherit"
            startIcon={<LogoutIcon />}
            style={{ marginLeft: 10, color: "black" }}
            onClick={() => {
              signOut();
              dispatch(logout());
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      <DrawerMenu
        drawerList={drawerList}
        selectedIndex={selectedIndex}
        drawerOpen={drawerOpen}
        onClose={(val: any) => {
          setDrawerOpen(val);
        }}
      />

      <Box
        style={{
          flexGrow: 1,
          paddingBottom: 20,
          marginTop: 60,
          width: "100%",
          height: "93vh",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: '20px'
        }}
      >
        {children}
      </Box>
      {/* { isMobile &&  <>
      <Box>
        <MobileBottomNavbar />
      </Box>
    </>
    } */}
    </Box>
  );
};

export default AdminLayout;
