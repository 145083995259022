import { Box, IconButton, Typography } from "@mui/material";
import { useRef, useState } from "react";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";

const UploadField = ({ onSelectFile }: any) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<FileList | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      setFiles(selectedFiles);
      onSelectFile(selectedFiles);
    }
  };

  const handleClick = (event: any) => {
    hiddenFileInput.current?.click();
  };

  const getFileLength = (file: any) => {
    let fileName = "";
    if (file.length == 1) {
      fileName = file[0].name;
    } else {
      fileName = file[0].name + " + " + (file.length - 1) + " more";
    }
    return fileName;
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <IconButton onClick={handleClick}>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
          multiple
        />
        <CloudUploadRoundedIcon
          color="primary"
          sx={{ height: 30, width: 30 }}
        />
      </IconButton>
      {files && <Typography>{files.length + " Selected"}</Typography>}
    </Box>
  );
};

export default UploadField;
