import { Box, useMediaQuery, useTheme } from "@mui/material";
import { TextFieldType } from "../../../core/types";
import TextInputLight from "../../../core/components/Input/TextInputLight";

const RowTextField = ({
  name1,
  label1,
  onChange1,
  onBlur1,
  name2,
  label2,
  onChange2,
  onBlur2,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%",
        flexDirection : isMobile ? 'column' : 'row'
      }}
    >
      <Box  width={ isMobile ? "100%" :400}>
      <TextInputLight
        mt={2}
        type={TextFieldType.TEXT}
        name={name1}
        label={label1}
        onChange={onChange1}
        onBlur={onBlur1}
        width={ isMobile ? "100%" :400}
        textFieldHeigt={50}
      />
      </Box>
      {label2 ? (
         <Box  width={ isMobile ? "100%" :400}>
        <TextInputLight
          mt={2}
          type={TextFieldType.TEXT}
          name={name2}
          label={label2}
          onChange={onChange2}
          onBlur={onBlur2}
          width={isMobile ? "100%" :400}
          textFieldHeigt={50}
        />
        </Box>
      ) : (
        <Box width={isMobile ? "100%" : 400}></Box>
      )}
    </Box>
  );
};

export default RowTextField;
