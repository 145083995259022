import { TextFieldType } from "../../../core/types";

export interface UserDetails {
  type?: TextFieldType;
  name?: string;
  label?: string;
  value?: string; // Optional property
  row?: number; // Optional property
  multiline?: boolean; // Optional property
  disabled?: boolean;
}

export const admimUserDetails: UserDetails[] = [
  {
    type: TextFieldType.TEXT,
    name: "firstName",
    label: "First Name",
    value: "",
  },
  {
    type: TextFieldType.TEXT,
    name: "lastName",
    label: "Last Name",
    value: "",
  },
  {
    type: TextFieldType.TEXT,
    name: "email",
    label: "Email",
    value: "",
    disabled: true,
  },
  {
    type: TextFieldType.TEXT,
    name: "designation",
    label: "Designation",
    value: "",
    disabled: true,
  },
];

export const adminUserInitialValue: Record<string, any> = {
  firstName: "",
  lastName: "",
  email: "",
  designation: "",
};

export const userDetails: UserDetails[] = [
  {
    type: TextFieldType.TEXT,
    name: "firstName",
    label: "First Name",
    value: "",
  },
  {
    type: TextFieldType.TEXT,
    name: "lastName",
    label: "Last Name",
    value: "",
  },
  {
    type: TextFieldType.TEXT,
    name: "email",
    label: "Email",
    value: "",
    disabled: true,
  },
  {
    type: TextFieldType.TEXT,
    name: "designation",
    label: "Designation",
    value: "",
    disabled: true,
  },
];

export const userInitialValue: Record<string, any> = {
  firstName: "",
  lastName: "",
  email: "",
  designation: "",
};
