import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  details: {},
};

const onBoardingSlice = createSlice({
  name: "onboardinf",
  initialState,
  reducers: {
    applicationDetails: (state: any, action: any) => {
      state.details = action.payload;
    },
  },
});

export const { applicationDetails } = onBoardingSlice.actions;

export default onBoardingSlice.reducer;
