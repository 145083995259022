import { Box, Typography } from "@mui/material";
import { FontWeight } from "../../types";

const TextWithLabel = ({
  title,
  subTitle,
  titleFontSize = 14,
  subTitleFontSize = 15,
}: any) => {
  return (
    <Box p={1}>
      <Typography
        color={"#929396"}
        fontWeight={FontWeight.MEDIUM}
        fontSize={titleFontSize}
      >
        {title}
      </Typography>
      <Typography
        pt={0.5}
        //color={"#000000"}
        fontWeight={FontWeight.MEDIUM}
        fontSize={subTitleFontSize}
      >
        {subTitle ? subTitle : "-"}
      </Typography>
    </Box>
  );
};
export default TextWithLabel;
