import { Box, Typography, Button, Link, Modal, Alert } from "@mui/material";
import TextInput from "../../../../core/components/Input/TextInput";
import { FontWeight, TextFieldType } from "../../../../core/types";
import { Formik } from "formik";
import { signUpValidationSchema } from "../../../../core/validations/formSchema";
import { useState } from "react";
import useAsyncRequest from "../../../../networking/useAsyncRequest";
import {
  googleLogin,
  userRegistration,
} from "../../../../store/Auth/authService";
import Loader from "../../../../core/components/Loader";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

const SignUpForm = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<any>(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState<any>("");

  const { execute, loading } = useAsyncRequest(userRegistration);

  const onSubmit = async (data: any) => {
    await execute({ ...data, role: "USER" }, handleResponse);
  };

  const clientId =
    "830136945700-4sukjrick8hl7m10rlj7al7jhgfmntvo.apps.googleusercontent.com";

  const { execute: glogin, loading: loginLoading } =
    useAsyncRequest(googleLogin);

  const onSuccess = (res: any) => {
    console.log("Success", res);

    const { profileObj } = res;

    const { email, givenName, familyName } = profileObj;

    const payload = {
      firstName: givenName,
      lastName: familyName,
      email: email,
      role: "USER",
    };
    glogin(payload, handleResponse);
  };

  const onFailure = (res: any) => {
    console.log("Success", res);
  };

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      setIsSuccess(true);
      setMessage(
        "Registration Successful. Please verify your E-mail to continue"
      );
      setOpen(true);
    } else {
      setIsSuccess(false);
      setMessage(data.message);
      setOpen(true);
    }
  };

  const onClose = () => {
    setOpen(false);
    navigate('/')
  };

  const alert = () => {
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        closeAfterTransition
      >
        <Box p={60} sx={style}>
          <Alert severity={isSuccess ? "success" : "error"}>{message}</Alert>
          <Button onClick={onClose} sx={{ marginTop: 4 }} variant="contained">
            Close
          </Button>
        </Box>
      </Modal>
    );
  };

  return (
    <Box>
      <Typography paddingTop={1} fontWeight={FontWeight.MEDIUM} fontSize={25}>
        Create User Account
      </Typography>
      <Loader loading={loading || loginLoading} />
      {alert()}
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
        }}
        validationSchema={signUpValidationSchema}
        onSubmit={(value) => {
          onSubmit(value);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextInput
              label="First name"
              onChange={handleChange}
              name="firstName"
              value={values.firstName}
              onBlur={handleBlur}
              error={
                errors.firstName && touched.firstName && errors.firstName
                  ? true
                  : false
              }
              helperText={
                errors.firstName && touched.firstName && errors.firstName
              }
              textFieldHeigt={40}
            />

            <TextInput
              label="Last name"
              onChange={handleChange}
              name="lastName"
              value={values.lastName}
              onBlur={handleBlur}
              error={
                errors.lastName && touched.lastName && errors.lastName
                  ? true
                  : false
              }
              helperText={
                errors.lastName && touched.lastName && errors.lastName
              }
              textFieldHeigt={40}
            />
            <Box>
              <TextInput
                label="Enter your email"
                onChange={handleChange}
                name="email"
                value={values.email}
                onBlur={handleBlur}
                error={
                  errors.email && touched.email && errors.email ? true : false
                }
                helperText={errors.email && touched.email && errors.email}
                textFieldHeigt={40}
              />
              <TextInput
                label="Enter your password"
                onChange={handleChange}
                type={TextFieldType.PASSWORD}
                name="password"
                value={values.password}
                onBlur={handleBlur}
                error={
                  errors.password && touched.password && errors.password
                    ? true
                    : false
                }
                helperText={
                  errors.password && touched.password && errors.password
                }
                textFieldHeigt={40}
              />
            </Box>
            <Typography fontSize={14} pt={2} pl={1} sx={{ color: "#000" }}>
              By Clicking “Sign Up” you agree with our{" "}
              <Link href={""} sx={{ color: "#2196F3", textDecoration: "none" }}>
                terms and conditions
              </Link>
            </Typography>
            <Box
              padding={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 25,
                alignItems: "center",
              }}
            >
              {/* <Typography fontSize={15} sx={{ color: "#000" }}>
                Already have a account ?{" "}
                <Link
                  href={SIGNIN}
                  sx={{ color: "#2196F3", textDecoration: "none" }}
                >
                  Login
                </Link>
              </Typography> */}
            </Box>
            <Button
              sx={{
                width: "100%",
                backgroundColor: "#2196f3",
                borderRadius: "20px",
              }}
              type="submit"
              variant="contained"
            >
              Sign Up
            </Button>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default SignUpForm;
