import { Box, Typography, useMediaQuery, useTheme, Avatar } from "@mui/material";
import { FontWeight } from "../../../../core/types";

const WelcomeComponent = ({ user }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Box
        p={3}
        height={200}
        width={"100%"}
        borderRadius={3}
        boxShadow={2}
        mb={2}
        sx={{ backgroundColor: "#52aaff" }}
        display={"flex"}
        flexDirection={"column"} 
        alignItems={isMobile ? "center" : "flex-start"}
        justifyContent={"center"} 
        gap={2} 
      >
        {/* Avatar */}
        <Avatar
          alt={`${user.firstName} ${user.lastName}`}
          src={user.avatarUrl}
          sx={{ width: isMobile ? 60 : 80, height: isMobile ? 60 : 80 }}
        />

        {/* Welcome Text */}
        <Typography
          fontWeight={FontWeight.BOLD}
          fontSize={!isMobile ? 30 : 20}
          color={"white"}
          textAlign={isMobile ? "center" : "left"}
        >
          Welcome, {user.firstName} {user.lastName}
        </Typography>
      </Box>
    </Box>
  );
};

export default WelcomeComponent;
