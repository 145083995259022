import { Box, Grid, Typography } from "@mui/material";
import TextWithLabel from "../../../core/components/TextWithLabel";
import { FontWeight } from "../../../core/types";
import DashboardContainer from "../../../core/components/DashboardContainer";

const CompanyDetail = (data: any) => {

  const detailField = (title: string, subtitle: any) => {
    const isBool = typeof subtitle === "boolean";
    let subTitle = "";
    if (isBool) {
      subTitle = subtitle === true ? "Yes" : "No";
    } else {
      subTitle = subtitle ? subtitle : "-";
    }
    return (
      <Grid item xs={2} sm={4} md={4}>
        {title && <TextWithLabel title={title} subTitle={subTitle} />}
      </Grid>
    );
  };

  const heading = (title: string) => {
    return (
      <Typography variant="h6" fontWeight={FontWeight.MEDIUM}>
        {title.toUpperCase()}
      </Typography>
    );
  };

  return (
    <Box >
       <DashboardContainer>
      {heading(" Company Details")}
      <Grid
        container
        spacing={4}
        alignItems={"baseline"}
        columns={{ xs: 2, sm: 12, md: 16 }}
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        {detailField("Name", data?.data?.nameOfCompany)}
        {detailField("Phone", data?.data?.telephone)}
        {detailField("Address", data?.data?.address)}
        {detailField("Pincode", data.data.pinCode)}
        {detailField("PAN", data.data.panNo)}
        {detailField("GST", data.data.gstNo)}
        {detailField("Invoice Address", data.data.invoiceAddress)}
        {detailField("Mail-ID", data.data.email)}
        {detailField("TAN", data.data.tanNo)}
        {detailField("Website", data.data.website)}
        {detailField("AnnualTurnover", data.data.annualTurnOver)}
        {detailField("Holding Company", data.data.holdingCompany)}
        {detailField("Director/CEO", data.data.directorCeoPartner)}
        {detailField("Mobile", data.data.directorCeoPartnerMobile)}
        {detailField("E-Mail", data.data.directorCeoPartnerEmail)}
        {detailField("", "")}
        {detailField("Food Safety Team / MR", data.data.foodSaftyTeamLeaderMR)}
        {detailField("Mobile", data.data.foodSaftyTeamLeaderMRMobile)}
        {detailField("E-Mail", data.data.foodSaftyTeamLeaderMREmail)}
      </Grid>
      </DashboardContainer>
      <DashboardContainer>
      {heading("Any previous certifications / Transfer of certification")}
      <Grid
        container
        spacing={4}
        columns={{ xs: 2, sm: 12, md: 16 }}
        alignItems={"baseline"}
        sx={{
          paddingTop: 2,
          paddingRight: { xs: 2, md: 4 },
          paddingBottom: 2,
          alignItems: "end",
        }}
      >
        {detailField(
          "Food Safty Team Le Standard certified for /Certificate to be transferred ader / MR",
          data.data.previousCertificateDetails
        )}
        {detailField("Valid till", data.data.previousCertificateValidity)}
        {detailField(
          "Certification Body (Previous)",
          data.data.previousCertificateBody
        )}
        {detailField("", "")}
      </Grid>
      </DashboardContainer>
      <DashboardContainer>
      {heading(" Details of operations in the Main Unit / Head office")}
      <Grid
        container
        spacing={4}
        columns={{ xs: 2, sm: 12, md: 16 }}
        sx={{
          paddingTop: 2,
          paddingRight: { xs: 2, md: 4 },
          paddingBottom: 2,
          alignItems: "end",
        }}
      >
        {detailField("Number of work shifts", data.data.noOfWorkShifts)}
        {detailField(
          "Number of Employee per shift",
          data.data.noOfEmployeePerShift
        )}
        {detailField("Season of Operation", data.data.seasonOfOperation)}
        {detailField(
          "Language of Communication",
          data.data.LanguageOfCommunication
        )}
        {detailField(
          "Outsourced Processes (if any)",
          data.data.outSourceProccess
        )}
        {detailField("Exclusions", data.data.exclusions)}
        {detailField(
          "Approvals / Statutory &   Regulatory requirements / Legal status e.g FSSAI License number   with Validity for   India",
          data.data.approvalsSatutoryAndRegulatoryRequirementsOrLegalStatus
        )}
        {detailField(
          "Any Activity belongingto the same legal entity of the parent organization applied for Certification",
          data.data.activityBelongingToLegalEntity
        )}
        {detailField(
          "For Dairy Units: Number of Collection Centers/ Milk Chilling centers belonging to the same legal entity of the parent organization applied for Certification",
          data.data.noOfCollectionsCentersForMilk
        )}
        {detailField("", "")}
        {detailField("", "")}
        {detailField("", "")}
        {detailField(
          "Intended user is Vulnerable to specific food safety hazard",
          data.data.foodSaftyHazard
        )}
        {detailField("In-house laboratory", data.data.inHouseLaboratory)}
        {detailField("Product Development (R&D)", data.data.productDevelopment)}
      </Grid>
      </DashboardContainer>
      <DashboardContainer>
      {heading("Details of Sites")}
      <Grid
        container
        spacing={4}
        columns={{ xs: 2, sm: 12, md: 16 }}
        sx={{
          paddingTop: 2,
          paddingRight: { xs: 2, md: 4 },
          paddingBottom: 2,
          alignItems: "end",
        }}
      >
        {detailField(
          "Total Numberof Sites/ Location",
          data.data.totalNumberOfSites
        )}
        {detailField(
          "Permanent Number of Site/Location",
          data.data.noOfSiteLocationPermanent
        )}
        {detailField(
          "Temporary Number of Site/Location",
          data.data.noOfSiteLocationTemporary
        )}
        {detailField("", "")}
        {detailField("", "")}
        {detailField(
          "Permanent Additional for scope extension",
          data.data.additionalScopeExtensionPermanent
        )}
        {detailField(
          "Temporary Additional for scope extension",
          data.data.additionalScopeExtensionTemporary
        )}
        {detailField("", "")}
        {detailField(
          "No. of products/production lines",
          data.data.noOfProducts
        )}
        {detailField("Building Area (in sq m)", data.data.buildingArea)}
        {detailField(
          "Any other certified Management Systems? E.g. ISO 9001:2015, ISO 14001:2015, ISO 45001:2018 etc.",
          data.data.otherCertificateManagementSystem
        )}
        {/* {detailField("Main site", data.data.mainSite)}
        {detailField("Site 2", data.data.siteTwo)}
        {detailField("Site 3", data.data.siteThree)}
        {detailField("Site 4", data.data.siteFour)}
        {detailField("Site 5", data.data.siteFive)}
        {detailField("Site 6", data.data.siteSix)} */}
        {detailField(
          "Total No. Of O-PRP & CCP more than 10",
          data.data.totalOPRPandCCP
        )}
      </Grid>
      </DashboardContainer>
      <DashboardContainer>
      {heading("Attachments for Food Scheme")}
      <Grid
        container
        spacing={4}
        columns={{ xs: 2, sm: 12, md: 16 }}
        alignItems={"baseline"}
        sx={{
          paddingTop: 2,
          paddingRight: { xs: 2, md: 4 },
          paddingBottom: 2,
          alignItems: "end",
        }}
      >
        {detailField("Legal status", data.data.legalStatus)}
        {detailField("List of raw material(s)", data.data.listOfRawMaterials)}
        {detailField("Process details", data.data.processDetails)}
        {detailField(
          "Food Safety Management system issues",
          data.data.foodSaftyManagementSystemIssues
        )}
        {detailField("HACCP analysis plan", data.data.haccpAnalysisPlan)}
        {detailField(
          "Test Reports and/or Certificate of Analysis",
          data.data.testReports
        )}
        {detailField(
          "Product Labels/Artwork of Primary and Secondary Packages",
          data.data.productLabels
        )}
      </Grid>
      </DashboardContainer>
      <DashboardContainer>
      {heading("Attachments for Slaughterhouse")}
      <Grid
        container
        spacing={4}
        columns={{ xs: 2, sm: 12, md: 16 }}
        alignItems={"baseline"}
        sx={{
          paddingTop: 2,
          paddingRight: { xs: 2, md: 4 },
          paddingBottom: 2,
          alignItems: "end",
        }}
      >
        {detailField(
          "Trade/Industrial/Slaughterhouse License",
          data.data.tradeOrSlaughterHouseLicense
        )}
        {detailField(
          "List of Slaughter persons and their competency requirements",
          data.data.listOfSlaughterPerson
        )}
        {detailField("Process Flowchart", data.data.proccessFlowChart)}
        {detailField(
          "Certificate for Critical Ingredients",
          data.data.certificateForCriticalIngredients
        )}
        {detailField("Health Certificate", data.data.healthCertificate)}
      </Grid>
      </DashboardContainer>
      <DashboardContainer>
      {heading("Filled by")}
      <Grid
        container
        spacing={4}
        columns={{ xs: 2, sm: 12, md: 16 }}
        alignItems={"baseline"}
        sx={{
          paddingTop: 2,
          paddingRight: { xs: 2, md: 4 },
          paddingBottom: 2,
          alignItems: "end",
        }}
      >
        {detailField("Name", data.data.nameOfCompany)}
        {detailField("Position", data.data.telephone)}
      </Grid>
      </DashboardContainer>
    </Box>
  );
};

export default CompanyDetail;
