import { Box } from "@mui/material";
import Certificaions from "./Pages/Certifications";

const OnBoarding = () => {
  return (
    <Box>
      <Certificaions />
    </Box>
  );
};

export default OnBoarding;
