import { apiClient, makeApiRequest } from "../../networking/apiClient";
import {
  ACTIVEAPPLICATIONS,
  ADDAUDITORCHECKLIST,
  DELETEAUDITORCHECKLIST,
  GETALLAUDITORCHECKLIST,
  UPDATEAUDITORCHECKLIST,
} from "../../networking/endpoints";

export const getActiveApplication = async () => {
  try {
    const response = await apiClient.get(ACTIVEAPPLICATIONS);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addAuditCheckList = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      ADDAUDITORCHECKLIST,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllAuditorCheckList = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      GETALLAUDITORCHECKLIST,
      "GET",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateAuditorCheckList = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      UPDATEAUDITORCHECKLIST,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCheckList = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      DELETEAUDITORCHECKLIST,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
