import { Box, Typography } from "@mui/material";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { getActiveApplicationAuditor } from "../../../store/Dashboard/dashboardService";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import OrderTable from "../../dashboard/AuditorDashboard/components/Recent";
import Loader from "../../../core/components/Loader";
import moment from "moment";

function createData(
  name: string,
  address: any,
  applicationDate: any,
  type: any,
  quotation: any,
  status: any
) {
  return { name, address, applicationDate, type, quotation, status };
}

const UpcommingAudit = () => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.user);
  const { role, email } = user;
  const [responseData, setResponseData] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);

  const { execute: executeAuditorList, loading: auditorListLoading } =
    useAsyncRequest(getActiveApplicationAuditor);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      let list: any = [];
      const upcomming = data.filter(
        (item: any) => item.activeStatus === "Audit Planned"
      );
      setResponseData(upcomming);
      upcomming.map((item: any) => {
        list.push(
          createData(
            item.companyDetail.nameOfCompany,
            item.companyDetail.address,
            item.createdDate
              ? moment(item.createdDate).format("DD/MM/YYYY")
              : "-",
            item.certification,
            item.quoteTotal ? item.quoteTotal : "-",
            item.activeStatus ? item.activeStatus : "Active"
          )
        );
      });
      setData(list);
    }
  };

  useEffect(() => {
    const payload = {
      email: email,
    };
    executeAuditorList(payload, handleResponse);
  }, [navigate]);

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={auditorListLoading} />
        <Typography variant="h5">Upcoming Audits</Typography>
        <OrderTable data={data} resData={responseData} role={role} />
      </DashboardContainer>
    </AdminLayout>
  );
};

export default UpcommingAudit;
