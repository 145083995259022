import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../dashboard/components/AdminLayout";
import DashboardContainer from "../../core/components/DashboardContainer";
import SuccessIcon from "../../Icons/SuccessIcon";
import { FontWeight } from "../../core/types";
import { DASHBOARD } from "../../router/config";

const ApplicationStatus = () => {
  const navigate = useNavigate();

  return (
    <AdminLayout>
      <DashboardContainer>
        <Box
          display="flex"
          justifyContent="start"
          alignItems={"center"}
          flexDirection={"column"}
          sx={{ mt: { xs: 2, md: 20 } }}
          height={"65vh"}
        >
          <SuccessIcon />
          <Typography mt={2} variant="h6" textAlign={'center'} fontWeight={FontWeight.BOLD}>
            Application is assigned to auditor
          </Typography>
          <Typography mt={2} variant="h6" textAlign={'center'} fontWeight={FontWeight.BOLD}>
            Application status will be changed once audit is completed
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            onClick={() => {
              navigate(DASHBOARD, { replace: true });
            }}
          >
            Back to dashboard
          </Button>
        </Box>
      </DashboardContainer>
    </AdminLayout>
  );
};
export default ApplicationStatus;
