import { Typography } from "@mui/material";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";

const AllApplications = () => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.user);
  const { role } = user;

  return (
    <AdminLayout>
      <DashboardContainer>
        <Typography variant="h5">All Applications</Typography>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default AllApplications;
