import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FontWeight } from "../../../core/types";

function createData(
  standrad: string,
  siteName: string,
  criteria: any,
  percent: string
) {
  return {
    standrad,
    siteName,
    criteria,
    percent,
  };
}

const IncreaseInManDays = ({ increaseinManDays, totalTimeOnSIte }: any) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (increaseinManDays) {
      let list: any = [];
      increaseinManDays.map((item: any) => {
        list.push(
          createData(
            item.standrad ? item.standrad : "-",
            item.siteName ? item.siteName : "-",
            item.criteria ? item.criteria : "-",
            item.percent ? item.percent : "-"
          )
        );
      });
      setData(list);
    }
  }, [increaseinManDays]);

  return (
    <Box>
      <TableContainer>
        <Table
          sx={{
            minWidth: 650,
            width: "100%",
            mb: 4,
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Standrad</TableCell>
              <TableCell align="left">Site Name</TableCell>
              <TableCell align="center">
                Criteria for Increase in audit time of management systems [Food
                Safety Scheme(s)] Increasing Factor
              </TableCell>
              <TableCell align="center">%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row: any, index: any) => (
                <TableRow key={row.name}>
                  <TableCell align="left">{row.standrad}</TableCell>
                  <TableCell align="center">{row.siteName}</TableCell>
                  <TableCell align="center">{row.criteria}</TableCell>
                  <TableCell align="center">{row.percent}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography fontWeight={FontWeight.BOLD}>
        Total Time onsite: {totalTimeOnSIte}
      </Typography>
    </Box>
  );
};

export default IncreaseInManDays;
