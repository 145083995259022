import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Typography, Box, Button, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../../core/components/Input/TextInput";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import { FontWeight, TextFieldType } from "../../../../core/types";
import {
  FORGOTPASSWORD,
  CERTIFICATION,
  COMPANYDETAILS,
  QUOTATION,
  REVIEW,
  TERMSCONDITIONS,
  DASHBOARD,
  SIGNUP,
} from "../../../../router/config";
import { Formik } from "formik";
import { signInValidationSchema } from "../../../../core/validations/formSchema";
import useAsyncRequest from "../../../../networking/useAsyncRequest";
import {
  getApplicationbyEmail,
  googleLogin,
  userLogin,
} from "../../../../store/Auth/authService";
import Loader from "../../../../core/components/Loader";
import { signIn } from "../../../../store/Auth/authSlice";
import { applicationDetails } from "../../../../store/Oboarding/onBoardingSlice";
import { USERTYPE } from "../../../../core/constants";
import GoogleLogin from "react-google-login";
import { FcGoogle } from "react-icons/fc";

const SignInForm = ({ type }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [data, setData] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<string>();

  const clientId =
    "830136945700-4sukjrick8hl7m10rlj7al7jhgfmntvo.apps.googleusercontent.com";

  const { execute, loading } = useAsyncRequest(userLogin);
  const { execute: glogin, loading: loginLoading } =
    useAsyncRequest(googleLogin);

  const onSuccess = (res: any) => {
    console.log("Success", res);

    const { profileObj } = res;

    const { email, givenName, familyName } = profileObj;

    const payload = {
      firstName: givenName,
      lastName: familyName,
      email: email,
      role: "USER",
    };
    glogin(payload, handleResponse);
  };

  const onFailure = (res: any) => {
    console.log("Success", res);
  };

  const {
    execute: getApplication,
    loading: loadingApplication,
    error: err,
  } = useAsyncRequest(getApplicationbyEmail);

  const onSubmit = async (data: any) => {
    await execute(data, handleResponse);
  };

  const handleResponse = async (response: any) => {
    const { status, data } = response;
    setData(response);
    if (status === 200) {
      const { userData } = data;
      const { role } = userData;

      if (type !== undefined) {
        if (role !== type) {
          setErrorMessage("User Not Found");
          return;
        }
        dispatch(signIn(data));
        switch (role) {
          case USERTYPE.USER:
            const payload = {
              userEmail: data.userData.email,
            };
            await getApplication(payload, handelApplicationResponse);
            break;
        }
      } else {
        dispatch(signIn(data));
        switch (role) {
          case USERTYPE.ADMIN:
            navigate(DASHBOARD);
            break;
          case USERTYPE.AUDITOR:
            navigate(DASHBOARD);
            break;
          case USERTYPE.DECISION:
            navigate(DASHBOARD);
            break;
          case USERTYPE.USER:
            setErrorMessage("User Not Found");
            break;
        }
      }
    } else {
      setData(data);
    }
  };

  const handelApplicationResponse = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      dispatch(applicationDetails(data));
      const { navigateTo } = data;
      switch (navigateTo) {
        case "COMPANYDETAILS":
          navigate(COMPANYDETAILS);
          break;
        case "QUOTATION":
          navigate(QUOTATION);
          break;
        case "REVIEW":
          navigate(REVIEW);
          break;
        case "TERMSANDCONDITIONS":
          navigate(TERMSCONDITIONS);
          break;
        case "DASHBOARD":
          navigate(DASHBOARD);
          break;
      }
    } else {
      navigate(CERTIFICATION);
    }
  };

  return (
    <Box>
      <Loader open={loading || loadingApplication || loginLoading} />
      <Box display={"flex"} justifyContent={"center"}>
        <LockOpenRoundedIcon
          htmlColor="rgba(0, 0, 0, 0.6)"
          sx={{
            border: "2px solid rgba(0, 0, 0, 0.6)",
            padding: "8px",
            borderRadius: "23px",
          }}
        />
      </Box>
      <Box textAlign={"center"} color={"#000000"} sx={{opacity: '0.6'}}>
        {type === USERTYPE.USER ? (
          <>
            <Typography mb={1} mt={1} variant="h5">
              User sign in
            </Typography>
            <Typography mb={1} mt={1}>
              <i>Welcome user, please sign in to continue</i>
            </Typography>
          </>
        ) : (
          <>
            <Typography mb={1} mt={1} variant="h5">
              Admin sign in
            </Typography>
            <Typography mb={1} mt={1}>
              <i>Welcome admin, please sign in to continue</i>
            </Typography>
            <Box></Box>
          </>
        )}
      </Box>

      {type === USERTYPE.USER && (
        <Box
          mt={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <GoogleLogin
            clientId={clientId}
            buttonText="Google"
            onSuccess={onSuccess}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                style={{ background: 'none', borderRadius: '25px', border: 'none', fontSize: '3rem', cursor: 'pointer' }}
              >
               <FcGoogle /> 
              </button>
            )}
            onFailure={onFailure} 
            cookiePolicy={"single_host_origin"}
            isSignedIn={true}
          />
          <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
            <Divider sx={{ flex: 1 }} />
            <Typography sx={{ mx: 2 }} color={"#72777a"}>
              or
            </Typography>
            <Divider sx={{ flex: 1 }} />
          </Box>
        </Box>
      )}

      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={signInValidationSchema}
        onSubmit={(value) => {
          onSubmit(value);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box color={"#72777a"}>
              <TextInput
                type={TextFieldType.EMAIL}
                name="email"
                label="Enter your email"
                onChange={handleChange}
                value={values.email}
                onBlur={handleBlur}
                error={
                  errors.email && touched.email && errors.email ? true : false
                }
                helperText={errors.email && touched.email && errors.email}
                textFieldHeigt={40}
              />
              <TextInput
                label="Enter your password"
                onChange={handleChange}
                type={TextFieldType.PASSWORD}
                name="password"
                value={values.password}
                onBlur={handleBlur}
                error={
                  errors.password && touched.password && errors.password
                    ? true
                    : false
                }
                helperText={
                  errors.password && touched.password && errors.password
                }
                textFieldHeigt={40}
              />
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <Typography variant="subtitle1" color={"red"}>
                {data && data.message}
              </Typography>
            </Box>
            {errorMessage && (
              <Box display={"flex"} justifyContent={"center"}>
                <Typography variant="subtitle1" color={"red"}>
                  {errorMessage}
                </Typography>
              </Box>
            )}
            <Box
              padding={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 6,
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  fontSize={13}
                  mt={0.5}
                  mb={1}
                  sx={{ color: "#2196F3", cursor: "pointer" }}
                  fontWeight={FontWeight.MEDIUM}
                  onClick={() => {
                    navigate(FORGOTPASSWORD);
                  }}
                >
                  Forgot Password ?
                </Typography>
              </Box>
              <Box>
                {type === USERTYPE.USER && (
                  <Typography
                    fontSize={13}
                    mt={0.5}
                    mb={1}
                    sx={{ color: "#2196F3", cursor: "pointer" }}
                    fontWeight={FontWeight.MEDIUM}
                    onClick={() => {
                      navigate(SIGNUP);
                    }}
                  >
                    No account?, Sign up
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Button
                sx={{
                  width: "95%",
                  borderRadius: "19px",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
                type="submit"
                variant="contained"
              >
                Sign in
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      {/* {type === USERTYPE.USER && (
        <Box
          mt={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <Typography mb={2}>OR</Typography>
          <GoogleLogin
            clientId={clientId}
            buttonText="Google"
            onSuccess={onSuccess}
            render={(renderProps) => (
              <GoogleButton
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                label="Continue with Google"
              >
                Continue with Google
              </GoogleButton>
            )}
            onFailure={onFailure}
            cookiePolicy={"single_host_origin"}
            isSignedIn={true}
          />
        </Box>
      )} */}
    </Box>
  );
};

export default SignInForm;
