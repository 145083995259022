import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userAuditFinings: {},
  auditorAuditFinings: {},
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    userAuditFindings: (state: any, action: any) => {
      state.userAuditFinings = action.payload;
    },
    auditorAuditFindings: (state: any, action: any) => {
      state.auditorAuditFinings = action.payload;
    },
  },
});

export const { userAuditFindings, auditorAuditFindings } = adminSlice.actions;

export default adminSlice.reducer;
