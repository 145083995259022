import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextInputLight from "../../../../../core/components/Input/TextInputLight";
import { Box, Typography } from "@mui/material";
import useAsyncRequest from "../../../../../networking/useAsyncRequest";
import { addComplaints } from "../../../../../store/Application/applicationService";
import Loader from "../../../../../core/components/Loader";
import { useSelector } from "react-redux";

const ComplaintsPopUp = ({ open, handleClose, title }: any) => {
  const [scroll] = useState<DialogProps["scroll"]>("paper");

  const descriptionElementRef = React.useRef<HTMLElement>(null);

  const user = useSelector((state: any) => state.auth.user);

  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  const { execute, loading } = useAsyncRequest(addComplaints);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      handleClose();
    }
  };

  useEffect(() => {
    setSubject("")
    setBody("")
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Loader open={loading} />
        <DialogTitle id="scroll-dialog-title">
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography>{title}</Typography>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box mt={2}>
              <TextInputLight
                textFieldHeigt={50}
                label={"Subject"}
                name={"subject"}
                multiline
                row={1}
                value={subject}
                onChange={(val: any) => {
                  setSubject(val.target.value);
                }}
              />
              <TextInputLight
                textFieldHeigt={490}
                label={""}
                name={"body"}
                value={body}
                multiline
                row={20}
                onChange={(val: any) => {
                  setBody(val.target.value);
                }}
              />
              <DialogActions>
                <Button
                  onClick={() => {
                    const payload = {
                      firstName : user.firstName,
                      lastName : user.lastName,
                      userEmail : user.email,
                      subject : subject,
                      body : body
                    };
                    execute(payload, handleResponse);
                  }}
                >
                  Send
                </Button>
              </DialogActions>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default ComplaintsPopUp;
