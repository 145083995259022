import { useState } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import BaseAuthTemplate from "../../../core/components/CommonTemplate/BaseAuthTemplate";
import { Box, Button, Typography } from "@mui/material";
import { TextFieldType } from "../../../core/types";
import { Formik } from "formik";
import TextInputLight from "../../../core/components/Input/TextInputLight";
import { passwordResetSchema } from "../../../core/validations/formSchema";
import { useLocation, useNavigate } from "react-router-dom";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { resetPasswordByUser } from "../../../store/Auth/authService";
import Loader from "../../../core/components/Loader";

const ResetPasswordScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location?.state;

  const [errorMessage, setErrorMessage] = useState("");

  const [success, setSuccess] = useState(false);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      setSuccess(true);
    } else if (status === 240) {
      setErrorMessage(res.message);
    }
  };
  const { execute, loading } = useAsyncRequest(resetPasswordByUser);

  return (
    <BaseAuthTemplate>
      <Box
        mt={20}
        mb={40}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
        width={"100%"}
      >
        <Loader open={loading} />
        {success ? (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            width={"100%"}
          >
            <CheckCircleRoundedIcon
              sx={{ height: 150, width: 150, color: "green" }}
            />
            <Typography
              mt={2}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Password Reset Successfull
            </Typography>
            <Typography
              mt={2}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Please login with new password
            </Typography>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              variant="outlined"
              sx={{ mt: 4 }}
            >
              Back to login
            </Button>
          </Box>
        ) : (
          <Box width={"30%"}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Reset Password
            </Typography>
            <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              validationSchema={passwordResetSchema}
              onSubmit={(value: any) => {
                const payload = {
                  email: email,
                  password: value.password,
                };
                execute(payload, handleResponse);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextInputLight
                    type={TextFieldType.PASSWORD}
                    name={"password"}
                    label={"Password"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    textFieldHeigt={60}
                    error={
                      errors["password"] &&
                      touched["password"] &&
                      errors["password"]
                        ? true
                        : false
                    }
                    helperText={
                      errors["password"] &&
                      touched["password"] &&
                      errors["password"]
                    }
                  />
                  <TextInputLight
                    type={TextFieldType.PASSWORD}
                    name={"confirmPassword"}
                    label={"Confirm Password"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    textFieldHeigt={60}
                    error={
                      errors["confirmPassword"] &&
                      touched["confirmPassword"] &&
                      errors["confirmPassword"]
                        ? true
                        : false
                    }
                    helperText={
                      errors["confirmPassword"] &&
                      touched["confirmPassword"] &&
                      errors["confirmPassword"]
                    }
                  />
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"flex-end"}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 2, mr: 2 }}
                    >
                      Reset Password
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
            {errorMessage && (
              <Typography color={"red"} mt={2}>
                {errorMessage}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </BaseAuthTemplate>
  );
};

export default ResetPasswordScreen;
