import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import { FontWeight } from "../../../core/types";
import { useLocation, useNavigate } from "react-router-dom";
import { DASHBOARD } from "../../../router/config";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { getAuditAssessmentFindings } from "../../../store/AuditorFlow/auditorListService";
import { useEffect, useState } from "react";
import AuditFindingsTable from "../../../core/components/AuditFindingsTable";
import { useSelector } from "react-redux";
import Loader from "../../../core/components/Loader";

const AuditFindings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state;
  const findingsResponse = useSelector(
    (state: any) => state.admin.auditorAuditFinings
  );

  const [findings, setFindings] = useState<any>([]);

  const { applicationId } = data;

  const { execute, loading } = useAsyncRequest(getAuditAssessmentFindings);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      const { assesmentData } = data;
      const { detailOfFindings } = assesmentData;
      setFindings(detailOfFindings);
    }
  };

  useEffect(() => {
    const payload = {
      applicationId: applicationId,
    };
    execute(payload, handleResponse);
  }, [applicationId, findingsResponse]);

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={loading}/>
        <Typography variant="h6" fontWeight={FontWeight.BOLD} pl={2} pt={3}>
          Audit findings
        </Typography>
        {findings?.length === 0 ? (
          <Box
            p={10}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="h5" fontWeight={FontWeight.BOLD}>
              No Findings Recorded
            </Typography>
          </Box>
        ) : (
          <AuditFindingsTable
            findings={findings}
            applicationId={applicationId}
            onClick={(item: any, rowIndex: any) => console.log(item, rowIndex)}
          />
        )}
        <Box
          pt={7}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              navigate(DASHBOARD, { replace: true });
            }}
          >
            Back to Dashboard
          </Button>
        </Box>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default AuditFindings;
