import React from 'react';

const StepperCompleteIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.7188 9C17.7188 13.8152 13.8152 17.7188 9 17.7188C4.18475 17.7188 0.28125 13.8152 0.28125 9C0.28125 4.18475 4.18475 0.28125 9 0.28125C13.8152 0.28125 17.7188 4.18475 17.7188 9ZM7.99151 13.6165L14.4603 7.14776C14.6799 6.9281 14.6799 6.57193 14.4603 6.35228L13.6648 5.5568C13.4451 5.33711 13.089 5.33711 12.8693 5.5568L7.59375 10.8323L5.13074 8.36926C4.91108 8.14961 4.55491 8.14961 4.33522 8.36926L3.53974 9.16474C3.32009 9.3844 3.32009 9.74057 3.53974 9.96022L7.19599 13.6165C7.41568 13.8362 7.77182 13.8362 7.99151 13.6165Z" fill="#57CB6A"/>
  </svg>
  

);

export default StepperCompleteIcon;