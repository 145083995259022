import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Box, Typography } from "@mui/material";
import { FontWeight } from "../../../../core/types";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";

const AuditTimeline = ({ data }: any) => {
  const { auditPlan } = data;

  const timeLineContent = ({ item, isLast }: any) => {
    if (!item || item.length === 0) {
      return null;
    }
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="success">
            <AccessTimeFilledRoundedIcon />
          </TimelineDot>
          {!isLast && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Box>
            {item?.map((item: any) => {
              return (
                <Box pb={4}>
                  <Box>
                    <Typography fontSize={18} fontWeight={FontWeight.MEDIUM}>
                      {item.label}
                    </Typography>
                    <Typography fontWeight={FontWeight.MEDIUM}>
                      Date & Time: {item.date}
                    </Typography>
                  </Box>
                  <Box ml={2}>
                    {item?.fileds &&
                      item?.fileds?.map((fieldsItem: any, index: number) => {
                        return (
                          <Typography fontWeight={FontWeight.MEDIUM}>
                            {index + 1}. {fieldsItem.item}
                          </Typography>
                        );
                      })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </TimelineContent>
      </TimelineItem>
    );
  };

  return (
    <Timeline position="alternate">
      {auditPlan?.map((days: any) => {
        return (
          <Box>
            {timeLineContent({ item: days.plan.beforeBreak })}
            {timeLineContent({ item: days.plan.breakTime })}
            {timeLineContent({ item: days.plan.afterBreak })}
            {timeLineContent({ item: days.plan.closingMeeting, isLast: true })}
          </Box>
        );
      })}
    </Timeline>
  );
};

export default AuditTimeline;
