import { TextFieldType } from "../../../core/types";

export const usageOfMarkLogo = [
  {
    type: TextFieldType.TEXT,
    name: "usingMarkLogoOnWebsite",
    label: "Using Mark / Logo on website",
  },
  {
    type: TextFieldType.TEXT,
    name: "misusingmatkLogo",
    label: "Misusing Mark / Logo on website",
  },
  {
    type: TextFieldType.TEXT,
    name: "notUsingMatkLogo",
    label: "Not using Mark / Logo on website",
  },
  {
    type: TextFieldType.TEXT,
    name: "notHavingWebsite",
    label: "Not having website",
  },
];

export const reviewInitialValues = {
  misusingmatkLogo: false,
  notHavingWebsite: false,
  notUsingMatkLogo: false,
  usingMarkLogoOnWebsite: false,
  manPowerCalculation: [
    { auditManDays: 1.5 },
    { auditManDays: 0 },
    { auditManDays: 0.25 },
    { auditManDays: 0 },
    { details: "Not applicable", auditManDays: 0 },
  ],
};

export function increaseInMandays(
  standard: string,
  siteName: any,
  criteria: any,
  percent: any
) {
  return {
    standard,
    siteName,
    criteria,
    percent,
  };
}

export const increaseinManDays = [
  increaseInMandays("standrad", "siteName", "In-house laboratory testing", 10),
  increaseInMandays(
    "standrad",
    "siteName",
    "Building area, infrastructure",
    10
  ),
  increaseInMandays("standrad", "siteName", "Product development, R & D", 10),
  increaseInMandays(
    "standrad",
    "siteName",
    "Total Number of operational prerequisites programmes, critical control points more than 10",
    10
  ),
  increaseInMandays("standrad", "siteName", "Need for a translator", 10),
  increaseInMandays(
    "standrad",
    "siteName",
    "Number of product types and Number of product lines",
    10
  ),
  increaseInMandays(
    "standrad",
    "siteName",
    "If the intended user is vulnerable to specific food safety hazard",
    10
  ),
  increaseInMandays(
    "standrad",
    "siteName",
    "Additional travel time from one department to other for larger organization within the same premise",
    10
  ),
];

export function impartialityAssessement(
  possibleRisk: string,
  value: any,
  assessment: any
) {
  return {
    possibleRisk,
    value,
    assessment,
  };
}

export const impartialityAssessementList = [
  impartialityAssessement("Consultant to the firm", "value", "assesement"),
  impartialityAssessement(
    "Name and detailsof the consultant to be confirmed",
    "value",
    "assesement"
  ),
  impartialityAssessement(
    "Organization’s Top Management part of IRS / ISSPL board",
    "value",
    "assesement"
  ),
  impartialityAssessement(
    "Influence through any training provided",
    "value",
    "assesement"
  ),
  impartialityAssessement(
    "Review on Conflict of Interest",
    "value",
    "assesement"
  ),
];

export const existingCertificationStatis = [
  {
    type: TextFieldType.TEXT,
    name: "managementSystem",
    label: "Management System",
  },
  {
    type: TextFieldType.TEXT,
    name: "certifcateNo",
    label: "Certificate No.",
  },
  {
    type: TextFieldType.TEXT,
    name: "expiryDate",
    label: "Certificate expiry date",
  },
  {
    type: TextFieldType.TEXT,
    name: "certificateBody",
    label: "Certification Body",
  },
];

export const mutiSiteInfo = [
  {
    type: TextFieldType.TEXT,
    name: "certificaitonAudit",
    label: "Certification Audit",
  },
  {
    type: TextFieldType.TEXT,
    name: "surveillanceAuditOne",
    label: "Surveillance Audit 1",
  },
  {
    type: TextFieldType.TEXT,
    name: "surveillanceAuditTwo",
    label: "Surveillance Audit 2",
  },
];

export const levelOfIntegration = [
  {
    name: "integratedDocumention",
    label:
      "Integrated Documentation (Manual, Policy and Objectives, Procedures, Work Instruction etc.)",
  },
  {
    name: "integratedApprochToRolesAndResponsibilites",
    label: "An Integrated approach to Roles & Responsibilities",
  },
  {
    name: "conductOfIntegrated",
    label: "Conduct of Integrated / approach to Internal Audit",
  },
  {
    name: "conductOfIntegratedManahgementReviews",
    label:
      "Conduct of Integrated Management Reviews considering the overall business strategy and plan",
  },
  {
    name: "integratedApproachToSysyemProcesses",
    label: "An Integrated approach to systems processes",
  },
  {
    name: "integratedApproachToContinualImproement",
    label: "An Integrated approach to continual Improvement mechanisms",
  },
  {
    name: "organizationPersonnelRespondQuestion",
    label:
      "Organization’s personnel to respond to questions more than one management system standards",
  },
];

export function createData(
  siteName: string,
  activeties: any,
  noOfShifts: any,
  shiftName: any,
  fullTimeEmp: any,
  partTimeEmp: any,
  contractEmp: any
) {
  return {
    siteName,
    activeties,
    noOfShifts,
    shiftName,
    fullTimeEmp,
    partTimeEmp,
    contractEmp,
  };
}

export const seasonalActivities = [
  createData("", "Core", 6.0, 24, 4.0, 1, 1),
  createData("", "Support", 6.0, 24, 4.0, 1, 1),
  createData("", "Repetitive", 6.0, 24, 4.0, 1, 1),
  createData("", "Others", 6.0, 24, 4.0, 1, 1),
  createData("", "Seasonal", 6.0, 24, 4.0, 1, 1),
];

export function effectiveManPower(
  scheme: string,
  location: any,
  totalManPower: any,
  effectiveManPower: any,
  manday: any,
  increase: any,
  decrease: any,
  stageOneOn: any,
  stageOneOff: any,
  stageTwoOn: any,
  stageTwoOff: any,
  saOneOn: any,
  saOneOff: any,
  saTwoOn: any,
  saTwoOff: any
) {
  return {
    scheme,
    location,
    totalManPower,
    effectiveManPower,
    manday,
    increase,
    decrease,
    stageOneOn,
    stageOneOff,
    stageTwoOn,
    stageTwoOff,
    saOneOn,
    saOneOff,
    saTwoOn,
    saTwoOff,
  };
}

export const manPowerCalculation = [
  effectiveManPower(
    "ISO",
    "FSSC",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  ),
];

export function auditDurationData(
  auditTimeFactors: string,
  details: any,
  auditManDays: any
) {
  return {
    auditTimeFactors,
    details,
    auditManDays,
  };
}

export const auditDuration = [
  auditDurationData("Basic on-site audit time", "", 1.5),
  auditDurationData("Number of HACCP Studies/Plan", "", ""),
  auditDurationData(
    "Number of audit days for absence of relevant management system",
    "",
    ""
  ),
  auditDurationData("Number of Employees", "", ""),
  auditDurationData("Additional Site", "Not applicable", 0),
  auditDurationData("Audit Preparation", "", 0.25),
  auditDurationData("Audit Reporting", "", 0.5),
  auditDurationData("Use of Translator", "", ""),
  auditDurationData("Halal Audit", "", ""),
];

export function auditcalculation(auditType: string, formula: any, total: any) {
  return {
    auditType,
    formula,
    total,
  };
}

export const auditCalculation = [
  auditcalculation(
    "Initial Certification Audit Time",
    "Total Audit Duration",
    0.0
  ),
  auditcalculation("Surveillance Audit Time", "Total Audit Duration/3", ""),
  auditcalculation(
    "Recertification Audit Time",
    "Total Audit Duration*(2/3)",
    0.0
  ),
  auditcalculation(
    "Total Audit Man Days for One certification Cycle",
    "Audit Man Days = Total Audit Duration + (2* Surveillance Audit Time)",
    0.0
  ),
];

export const adjustmentCheckBox = [
  {
    name: "timeAdjustmentyes",
    label: "Yes",
  },
  {
    name: "timeAdjustmentno",
    label: "No",
  },
];

export const particulars = [1, 2];
export const documentsReceviedParticulars = [1, 2];

export const auditPlaningInitialValues = {
  auditDate: "",
  leadAuditor: "",
  auditor: "",
  auditType: "",
};
