import {
  Box,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FontWeight } from "../../types";
import FindingsResolution from "../../../features/dashboard/userDashboard/component/FindingsResolve";
import { useSelector } from "react-redux";
import { USERTYPE } from "../../constants";
import AttachmentsView from "../AttachmentsView";

const AuditFindingsTable = ({ findings, onClick, applicationId }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const user = useSelector((state: any) => state.auth.user);
  const { role } = user;

  const headLabel = (label: string) => {
    return (
      <Typography fontWeight={FontWeight.BOLD} fontSize={16} color={"#929396"}>
        {label}
      </Typography>
    );
  };

  const rowContainer = (title: any, subTitle: any) => {
    return (
      <Box mt={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography fontWeight={FontWeight.BOLD}>{title}</Typography>
        <Typography fontWeight={FontWeight.BOLD}>{subTitle}</Typography>
      </Box>
    );
  };

  return (
    <Box>
      {isMobile ? (
        <Box>
          {findings &&
            findings?.map(
              (row: any, rowIndex: number) =>
                row && (
                  <Box mt={4}>
                    {rowContainer("Sl No", rowIndex + 1)}
                    {rowContainer("Grading", row?.grading ? row?.grading : "-")}
                    {rowContainer(
                      "Reference Clause",
                      row?.referenceClause ? row?.referenceClause : "-"
                    )}
                    {rowContainer(
                      "Assessment Team",
                      row?.assessementTeamMember
                        ? row?.assessementTeamMember
                        : "-"
                    )}
                    {rowContainer("Remarks", row?.remarks ? row?.remarks : "-")}
                    <Box mt={2}>
                    {row?.filePath ? (
                      <AttachmentsView label={row?.filePath} />
                    ) : (
                      <Typography>-</Typography>
                    )}
                    </Box>
                    <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
                      <FindingsResolution
                        butttonLabel={
                          row?.status
                            ? row?.status
                            : role === USERTYPE.USER
                            ? "Resolve"
                            : USERTYPE.AUDITOR
                            ? "Pending"
                            : "Pending"
                        }
                        item={row}
                        index={rowIndex}
                        applicationId={applicationId}
                      />
                    </Box>
                  </Box>
                )
            )}
        </Box>
      ) : (
        <TableContainer>
          <Table
            sx={{
              minWidth: 650,
              mt: 5,
              [`& .${tableCellClasses.root}`]: {
                border: 1,
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Sl No</TableCell>
                <TableCell align="center">{headLabel("Grading")}</TableCell>
                <TableCell align="center">
                  {" "}
                  {headLabel("Reference Clause")}
                </TableCell>
                <TableCell align="center">
                  {headLabel("Assessment Team")}
                </TableCell>
                <TableCell align="center">{headLabel("Remarks")}</TableCell>
                <TableCell align="center">{headLabel("Evidence")}</TableCell>
                <TableCell align="center">{headLabel("Status")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {findings &&
                findings?.map(
                  (row: any, rowIndex: number) =>
                    row && (
                      <TableRow
                        key={rowIndex}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 1 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          onClick(row, rowIndex);
                        }}
                      >
                        <TableCell>
                          <Typography fontWeight={FontWeight.BOLD}>
                            {rowIndex + 1}.
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography fontWeight={FontWeight.BOLD}>
                            {row?.grading ? row?.grading : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography fontWeight={FontWeight.BOLD}>
                            {row?.referenceClause ? row?.referenceClause : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography fontWeight={FontWeight.BOLD}>
                            {row?.assessementTeamMember
                              ? row?.assessementTeamMember
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography fontWeight={FontWeight.BOLD}>
                            {row?.remarks ? row?.remarks : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {row?.filePath ? (
                            <AttachmentsView label={row?.filePath} />
                          ) : (
                            <Typography>-</Typography>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <FindingsResolution
                              butttonLabel={
                                row?.status
                                  ? row?.status
                                  : role === USERTYPE.USER
                                  ? "Resolve"
                                  : USERTYPE.AUDITOR
                                  ? "Pending"
                                  : "Pending"
                              }
                              item={row}
                              index={rowIndex}
                              applicationId={applicationId}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default AuditFindingsTable;
