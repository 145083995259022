export const onBoardingStepperList = [
  "Certification",
  "Company Details",
  "Quotation",
  "Review",
  "Terms & Conditions",
];

export enum TYPE {
  ONBOARD = "ONBOARD",
}

export enum DATEFORMAT {
  DATETIMEFORMATE = "MM/DD/YYYY",
}

export enum USERTYPE {
  ADMIN = "ADMIN",
  USER = "USER",
  AUDITOR = "AUDITOR",
  DECISION = "DECISION",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export enum APPLICATIONTYPE {
  FRESH = "FRESH",
  RENEWAL = "RENEWAL",
  COMPLETED = "COMPLETED",
  AUDITCOMPLETED = "ADUITCOMPLETED",
}
