import { Box, Typography, Grid, IconButton } from "@mui/material";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import CompanyDetail from "../../../features/applicationDetailScreen/components/companyDetail";
import { FontWeight } from "../../types";
import AttachmentsView from "../AttachmentsView";
import TextWithLabel from "../TextWithLabel";
import { handleDownloadPdf } from "../../utils";

const CompanyDetailComponent = ({ data }: any) => {
  const { companyDetail } = data;

  const requiredCertifications = (list: any) => {
    return list?.join(", ");
  };

  return (
    <Box>
      <Box display={"flex"} justifyContent={"flex-end"} alignItems={"baseline"}>
        <IconButton onClick={() => handleDownloadPdf("page-content")}>
          <Typography mr={1}>Download</Typography>
          <FileDownloadRoundedIcon />
        </IconButton>
      </Box>
      {data && (
        <Box id="page-content">
          <Box>
          <TextWithLabel
            title={"Application ID"}
            subTitle={data?.applicationNo}
            
          />
            <TextWithLabel
              title={"Certification Type"}
              subTitle={data?.certification?.toUpperCase()}
            
            />
            <TextWithLabel
              title={"Certification Required"}
              subTitle={
                requiredCertifications(data?.requiredCertifications) +
                (data.other ? ", " + data.other : ' ')
              }
            />
          </Box>
          <Box>
            <CompanyDetail data={companyDetail} />
            {companyDetail?.bussinessActivityDocument?.length !== 0 && (
              <Box mt={4}>
                <Typography variant="h6" fontWeight={FontWeight.BOLD}>
                  Business Activity Documents
                </Typography>
                {companyDetail?.bussinessActivityDocument?.map((item: any) => {
                  return (
                    <Grid item xs={2} sm={4} md={4}>
                      <AttachmentsView label={item} />
                    </Grid>
                  );
                })}
              </Box>
            )}
            {companyDetail?.foodScheme?.length !== 0 && (
              <Box mt={4}>
                <Typography variant="h6" fontWeight={FontWeight.BOLD}>
                  Food Scheme
                </Typography>
                {companyDetail?.foodScheme?.map((item: any, index: number) => {
                  return (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography fontWeight={FontWeight.BOLD}>
                        {index + 1}. {item?.item}
                      </Typography>
                      <AttachmentsView label={item?.filePath} />
                    </Box>
                  );
                })}
              </Box>
            )}
            {companyDetail?.slaughterHouse?.length !== 0 && (
              <Box mt={4}>
                <Typography variant="h6" fontWeight={FontWeight.BOLD}>
                  Slaughterhouse
                </Typography>
                {companyDetail?.slaughterHouse.map(
                  (item: any, index: number) => {
                    return (
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography fontWeight={FontWeight.BOLD}>
                          {index + 1}. {item?.item}
                        </Typography>
                        <AttachmentsView label={item?.filePath} />
                      </Box>
                    );
                  }
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CompanyDetailComponent;
