import React, { useState } from "react";
import { TableContainer, Box, Button, Typography } from "@mui/material";
import TwoRowTable from "./twoRowTable";
import { FontWeight } from "../../../core/types";

const AuditPlanTable = ({ setFieldValue, options }: any) => {
  const headings = ["Date/Time", "", "Day 1"];

  const initialDayOneBeforeBreak = [
    {
      dateTime: new Date(),
      name: "openMeetingDate",
      labels: "Opening Meeting",
      auditor: null,
    },
    {
      dateTime: new Date(),
      name: "beforeBreakLeadAuditor",
      labels: "Lead Auditor",
      auditor: "Lead Auditor",
      type: "text",
      fields: [1],
      textName: "textField",
    },
    {
      dateTime: new Date(),
      name: "beforeBreakAuditor",
      labels: "Auditor",
      auditor: "Auditor",
      type: "text",
      fields: [1],
      textName: "textField",
    },
  ];

  const initialDayOneAfterBreak = [
    {
      dateTime: new Date(),
      name: "afterBreakLeadAuditor",
      labels: "Lead Auditor",
      auditor: "Lead Auditor",
      type: "text",
      textName: "textField",
      fields: [1],
    },
    {
      dateTime: new Date(),
      name: "afterBreakAuditor",
      labels: "Auditor",
      auditor: "Auditor",
      type: "text",
      textName: "textField",
      fields: [1],
    },
  ];

  const initialDayOneBreak = [
    {
      dateTime: new Date(),
      name: "breakTime",
      labels: "Break",
    },
  ];

  const initialDayTwoClosing = [
    {
      dateTime: new Date(),
      name: "closingMeeting",
      labels: "Closing Meeting",
    },
  ];

  const [auditPlan, setAuditPlan] = useState([
    {
      beforeBreak: initialDayOneBeforeBreak,
      afterBreak: initialDayOneAfterBreak,
      breakTime: initialDayOneBreak,
    },
  ]);

  const addNewDay = () => {
    const dayCount = auditPlan.length + 1;
    const newDay = {
      beforeBreak: [
        {
          dateTime: new Date(),
          name: "day",
          labels: `Day ${dayCount}`,
          auditor: null,
        },
        {
          dateTime: new Date(),
          name: "beforeBreakLeadAuditor",
          labels: "Lead Auditor",
          auditor: "Lead Auditor",
          textName: "textField",
          type: "text",
          fields: [1],
        },
        {
          dateTime: new Date(),
          name: "beforeBreakAuditor",
          labels: "Auditor",
          auditor: "Auditor",
          type: "text",
          textName: "textField",
          fields: [1],
        },
      ],
      afterBreak: [
        {
          dateTime: new Date(),
          name: "afterBreakLeadAuditor",
          labels: "Lead Auditor",
          auditor: "Lead Auditor",
          textName: "textField",
          type: "text",
          fields: [1],
        },
        {
          dateTime: new Date(),
          name: "afterBreakAuditor",
          labels: "Auditor",
          auditor: "Auditor",
          textName: "textField",
          type: "text",
          fields: [1],
        },
      ],
      breakTime: [
        {
          dateTime: new Date(),
          name: "breakTime",
          labels: "Break",
        },
      ],
    };

    setAuditPlan([...auditPlan, newDay]);
  };

  const removeDay = (dayIndex: number) => {
    const updatedAuditPlan = auditPlan.filter((_, index) => index !== dayIndex);
    setAuditPlan(updatedAuditPlan);
  };

  const handleFieldValueChange = (
    dayIndex: number,
    fieldName: string,
    value: any
  ) => {
    const updatedAuditPlan: any = [...auditPlan];
    updatedAuditPlan[dayIndex][fieldName] = value;
    setAuditPlan(updatedAuditPlan);
    setFieldValue(`auditPlan[${dayIndex}].${fieldName}`, value);
  };

  return (
    <Box>
      <TableContainer>
        {auditPlan.map((day, index) => (
          <React.Fragment key={index}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              my={2}
            >
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
              >
                <Typography
                  ml={2}
                  variant="h6"
                  fontWeight={FontWeight.BOLD}
                >{`Day ${index + 1}`}</Typography>
              </Box>
              {index > 0 && (
                <Button
                  sx={{ width: 150, mr: 2 }}
                  onClick={() => removeDay(index)}
                  variant="outlined"
                  color="secondary"
                >
                  Remove Day
                </Button>
              )}
            </Box>
            <TwoRowTable
              headings={[]}
              rows={day.beforeBreak}
              setFieldValue={(fieldName: any, value: any) =>
                handleFieldValueChange(index, fieldName, value)
              }
              name={`plan.beforeBreak`}
            />
            <TwoRowTable
              headings={[]}
              rows={day.breakTime}
              setFieldValue={(fieldName: any, value: any) =>
                handleFieldValueChange(index, fieldName, value)
              }
              name={`plan.breakTime`}
            />
            <TwoRowTable
              headings={[]}
              rows={day.afterBreak}
              setFieldValue={(fieldName: any, value: any) =>
                handleFieldValueChange(index, fieldName, value)
              }
              name={`plan.afterBreak`}
            />
          </React.Fragment>
        ))}
        <TwoRowTable
          headings={[]}
          rows={initialDayTwoClosing}
          setFieldValue={(fieldName: any, value: any) =>
            handleFieldValueChange(auditPlan.length - 1, fieldName, value)
          }
          name={`plan.closingMeeting`}
        />
      </TableContainer>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        mt={2}
        mr={2}
      >
        <Button onClick={addNewDay} variant="contained" color="primary">
          Add Day
        </Button>
      </Box>
    </Box>
  );
};

export default AuditPlanTable;
