import {
  Box,
  Checkbox,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FontWeight, TextFieldType } from "../../../core/types";
import TextInputTextField from "../../../core/components/Input/TextInputTextField";
import CheckListViewComponent from "./CheckListViewComponenet";

const ThreeCheckComponentWithFeild = ({
  rows = [1, 2, 4],
  setFieldValue,
  onBlur,
  fieldName = "name",
  heading = "label",
  data,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selected, setSelected] = useState<any>({});

  const handleChange =
    (name: string, option: string, label: string, index: any) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = {
        value: event.target.checked ? option : "",
        label: label,
      };
      setSelected((prev: any) => ({
        ...prev,
        [name]: newValue,
      }));
      setFieldValue(`${fieldName}.list.${index}`, newValue);
      setFieldValue(`${fieldName}.heading`, heading);
    };

  useEffect(() => {}, [onBlur]);

  const headLabel = (label: string) => {
    return (
      <Typography fontWeight={FontWeight.MEDIUM} fontSize={16} color={"#929396"}>
        {label}
      </Typography>
    );
  };

  return (
    <FormControl component="fieldset">
      {isMobile ? (
        <Box>
          {rows.map((row: any, rowIndex: number) => (
            <Box mt={4} width={"100%"}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography fontWeight={FontWeight.MEDIUM}>
                  {rowIndex + 1}. {row.label}
                </Typography>
                <CheckListViewComponent data={row} details={data} />
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography fontWeight={FontWeight.MEDIUM}>Pass</Typography>
                  <Checkbox
                    checked={selected[row.name]?.value === "pass"}
                    onChange={handleChange(
                      row.name,
                      "pass",
                      row.label,
                      rowIndex
                    )}
                  />
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography fontWeight={FontWeight.MEDIUM}>Fail</Typography>
                  <Checkbox
                    checked={selected[row.name]?.value === "fail"}
                    onChange={handleChange(
                      row.name,
                      "fail",
                      row.label,
                      rowIndex
                    )}
                  />
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography fontWeight={FontWeight.MEDIUM}>N/A</Typography>
                  <Checkbox
                    checked={selected[row.name]?.value === "na"}
                    onChange={handleChange(row.name, "na", row.label, rowIndex)}
                  />
                </Box>
              </Box>
              <TextInputTextField
                mt={1}
                type={TextFieldType.TEXT}
                name={"objectEvidence"}
                disabled={selected[row.name] == undefined}
                label="Objective Evidence"
                labelHeight={0}
                onChange={(val: any) => {
                  setFieldValue(
                    `${fieldName}.list.${rowIndex}.objectEvidence`,
                    val.target.value
                  );
                  setFieldValue(`${fieldName}.heading`, heading);
                }}
                onBlur={onBlur}
                multiline={true}
              />
              <TextInputTextField
                mt={1}
                type={TextFieldType.TEXT}
                disabled={selected[row.name] == undefined}
                name={`remarks`}
                label="Remarks"
                onChange={(val: any) => {
                  setFieldValue(
                    `${fieldName}.list.${rowIndex}.remarks`,
                    val.target.value
                  );
                  setFieldValue(`${fieldName}.heading`, heading);
                }}
                onBlur={onBlur}
                multiline={true}
                labelHeight={0}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Table
          sx={{
            minWidth: 650,
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" width={700}>
                {headLabel("Requirements")}
              </TableCell>{" "}
              <TableCell align="center"> {headLabel("View")}</TableCell>
              <TableCell align="center"> {headLabel("YES")}</TableCell>
              <TableCell align="center">{headLabel("NO")}</TableCell>
              <TableCell align="center">{headLabel("N/A")}</TableCell>
              <TableCell align="center">
                {headLabel("Objective Evidence")}
              </TableCell>
              <TableCell align="center">{headLabel("Remarks")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any, rowIndex: number) => (
              <TableRow key={rowIndex}>
                <TableCell component="th" scope="row" width={"50%"}>
                  <Box mt={2}>
                    <Typography fontWeight={FontWeight.MEDIUM}>
                      {rowIndex + 1}. {row.label}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <CheckListViewComponent data={row} details={data} />
                </TableCell>
                <TableCell>
                  <Box mt={2}>
                    <Checkbox
                      checked={selected[row.name]?.value === "pass"}
                      onChange={handleChange(
                        row.name,
                        "pass",
                        row.label,
                        rowIndex
                      )}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box mt={2}>
                    <Checkbox
                      checked={selected[row.name]?.value === "fail"}
                      onChange={handleChange(
                        row.name,
                        "fail",
                        row.label,
                        rowIndex
                      )}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box mt={2}>
                    <Checkbox
                      checked={selected[row.name]?.value === "na"}
                      onChange={handleChange(
                        row.name,
                        "na",
                        row.label,
                        rowIndex
                      )}
                    />
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <TextInputTextField
                    type={TextFieldType.TEXT}
                    name={"objectEvidence"}
                    disabled={selected[row.name] == undefined}
                    label=""
                    labelHeight={0}
                    onChange={(val: any) => {
                      setFieldValue(
                        `${fieldName}.list.${rowIndex}.objectEvidence`,
                        val.target.value
                      );
                      setFieldValue(`${fieldName}.heading`, heading);
                    }}
                    onBlur={onBlur}
                    multiline={true}
                  />
                </TableCell>
                <TableCell>
                  <TextInputTextField
                    type={TextFieldType.TEXT}
                    disabled={selected[row.name] == undefined}
                    name={`remarks`}
                    label=""
                    onChange={(val: any) => {
                      setFieldValue(
                        `${fieldName}.list.${rowIndex}.remarks`,
                        val.target.value
                      );
                      setFieldValue(`${fieldName}.heading`, heading);
                    }}
                    onBlur={onBlur}
                    multiline={true}
                    labelHeight={0}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </FormControl>
  );
};

export default ThreeCheckComponentWithFeild;
