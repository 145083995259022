import { useLocation } from "react-router-dom";
import AdminLayout from "../../components/AdminLayout";
import DashboardContainer from "../../../../core/components/DashboardContainer";
import ApplicationReviewView from "../../../decisionFlow/ApplicationReviewView";

const ReviewSummary = () => {
  const location = useLocation();
  const { data } = location.state;

  const { application } = data;

  const { companyId } = application;

  return (
    <AdminLayout>
      <DashboardContainer>
        <ApplicationReviewView data={companyId} />
      </DashboardContainer>
    </AdminLayout>
  );
};
export default ReviewSummary;
