import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MobileBottomNavbar from "../../MobileBottomNavbar";

const Footer = () => {
  return (

    <Box sx={{ textAlign: "center", color: '#7c8695' }}>
      <hr />
      <Typography variant="body2" >
        &copy; {new Date().getFullYear()} Halal Point. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
