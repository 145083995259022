import React, { useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";

const FileUpload = ({ index, onFileUpload, item }: any) => {
  const [file, setFile] = useState<any>(null);
  const [previewUrl, setPreviewUrl] = useState<any>(null);

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPreviewUrl(URL.createObjectURL(selectedFile));
      onFileUpload({ index, item, file: selectedFile });
    }
  };

  const removeFile = () => {
    setFile(null);
    setPreviewUrl(null);
    onFileUpload({ index, file: null });
  };

  return (
    <Box
      sx={{
        border: "1px solid #ddd",
        borderRadius: 2,
        p: 2,
        maxWidth: 300,
        textAlign: "center",
        mx: "auto",
      }}
    >
      {!file ? (
        <Button
          variant="contained"
          component="label"
          startIcon={<AttachFileIcon />}
          sx={{ mb: 2 }}
        >
          Attach File
          <input type="file" hidden onChange={handleFileChange} />
        </Button>
      ) : (
        <Box sx={{ position: "relative" }}>
          <Box
            component="img"
            src={previewUrl}
            alt="Preview"
            sx={{
              width: 100,
              height: 100,
              objectFit: "cover",
              borderRadius: 1,
              mb: 1,
            }}
          />
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {file.name}
          </Typography>
          <IconButton
            size="small"
            onClick={removeFile}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "#ff4d4f",
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#ffe6e6",
              },
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default FileUpload;
