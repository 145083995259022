import { Box, Typography } from "@mui/material";
import DashboardContainer from "../../core/components/DashboardContainer";
import AdminLayout from "../dashboard/components/AdminLayout";
import { useState } from "react";
import AddAuditor from "../auditor/addAuditor";
import AuditorListTable from "../auditor/components/Table";
import { USERTYPE } from "../../core/constants";

const ClientList = () => {
  const [trigger, setTrigger] = useState(false);

  return (
    <AdminLayout>
      <DashboardContainer>
        <Box display={"flex"} justifyContent={"space-between"} pr={2}>
          <Typography variant="h6" pl={2}>
            Client List
          </Typography>
          <AddAuditor
            trigger={() => {
              setTrigger(!trigger);
            }}
          />
        </Box>
        <AuditorListTable trigger={trigger} role={USERTYPE.USER} />
      </DashboardContainer>
    </AdminLayout>
  );
};

export default ClientList;
