export const auditMethodology = [
  "The certification audit was conducted on in accordance with standard operating procedures. Audit was conducted by picking visual, documentary and interview samples to assess compliance of the audit Standard’s applicable clauses.",
  "The reporting format also follows the audit standard clause by clause, and findings have been reported accordingly.  Activities that are not in compliance with your own HAS or the audit standard have been highlighted and reported in, NON-CONFORMANCE REPORTING FORM (NCR’S) or OPPORTUNITY FOR IMPROVEMENT  REPORTING FORM (OFI’s). Non conformities and OFI/ AFI have been classified as following -",
  "MAJOR NON-CONFORMANCE is a non-compliance of a serious nature, that may have a significant and direct adverse impact on the quality of the product/ services provided by your organization. Multiple minor non-compliance may also be flagged as major Non conformity. Major non conformities must be responded to, corrected and formally closed-out, preferably within 30 days. These are re-verified by the auditor/s, mostly by revisiting the audit site. Only after satisfactory closure of major non conformities the certification and registration can proceed.",
  "MINOR NON CONFORMANCE is a non compliance of less serious nature that does not cause significant adverse impact over the goods or services provided by your organization. These Minor non conformities are closed-out by our auditors by reviewing your corrective action plan or evidences of your corrective action, which you must submit within the agreed time, preferably within 60 days.",
  "OBSERVATION is an isolated non compliance, that does not show collapse of any process. It is not mandatory for you to submit corrective action plan or corrective action evidence. However you should take these observations as potential non conformities, which should be closed in order to stop its conversion into actual non conformities.",
  "AREA/ OPPORTUNITY FOR IMPROVEMENT is not a non compliance. These are areas where scope of further improvement is available. It is not mandatory to respond to OFI’s.",
  "In order to ensure continued compliance to the audit standard, all above aspects are verified / re verified during subsequent surveillance audit.",
  "Please respond by completing the NON-CONFORMANCE Reports (NCR’s) and, if necessary, OPPORTUNITY FOR IMPROVEMENT forms (OFI’S) attached herewith, within the time period agreed at the audit closing meeting.",
  "Please put your signature in the space designated for “Company Representative”  in the attached NC forms, and also fill in details of your intended corrective action and the date by which you expect to close the non conformity.  If you have any problem regarding the agreed time frame, then please contact us to re-evaluate proposed action and time-scale.",
  "If you have any other query, please contact  +91 9999 999 999",
];

export const recommendations = [
  "1. Strength",
  "2. Weakness",
  "3. Opportunity",
  "4. Maturity level",
];
