import { Box, Button, Typography } from "@mui/material";
import AdminLayout from "../dashboard/components/AdminLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { FontWeight } from "../../core/types";
import DashboardContainer from "../../core/components/DashboardContainer";
import { Formik } from "formik";
import { quotationShcema } from "../../core/validations/formSchema";
import { createQuoation, quoationInitialValue } from "./constants";
import CommonGrid from "../onBoarding/Components/common/CommonGrid";
import { useEffect, useState } from "react";
import useAsyncRequest from "../../networking/useAsyncRequest";
import { createQuotationApi } from "../../store/Quotation/QuotaionService";
import Loader from "../../core/components/Loader";
import { getQuoation } from "../../store/Oboarding/quotationServices";

const CreateQuotation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: details } = location.state;
  const { applicationId, companyDetail } = details;
  const [gst, setGst] = useState<Number>(0);
  const [total, setTotal] = useState<Number>(0);
  const [quoationDetails, setQuotationDetails] = useState<any>();

  const { execute, loading } = useAsyncRequest(createQuotationApi);

  const { execute: getQuotation, loading: quoatationLoading } =
    useAsyncRequest(getQuoation);

  const handleGetQuoationResponse = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      setQuotationDetails(data);
      const {
        accreditationFee = null,
        applicationFee = null,
        stageOneAuditFee = null,
        stageTwoAuditFee = null,
        documentReviewForRenuewalAudit = null,
        renewalAuditFree = null,
        surverillianceAuditOneFree = null,
        surverillianceAuditTwoFree = null,
        specialAuditFee = null,
        otherFees = null,
      } = data || {};

      quoationInitialValue.accreditationFee = accreditationFee;
      quoationInitialValue.applicationFee = applicationFee;
      quoationInitialValue.stageOneAuditFee = stageOneAuditFee;
      quoationInitialValue.stageTwoAuditFee = stageTwoAuditFee;
      quoationInitialValue.documentReviewForRenuewalAudit =
        documentReviewForRenuewalAudit;
      quoationInitialValue.renewalAuditFree = renewalAuditFree;
      quoationInitialValue.surverillianceAuditOneFree =
        surverillianceAuditOneFree;
      quoationInitialValue.surverillianceAuditTwoFree =
        surverillianceAuditTwoFree;
      quoationInitialValue.specialAuditFee = specialAuditFee;
      quoationInitialValue.otherFees = otherFees;
    }
  };

  useEffect(() => {
    const payload = {
      applicationId: applicationId,
    };

    getQuotation(payload, handleGetQuoationResponse);
  }, []);

  const amountBreakUp = (title: string, value: any) => {
    return (
      <Box
        width={200}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        p={1}
      >
        <Typography variant="h6" fontWeight={FontWeight.BOLD}>
          {title}
        </Typography>
        <Typography variant="h6" fontWeight={FontWeight.BOLD}>
          {value}
        </Typography>
      </Box>
    );
  };

  const calculateTotalAndTax = (values: any) => {
    const total = Object.values(values).reduce(
      (acc: number, value) => acc + Number(value || 0),
      0
    );
    const tax = parseFloat((total * 0.18).toFixed(2));
    setGst(tax);
    const grantTotal = parseFloat((total + tax).toFixed(2));
    setTotal(grantTotal);
    return { grantTotal, tax };
  };

  const convertValuesToNumbers = (
    values: any
  ): { [K in keyof any]: number } => {
    const numberValues: { [K in keyof any]: number } = {} as {
      [K in keyof any]: number;
    };
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        numberValues[key] = Number(values[key]) || 0;
      }
    }
    return numberValues;
  };

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      const payload = {
        applicationId: applicationId,
      };

      getQuotation(payload, handleGetQuoationResponse);
    }
  };

  return (
    <AdminLayout>
      <Typography variant="h4" fontWeight={FontWeight.BOLD}>
        {companyDetail.nameOfCompany}
      </Typography>
      <Loader loading={loading || quoatationLoading} />
      <DashboardContainer>
        <Formik
          initialValues={quoationInitialValue}
          validationSchema={quotationShcema}
          onSubmit={(value) => {
            const numberValues = convertValuesToNumbers(value);
            const payload = {
              applicationId: applicationId,
              companyId: companyDetail.id,
              gst: gst,
              total: total,
              ...numberValues,
            };
            execute(payload, handleResponse);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <CommonGrid
                handleChange={handleChange}
                value={values}
                onBlur={handleBlur}
                paddingTop={{ xs: 2, md: 2 }}
                error={errors}
                list={createQuoation}
                touched={touched}
              />
              <Box
                pt={4}
                pr={10}
                display={"flex"}
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
                flexDirection={"column"}
              >
                {amountBreakUp("GST 18%", calculateTotalAndTax(values).tax)}
                {amountBreakUp(
                  "Total",
                  calculateTotalAndTax(values).grantTotal
                )}
              </Box>
              <Box
                pt={10}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
              >
                {quoationDetails?.changeRequestStatus === "CHANGEREQUESTED" ? (
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <Typography fontWeight={FontWeight.BOLD}>
                      Change Request by {companyDetail.nameOfCompany} :{" "}
                      {quoationDetails?.changeRemark}
                    </Typography>
                    <Button
                      sx={{ mt: 2 }}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Update Quotation
                    </Button>
                  </Box>
                ) : null}
                {quoationDetails ? (
                  <Box p={2} borderRadius={3} sx={{boxShadow : 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'}}>
                  <Typography fontWeight={FontWeight.BOLD} textAlign={'center'}>
                    {companyDetail.nameOfCompany} has to accept the quotation to
                    continue
                  </Typography>
                  </Box>
                ) : (
                  <Button variant="contained" color="primary" type="submit">
                    Create Quotation
                  </Button>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default CreateQuotation;
