import * as React from "react";
import Button from "@mui/material/Button";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  Alert,
  AppBar,
  Box,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { userRegistration } from "../../../store/Auth/authService";
import { Formik } from "formik";
import TextInput from "../../../core/components/Input/TextInput";
import { addAuditorSchema } from "../../../core/validations/formSchema";
import { designationList, roleList } from "../constants";
import Loader from "../../../core/components/Loader";
import { useState } from "react";
import { USERTYPE } from "../../../core/constants";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddAuditor = ({ trigger }: any) => {
  const [open, setOpen] = useState<any>(false);
  const [openAlert, setOpenAlert] = useState<any>(false);
  const [response, setResponse] = useState<any>({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { execute, loading } = useAsyncRequest(userRegistration);

  const onSubmit = async (data: any) => {
    await execute({ ...data }, handleResponse);
  };

  const handleResponse = (res: any) => {
    const { status } = res;
    if (status == 200) {
      setOpenAlert(true);
      setOpen(false);
      trigger();
    } else {
      setOpenAlert(true);
      setResponse(res);
    }
  };

  const onClose = () => {
    setOpenAlert(false);
  };

  const alert = () => {
    return (
      <Modal
        open={openAlert}
        onClose={onClose}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        closeAfterTransition
      >
        <Box sx={style}>
          <Alert severity={response.status == 409 ? "error" : "success"}>
            {response.status == 409
              ? "Auditor Already Added"
              : "Auditor added Successfully"}
          </Alert>
        </Box>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen}>
        <ControlPointRoundedIcon
          sx={{ height: 35, width: 35, color: "green" }}
        />
      </IconButton>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Administrator
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          p={5}
        >
          {alert()}
          <Loader open={loading} />
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              designation: "",
              role: "",
            }}
            validationSchema={addAuditorSchema}
            onSubmit={(value) => {
              onSubmit(value);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box width={420}>
                  <TextInput
                    label="First name"
                    onChange={handleChange}
                    name="firstName"
                    value={values.firstName}
                    onBlur={handleBlur}
                    error={
                      errors.firstName && touched.firstName && errors.firstName
                        ? true
                        : false
                    }
                    helperText={
                      errors.firstName && touched.firstName && errors.firstName
                    }
                    textFieldHeigt={40}
                  />
                  <TextInput
                    label="Last name"
                    onChange={handleChange}
                    name="lastName"
                    value={values.lastName}
                    onBlur={handleBlur}
                    error={
                      errors.lastName && touched.lastName && errors.lastName
                        ? true
                        : false
                    }
                    helperText={
                      errors.lastName && touched.lastName && errors.lastName
                    }
                    textFieldHeigt={40}
                  />
                  <TextInput
                    label="Enter email"
                    onChange={handleChange}
                    name="email"
                    value={values.email}
                    onBlur={handleBlur}
                    error={
                      errors.email && touched.email && errors.email
                        ? true
                        : false
                    }
                    helperText={errors.email && touched.email && errors.email}
                    textFieldHeigt={40}
                  />
                  <Box mt={5} pb={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Select Role
                      </InputLabel>
                      <Select
                        label="Select role"
                        labelId="dropdown-label"
                        id="dropdown"
                        name="role"
                        value={values.role}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors.role && touched.role && errors.role
                            ? true
                            : false
                        }
                      >
                        {roleList.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  {values.role === USERTYPE.AUDITOR && (
                    <Box mt={5} pb={1}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">
                          Select Designation
                        </InputLabel>
                        <Select
                          label="Select Designation"
                          labelId="dropdown-label"
                          id="dropdown"
                          name="designation"
                          value={values.designation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors.designation &&
                            touched.designation &&
                            errors.designation
                              ? true
                              : false
                          }
                        >
                          {designationList.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </Box>
                <Box
                  pt={3}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={
                      values.role == USERTYPE.AUDITOR
                        ? !values.designation
                        : false
                    }
                  >
                    Create User
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default AddAuditor;
