import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import OnBoardingStepper from "../../../../core/components/Stepper/OnBoardingStepper";
import OnBoardingHeader from "../../Components/onBoardingHeader";
import { onBoardingStepperList } from "../../../../core/constants";
import {
  attachemntList,
  attachemntListSlaughtherHouse,
  companyDetailsInitialValue,
  companyDetailsOne,
  companyDetailsOneMobileView,
  companyDetailsTwo,
  companyManagementDetails,
  detailOfSite,
  mainUnitCheckBox,
  mainUnitDetails,
  mainUnitDetailsPart2,
  noOfOPRP,
  position,
  previousCertification,
} from "../../Constants/index";
import { Formik } from "formik";
import { compayDetailsSchema } from "../../../../core/validations/formSchema";
import {
  FontWeight,
  INPUTFIELDTYPE,
  TextFieldType,
} from "../../../../core/types";
import CommonGrid from "../../Components/common/CommonGrid";
import SiteDetailComponent from "../../Components/siteDetailComponent";
import { useNavigate } from "react-router-dom";
import { QUOTATION } from "../../../../router/config";
import FileComponent from "../../../../core/components/FileComponent";
import useAsyncRequest from "../../../../networking/useAsyncRequest";
import { addCompanyDetails } from "../../../../store/Oboarding/companyDetailService";
import { useSelector } from "react-redux";
import { Fragment, useState } from "react";
import Loader from "../../../../core/components/Loader";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SummaryComponent from "../../Components/SummaryComponent";
import TermsConditionsCheck from "../../../../core/components/TermsConditionCheck";
import CommonGridOnBoarding from "../../Components/common/CommonGridOnboarding";
import TextInputLight from "../../../../core/components/Input/TextInputLight";
import SiteDetails from "../../Components/common/SiteDetails";
import CheckLabelAttachment from "../../Components/common/checkLabelAttachment";
import DashboardContainer from "../../../../core/components/DashboardContainer";

const CompanyDetails = () => {
  const navigate = useNavigate();
  const applicationDetails = useSelector(
    (state: any) => state.onBoarding.details
  );
  const [bussinessActivityFile, setBussinessActivityFile] = useState<any[]>([]);
  const [foodSchemeFile, setFoodShemeFile] = useState<any[]>([]);
  const [slaughterhouseFile, setSlaughterhouseFile] = useState<any[]>([]);
  const [stepperOneError, setStepperOneError] = useState<any>("");
  const [check, setCheck] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { application } = applicationDetails || {};

  const { applicationId, companyId } = application || {};

  const { execute, loading } = useAsyncRequest(addCompanyDetails);

  const handleApplicationResponse = (res: any) => {
    const { status } = res;
    if (status === 200) {
      navigate(QUOTATION);
    }
  };

  const steps = [
    "Company Information",
    "Previous Certifications",
    "Details of Operations",
    "Details of Sites",
    "Attachments",
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  const isStepOptional = (step: number) => {
    return step === null;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    setCheck(false);
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box>
      <OnBoardingHeader />
      <Box
        sx={{
          paddingLeft: { xs: 2, md: 5 },
          paddingRight: { xs: 2, md: 5 },
          paddingTop: { xs: 2, md: 3 },
        }}
      >
        <OnBoardingStepper list={onBoardingStepperList} active={2} />
      </Box>
      <Loader loading={loading} />
      <Stepper
        activeStep={activeStep}
        sx={{
          mt: 5,
          pr: isMobile ? 10: 17,
          pl: isMobile ? 10: 17,
        }}
      >
        {steps.map((label, index) => {
          if(isMobile){
            if (index !== activeStep) {
              return null; 
            }
          }
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          pl: { xs: isMobile ? 2 : 5, md: 15 },
          pr: { xs: isMobile ? 2 : 5, md: 15 },
          pt: 2,
        }}
      >
        <Formik
          initialValues={companyDetailsInitialValue}
          validationSchema={compayDetailsSchema}
          onSubmit={(value: any) => {
            const formData = new FormData();
            Object.entries(value).forEach(([key, value]: any) => {
              formData.append(key, value);
            });
            formData.append("applicationId", applicationId);
            formData.append("companyId", companyId);

            foodSchemeFile &&
              foodSchemeFile.forEach((scheme: any) => {
                formData.append("foodScheme", scheme.item);
              });

            value.siteDetails &&
              value.siteDetails.forEach((item: any) => {
                formData.append("siteDetails", JSON.stringify(item));
              });

            slaughterhouseFile &&
              slaughterhouseFile.forEach((scheme: any) => {
                formData.append("slaughterHouse", scheme.item);
              });

            for (let i = 0; i < bussinessActivityFile.length; i++) {
              formData.append(
                "bussinessActivityDocument",
                bussinessActivityFile[i]
              );
            }

            console.log(foodSchemeFile, "asdahsgdjh");

            for (let i = 0; i < foodSchemeFile.length; i++) {
              formData.append("foodSchemeDocument", foodSchemeFile[i].file);
            }

            for (let i = 0; i < slaughterhouseFile.length; i++) {
              formData.append(
                "slaughterHouseDocument",
                slaughterhouseFile[i].file
              );
            }
            execute(formData, handleApplicationResponse);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            validateForm,
            setTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              {activeStep === steps.length ? (
                <Fragment>
                  <SummaryComponent
                    data={values}
                    bussinessDoc={bussinessActivityFile}
                    foodShemeDoc={foodSchemeFile}
                    slaughtherHouseDoc={slaughterhouseFile}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      pt: 2,
                      justifyContent: "space-between",
                
                    }}
                  >
                    <Button
                      color="inherit"
                      variant="contained"
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button variant="contained" onClick={handleReset}>
                      Reset
                    </Button>
                  </Box>
                </Fragment>
              ) : (
                <Fragment>
                  {activeStep === 0 ? (
                    <Box>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        flexDirection={isMobile ? 'column' : 'row'}
                        gap={2}
                      >
                        <DashboardContainer>
                        <CommonGridOnBoarding
                          handleChange={handleChange}
                          value={values}
                          onBlur={handleBlur}
                          error={errors}
                          list={isMobile ? companyDetailsOneMobileView : companyDetailsOne}
                          touched={touched}
                          paddingTop={{ xs: 2, md: 4 }}
                          setFieldValue={setFieldValue}
                        />
                       
                        </DashboardContainer>
                        <DashboardContainer>
                        <CommonGridOnBoarding
                          handleChange={handleChange}
                          value={values}
                          onBlur={handleBlur}
                          error={errors}
                          list={companyDetailsTwo}
                          touched={touched}
                          paddingTop={{ xs: 2, md: 4 }}
                        />
                          </DashboardContainer>
                   
                     
                      </Box>
                      <DashboardContainer>
                      <CommonGrid
                        paddingTop={{ xs: 2, md: 4 }}
                        handleChange={handleChange}
                        value={values}
                        onBlur={handleBlur}
                        error={errors}
                        list={companyManagementDetails}
                        touched={touched}
                      />
                      </DashboardContainer>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: { xs: "column", md: "row" },
                          alignItems: "center",
                          padding: { xs: 2, md: 3 },
                        }}
                      >
                        <FormControlLabel
                          sx={{ pt: 4 }}
                          control={
                            <Checkbox
                              name="isLargeFirm"
                              checked={values.isLargeFirm}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          }
                          label={"Is your firm part of some large organisation"}
                        />
                        <TextInputLight
                          type={TextFieldType.TEXT}
                          value={values["holdingCompany"]}
                          name={"holdingCompany"}
                          label={"Holding Company"}
                          onChange={handleChange}
                          width={350}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          paddingTop: 2,
                        }}
                      >
                        <FileComponent
                          onSelectFile={(file: any) =>
                            setBussinessActivityFile(file)
                          }
                        />
                        {stepperOneError && (
                          <Typography mt={5} fontSize={16} color={"red"}>
                            {stepperOneError}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  ) : activeStep === 1 ? (
                    <Fragment>
                      <CommonGrid
                        heading="Any previous certifications / Transfer of certification"
                        handleChange={handleChange}
                        value={values}
                        onBlur={handleBlur}
                        error={errors}
                        list={previousCertification}
                        touched={touched}
                      />
                    </Fragment>
                  ) : activeStep === 2 ? (
                    <Fragment>
                      <CommonGrid
                        heading="Details of operations in the Main Unit / Head office"
                        handleChange={handleChange}
                        value={values}
                        onBlur={handleBlur}
                        error={errors}
                        list={mainUnitDetails}
                        touched={touched}
                      />
                      <CommonGrid
                        handleChange={handleChange}
                        value={values}
                        onBlur={handleBlur}
                        error={errors}
                        list={mainUnitDetailsPart2}
                        touched={touched}
                        paddingTop={{ xs: 2, md: 6 }}
                      />
                      <CommonGrid
                        handleChange={handleChange}
                        value={values}
                        onBlur={handleBlur}
                        paddingTop={{ xs: 2, md: 2 }}
                        error={errors}
                        list={mainUnitCheckBox}
                        touched={touched}
                        filedType={INPUTFIELDTYPE.COMPANYDETAILCHECK}
                      />
                    </Fragment>
                  ) : activeStep === 3 ? (
                    <Fragment>
                      <Typography
                        variant="h6"
                        fontWeight={FontWeight.MEDIUM}
                        sx={{ pl: isMobile ? 0 : 5, pt: 2 }}
                      >
                        Details of Sites
                      </Typography>
                      <Typography
                        sx={{ pl: isMobile ? 0 : 5, pt: 0.5, fontSize:  isMobile ? '0.8rem' : '1rem' }}   
                      >
                        Details of Sites/agencies Address and Pin Code
                        Pleasemention if the site is Permanent (P)/Temporary (T)
                        / Additional (A)]
                      </Typography>
                      <SiteDetailComponent
                        onChange={handleChange}
                        value={values}
                        onBlur={handleBlur}
                        error={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                      <CommonGrid
                        heading=""
                        handleChange={handleChange}
                        value={values}
                        onBlur={handleBlur}
                        error={errors}
                        list={detailOfSite}
                        touched={touched}
                      />
                      <Typography
                        sx={{ pl: isMobile ? 0 : 5, pt: 4, fontSize:  isMobile ? '0.8rem' : '1rem' }}
                      >
                        No. of HACCP Study (in case of single site mention HACCP
                        Study for main site / for Multisite certification
                        mention site wise HACCP Study)
                      </Typography>
                      <SiteDetails setFieldValue={setFieldValue} />

                      <CommonGrid
                        handleChange={handleChange}
                        value={values}
                        onBlur={handleBlur}
                        paddingTop={{ xs: 2, md: 2 }}
                        error={errors}
                        list={noOfOPRP}
                        touched={touched}
                        filedType={INPUTFIELDTYPE.COMPANYDETAILCHECK}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <CheckLabelAttachment
                        list={attachemntList}
                        heading=" Attach the following documents along with the duly
                filled Questionnaire: : ( FOR Food Scheme ONLY)"
                        onChange={(val: any) => {
                          setFoodShemeFile(val);
                        }}
                      />
                      <CheckLabelAttachment
                        list={attachemntListSlaughtherHouse}
                        heading=" Attach the following documents along with the duly filled Questionnaire: ( FOR Slaughterhouse ONLY)"
                        onChange={(val: any) => {
                          setSlaughterhouseFile(val);
                        }}
                      />
                      <CommonGrid
                        handleChange={handleChange}
                        value={values}
                        onBlur={handleBlur}
                        paddingTop={{ xs: 2, md: 2 }}
                        error={errors}
                        list={position}
                        touched={touched}
                      />
                    </Fragment>
                  )}
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 10, mb : 5 }}>
                    <Button
                      color="inherit"
                      variant="contained"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {isStepOptional(activeStep) && (
                      <Button
                        variant="contained"
                        color="inherit"
                        onClick={handleSkip}
                        sx={{ mr: 1 }}
                      >
                        Skip
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        setTouched({
                          nameOfCompany: true,
                          email: true,
                          address: true,
                          invoiceAddress: true,
                          pinCode: true,
                          telephone: true,
                          website: true,
                          invoicePinCode: true,
                        });
                        validateForm().then((formErrors) => {
                          if (Object.keys(formErrors).length === 0) {
                            handleNext();
                          } else {
                            console.log("Form has errors", formErrors);
                          }
                        });
                      }}
                      variant="contained"
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Box>
                </Fragment>
              )}
              {activeStep === 5 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <TermsConditionsCheck
                    linkText="Terms and Conditions"
                    onChange={(value: any) => setCheck(value)}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!check}
                    sx={{ mt: 2, mb : 5 }}
                  >
                    Submit
                  </Button>
                </Box>
              )}
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default CompanyDetails;
