import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FontWeight } from "../../../core/types";

function createData(possibleRisk: string, value: string, assessment: any) {
  return {
    possibleRisk,
    value,
    assessment,
  };
}

const ImpartialityAssessement = ({ impartialityAssessementList }: any) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (impartialityAssessementList) {
      let list: any = [];
      impartialityAssessementList.map((item: any) => {
        list.push(
          createData(
            item.possibleRisk ? item.possibleRisk : "-",
            item.value ? item.value : "-",
            item.assessment ? item.assessment : "-"
          )
        );
      });
      setData(list);
    }
  }, [impartialityAssessementList]);

  return (
    <Box>
      <TableContainer>
        <Table
          sx={{
            minWidth: 650,
            width: "100%",
            mb: 4,
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Possible Risk</TableCell>
              <TableCell align="left">Value</TableCell>
              <TableCell align="center">
                Assessment / Mention Name of Resources
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row: any, index: any) => (
                <TableRow key={row.name}>
                  <TableCell align="left">{row.possibleRisk}</TableCell>
                  <TableCell align="center">{row.value}</TableCell>
                  <TableCell align="center">{row.assessment}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ImpartialityAssessement;
