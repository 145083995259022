import AdminLayout from "../../dashboard/components/AdminLayout";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Rating,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  Pagination,
  InputAdornment,
} from "@mui/material";
import Loader from "../../../core/components/Loader";
import { getFeedbacks } from "../../../store/Application/applicationService";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import { FontWeight } from "../../../core/types";
import SearchIcon from "@mui/icons-material/Search";

const Feedbacks = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const { execute, loading } = useAsyncRequest(getFeedbacks);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      setFeedbacks(data);
    }
  };

  useEffect(() => {
    execute({}, handleResponse);
  }, []);

  // Filter feedbacks based on the search term
  const filteredFeedbacks = feedbacks.filter((item: any) =>
    item.application.applicationNo.toString().includes(searchTerm)
  );

  // Calculate the number of pages needed
  const pageCount = Math.ceil(filteredFeedbacks.length / itemsPerPage);

  // Get feedback items for the current page
  const paginatedFeedbacks = filteredFeedbacks.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <AdminLayout>
      <Loader open={loading} />
      
      <Box display="flex" flexDirection="column" alignItems="center" height="100%" width="100%">
        
        {/* Heading and Search Bar */}
        <Box
          display="flex"
          justifyContent="space-between"
          width={isMobile ? "100%" : "60%"}
          mt={4}
          mb={4}
          flexDirection={isMobile ? "column" : "row"}
          gap={isMobile ? 1 : 2}
        >
          <Typography fontSize={20} fontWeight="bold" mb={1} color="#483F3D">
            Feedback
          </Typography>
          <TextField
            label="Search by Application ID"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: isMobile ? "100%" : "auto", }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon htmlColor="#9C6CA6"/>
                  </InputAdornment>
                ),
              }}
          />
        </Box>

        {/* Feedbacks List */}
        {paginatedFeedbacks.length > 0 ? (
          paginatedFeedbacks.map((item: any) => (
            <Box
              key={item.application.applicationNo}
              width={isMobile ? "100%" : "60%"}
              mb={2}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-${item.application.applicationNo}-content`}
                  id={`panel-${item.application.applicationNo}-header`}
                  sx={{ border: '1px solid #9C6CA6' }}
                >
                  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <Typography fontWeight={FontWeight.MEDIUM} color="#483F3D">
                      Application ID: {item.application.applicationNo}
                    </Typography>
                    <Rating
                      name="read-only"
                      value={item.rating}
                      readOnly
                    />
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {item.feedbacks.length !== 0 ? (
                    <Box>
                      {item.feedbacks.map((feedbackItem: any, index: any) => (
                        <Box mb={1} key={index}>
                          <Typography mb={1} color="#483F3D" fontSize={isMobile ? '0.8rem' : '1rem'}>
                            {index + 1}. {feedbackItem.label}
                          </Typography>
                          <Typography pl={2} color="#9C6CA6" fontWeight="medium">{feedbackItem.value}</Typography>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Typography color="#D26A32">No feedback available.</Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          ))
        ) : (
          <Typography color="#C54B56">No feedback found for the given Application ID.</Typography>
        )}

        {/* Pagination Component */}
        {pageCount > 1 && (
          <Box display="flex" mt={3}>
            <Pagination
              count={pageCount}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        )}
      </Box>
    </AdminLayout>
  );
};

export default Feedbacks;
