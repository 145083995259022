import React from "react";
import { Box, Typography, Grid, Container, useMediaQuery, useTheme } from "@mui/material";
import TextWithLabel from "../../../../core/components/TextWithLabel";
import { FontWeight } from "../../../../core/types";
import AttachmentsView from "../../../../core/components/AttachmentsView";
import DashboardContainer from "../../../../core/components/DashboardContainer";

const keyToLabel: any = {
  nameOfCompany: "Name of Company",
  telephone: "Telephone",
  panNo: "PAN Number",
  address: "Address",
  invoiceAddress: "Invoice Address",
  pinCode: "PIN Code",
  email: "Email",
  gstNo: "GST Number",
  tanNo: "TAN Number",
  website: "Website",
  annualTurnOver: "Annual Turnover",
  directorCeoPartner: "Director/CEO/Partner",
  directorCeoPartnerMobile: "Director/CEO/Partner Mobile",
  directorCeoPartnerEmail: "Director/CEO/Partner Email",
  foodSaftyTeamLeaderMR: "Food Safety Team Leader/MR",
  foodSaftyTeamLeaderMRMobile: "Food Safety Team Leader/MR Mobile",
  foodSaftyTeamLeaderMREmail: "Food Safety Team Leader/MR Email",
  bussinessActivityDocument: "Business Activity Document",
  previousCertificateDetails: "Previous Certificate Details",
  previousCertificateValidity: "Previous Certificate Validity",
  previousCertificateBody: "Previous Certificate Body",
  noOfWorkShifts: "Number of Work Shifts",
  noOfEmployeePerShift: "Number of Employees per Shift",
  seasonOfOperation: "Season of Operation",
  LanguageOfCommunication: "Language of Communication",
  outSourceProccess: "Outsource Process",
  exclusions: "Exclusions",
  approvalsSatutoryAndRegulatoryRequirementsOrLegalStatus:
    "Approvals, Statutory and Regulatory Requirements or Legal Status",
  activityBelongingToLegalEntity: "Activity Belonging to Legal Entity",
  isLargeFirm: "Is Large Firm",
  noOfCollectionsCentersForMilk: "Number of Collection Centers for Milk",
  inHouseLaboratory: "In-House Laboratory",
  productDevelopment: "Product Development",
  foodSaftyHazard: "Food Safety Hazard",
  attchmentListLawMaterials: "Attachment List of Law Materials",
  totalNumberOfSites: "Total Number of Sites",
  noOfSiteLocationPermanent: "Number of Permanent Site Locations",
  noOfSiteLocationTemporary: "Number of Temporary Site Locations",
  additionalScopeExtensionPermanent: "Additional Scope Extension (Permanent)",
  additionalScopeExtensionTemporary: "Additional Scope Extension (Temporary)",
  noOfProducts: "Number of Products",
  buildingArea: "Building Area",
  otherCertificateManagementSystem: "Other Certificate/Management System",
  mainSite: "Main Site",
  siteOne: "Site One",
  siteTwo: "Site Two",
  siteThree: "Site Three",
  siteFour: "Site Four",
  siteFive: "Site Five",
  siteSix: "Site Six",
  totalOPRPandCCP: "Total OPRP and CCP",
  legalStatus: "Legal Status",
  listOfRawMaterials: "List of Raw Materials",
  processDetails: "Process Details",
  foodSaftyManagementSystemIssues: "Food Safety Management System Issues",
  haccpAnalysisPlan: "HACCP Analysis Plan",
  testReports: "Test Reports",
  productLabels: "Product Labels",
  foodSchemeDocument: "Food Scheme Document",
  tradeOrSlaughterHouseLicense: "Trade or Slaughterhouse License",
  listOfSlaughterPerson: "List of Slaughter Persons",
  proccessFlowChart: "Process Flow Chart",
  certificateForCriticalIngredients: "Certificate for Critical Ingredients",
  healthCertificate: "Health Certificate",
  slaughterHouseDocument: "Slaughterhouse Document",
  holdingCompany: "Holding Company",
  totalOfSites: "Total of Sites",
  NoOfSitePermanent: "Number of Permanent Sites",
  scopeTemporary: "Scope (Temporary)",
  scopePermanent: "Scope (Permanent)",
  companyDetailsFilledByName: "Filled By",
  companyDetailsFilledByPosition: "Position",
};

const SummaryComponent = ({
  data,
  bussinessDoc,
  foodShemeDoc,
  slaughtherHouseDoc,
}: any) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    activityBelongingToLegalEntity,
    additionalScopeExtensionPermanent,
    additionalScopeExtensionTemporary,
    address,
    annualTurnOver,
    approvalsSatutoryAndRegulatoryRequirementsOrLegalStatus,
    attchmentListLawMaterials,
    buildingArea,
    bussinessActivityDocument,
    certificateForCriticalIngredients,
    directorCeoPartner,
    directorCeoPartnerEmail,
    directorCeoPartnerMobile,
    email,
    foodSaftyHazard,
    foodSaftyManagementSystemIssues,
    foodSaftyTeamLeaderMR,
    foodSaftyTeamLeaderMREmail,
    foodSaftyTeamLeaderMRMobile,
    foodSchemeDocument,
    gstNo,
    haccpAnalysisPlan,
    healthCertificate,
    inHouseLaboratory,
    invoiceAddress,
    invoicePinCode,
    isLargeFirm,
    legalStatus,
    listOfRawMaterials,
    listOfSlaughterPerson,
    nameOfCompany,
    noOfCollectionsCentersForMilk,
    noOfEmployeePerShift,
    noOfProducts,
    noOfSiteLocationPermanent,
    noOfSiteLocationTemporary,
    noOfWorkShifts,
    otherCertificateManagementSystem,
    outSourceProccess,
    panNo,
    pinCode,
    previousCertificateBody,
    previousCertificateDetails,
    previousCertificateValidity,
    proccessFlowChart,
    processDetails,
    productDevelopment,
    productLabels,
    siteDetails,
    slaughterHouseDocument,
    tanNo,
    telephone,
    testReports,
    totalNumberOfSites,
    totalOPRPandCCP,
    tradeOrSlaughterHouseLicense,
    website,
    holdingCompany,
  } = data;

  const companyDetails = {
    nameOfCompany,
    telephone,
    address,
    pinCode,
    invoiceAddress,
    invoicePinCode,
    website,
    tanNo,
    gstNo,
    email,
    annualTurnOver,
  };

  const companyOwners = {
    directorCeoPartner,
    directorCeoPartnerMobile,
    directorCeoPartnerEmail,
  };

  const foodSaftyTeam = {
    foodSaftyTeamLeaderMR,
    foodSaftyTeamLeaderMRMobile,
    foodSaftyTeamLeaderMREmail,
  };

  const detailsOfOperation = {
    noOfWorkShifts,
    noOfEmployeePerShift,
    outSourceProccess,
    approvalsSatutoryAndRegulatoryRequirementsOrLegalStatus,
    activityBelongingToLegalEntity,
    noOfCollectionsCentersForMilk,
    inHouseLaboratory,
    productDevelopment,
    foodSaftyHazard,
  };

  const detailsOfSiteNoOfSites = {
    totalNumberOfSites,
    noOfSiteLocationPermanent,
    noOfSiteLocationTemporary,
    additionalScopeExtensionPermanent,
    additionalScopeExtensionTemporary,
  };

  const detailsOfSiteTwo = {
    noOfProducts,
    buildingArea,
    otherCertificateManagementSystem,
  };
  const siteDetailsObj = {
    siteDetails,
  };

  const renderItem = (label: string, item: any) => {
    return (
      <Box>
        <Typography mt={2} mb={2} fontSize={20} fontWeight={FontWeight.MEDIUM}>
          {label.toUpperCase()}
        </Typography>
        <Grid container spacing={3}>
          {Object.keys(item).map((key) => (
            <Grid item xs={12} sm={3} key={key}>
              <TextWithLabel
                title={keyToLabel[key] || key}
                subTitle={
                  data[key] !== null && data[key] !== "" ? data[key] : "-"
                }
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const attchmentView = (item: any, label: string) => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        mb={4}
      >
        <Typography fontWeight={FontWeight.BOLD} fontSize={18} mb={2}>
          {label}
        </Typography>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          {item.map((item: any) => {
            return <AttachmentsView label={item.name} />;
          })}
        </Box>
      </Box>
    );
  };

  const attchmentViewFiles = (item: any, label: string) => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        mb={4}
      >
        <Typography fontWeight={FontWeight.BOLD} fontSize={18} mb={2}>
          {label}
        </Typography>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          {item.map((val: any) => {
            return (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography>{val.item} : </Typography>
                <AttachmentsView label={val.file.name} />
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Box sx={{ padding: isMobile ? 0 : 4 }}>
        <Typography variant="h6">Summary</Typography>
        <DashboardContainer>
        {renderItem("Company Details", companyDetails)}
        </DashboardContainer>
       
        <Box
          mt={4}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          {bussinessDoc.length !== 0 &&
            attchmentView(bussinessDoc, "Business Activity Documents")}
        </Box>
       
        <DashboardContainer>
        {renderItem("", companyOwners)}
        </DashboardContainer>
        <DashboardContainer>
        {renderItem("", foodSaftyTeam)}
        </DashboardContainer>
        <DashboardContainer>
        {renderItem("", { holdingCompany })}
        </DashboardContainer>
        <DashboardContainer>
        {renderItem("Details Of Operation", detailsOfOperation)}
        </DashboardContainer>
        <DashboardContainer>
        {renderItem("Details Of Sites", detailsOfSiteNoOfSites)}
        </DashboardContainer>
        <DashboardContainer>
        {renderItem("", detailsOfSiteTwo)}
        </DashboardContainer>
        <Grid container spacing={3}>
          {siteDetails &&
            siteDetails.map((item: any, index: number) => {
              return (
                <Grid item xs={6} sm={6} md={6}>
                  <TextWithLabel title={item.label} subTitle={item.item} />
                </Grid>
              );
            })}
        </Grid>
        <Box
          mt={4}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
        > 
          {foodShemeDoc.length !== 0 &&
            attchmentViewFiles(foodShemeDoc, "Food Scheme Documents")}
          {slaughtherHouseDoc.length !== 0 &&
            attchmentViewFiles(slaughtherHouseDoc, "Slaughter House Documents")}
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryComponent;