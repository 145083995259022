import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import GetAppIcon from "@mui/icons-material/GetApp";
import { downloadFile } from "../../utils";

const getIconByType = (type: any) => {
  switch (type) {
    case "doc":
      return <InsertDriveFileIcon />;
    case "pdf":
      return <PictureAsPdfIcon />;
    case "png":
      return <ImageIcon />;
    default:
      return <InsertDriveFileIcon />;
  }
};

const AttachmentsView = ({ label }: any) => {
  const fileName = label.split("/").pop();
  const fileType = fileName?.split(".").pop();

  const icon = getIconByType(fileType);

  return (
    <Box display="flex" alignItems="center">
      {icon}
      <Typography variant="body1" style={{ marginLeft: 8 }}>
        {fileName}
      </Typography>
      <IconButton
        onClick={() => {
          downloadFile(label, fileName);
        }}
      >
        <GetAppIcon />
      </IconButton>
    </Box>
  );
};

export default AttachmentsView;
