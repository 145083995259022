import { Box, Typography } from "@mui/material";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { getActiveApplicationAuditor } from "../../../store/Dashboard/dashboardService";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import OrderTable from "../../dashboard/AuditorDashboard/components/Recent";
import Loader from "../../../core/components/Loader";
import moment from "moment";

function createData(
  name: string,
  address: any,
  applicationDate: any,
  type: any,
  quotation: any,
  status: any
) {
  return { name, address, applicationDate, type, quotation, status };
}

const AuditorNotifications = () => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.user);

  return (
    <AdminLayout>
      <DashboardContainer>
        <Typography variant="h5">Notifcaition</Typography>
        <Box
          height={400}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant="h5">No notifcaition yet</Typography>
        </Box>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default AuditorNotifications;
