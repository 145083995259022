import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FontWeight, TextFieldType } from "../../../../core/types";
import TextInputLight from "../../../../core/components/Input/TextInputLight";
import FileComponent from "../../../../core/components/FileComponent";
import { Formik } from "formik";

import { commonValidationSchema } from "../../../../core/validations/formSchema";
import {
  auditFindingsInitialValue,
  auditorFindingsInitialValue,
} from "../../constants";
import useAsyncRequest from "../../../../networking/useAsyncRequest";
import {
  auditorfindingsUpdate,
  findingsUpdate,
} from "../../../../store/Application/applicationService";
import Loader from "../../../../core/components/Loader";
import TextWithLabel from "../../../../core/components/TextWithLabel";
import { useDispatch, useSelector } from "react-redux";
import { USERTYPE } from "../../../../core/constants";
import {
  auditorAuditFindings,
  userAuditFindings,
} from "../../../../store/AdminFlow/adminSlice";
import AttachmentsView from "../../../../core/components/AttachmentsView";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FindingsResolution = ({
  butttonLabel,
  item,
  index,
  applicationId,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const user = useSelector((state: any) => state.auth.user);

  const { role } = user;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAlignment("");
  };

  const [alignment, setAlignment] = React.useState("");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  const [evidence, setEvidence] = React.useState<any[]>([]);

  const { execute, loading } = useAsyncRequest(findingsUpdate);

  const handleResponse = (res: any) => {
    const { status } = res;
    if (status == 200) {
      handleClose();
      dispatch(userAuditFindings(res));
    }
  };

  const { execute: auditorUpdate, loading: updateLoading } = useAsyncRequest(
    auditorfindingsUpdate
  );

  const auditorHandleResponse = (res: any) => {
    const { status } = res;
    if (status == 200) {
      handleClose();
      dispatch(auditorAuditFindings(res));
    }
  };

  const detailField = (title: string, subtitle: any) => {
    const isBool = typeof subtitle === "boolean";
    let subTitle = "";
    if (isBool) {
      subTitle = subtitle === true ? "Yes" : "No";
    } else {
      subTitle = subtitle ? subtitle : "-";
    }
    return (
      <Grid item xs={2} sm={4} md={4}>
        {title && (
          <TextWithLabel
            titleFontSize={14}
            subTitleFontSize={20}
            title={title}
            subTitle={subTitle}
          />
        )}
      </Grid>
    );
  };

  const tableTitle = (title: any) => {
    return (
      <Typography fontWeight={FontWeight.BOLD} color={"gray"}>
        {title}
      </Typography>
    );
  };

  const rowContainer = (title: any, subTitle: any) => {
    return (
      <Box
        mt={1}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={FontWeight.BOLD}>{title}</Typography>
        <Typography fontWeight={FontWeight.BOLD}>{subTitle}</Typography>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        {butttonLabel}
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Findings
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Loader open={loading || updateLoading} />
        <Box
          mt={4}
          pl={4}
          pr={4}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {isMobile ? (
            <Box width={"100%"}>
              {rowContainer("Grading", item?.grading)}
              {rowContainer("Reference Clause", item?.referenceClause)}
              {rowContainer("Assessment Team", item?.assessementTeamMember)}
              {rowContainer("Remarks", item?.remarks)}
              {item?.resolution?.rootCauseAnalysus &&
                rowContainer(
                  "Root Cause Analysis (RCA)",
                  item?.resolution?.rootCauseAnalysus
                )}
              {item?.resolution?.correctionActions &&
                rowContainer(
                  "Corrections (CA)",
                  item?.resolution?.correctionActions
                )}
              {item?.resolution?.targetedDate &&
                rowContainer("Traget Date", item?.resolution?.targetedDate)}
              {item?.resolution?.reasonForNonAcceptance &&
                rowContainer(
                  "Reason for Non Acceptance",
                  item?.resolution?.reasonForNonAcceptance
                )}
              {item?.resolution?.conclusionForUnacceptedFindings &&
                rowContainer(
                  "Conclusion",
                  item?.resolution?.conclusionForUnacceptedFindings
                )}
              {item?.resolutionEvidence && (
                <Box mt={2}>
                  <AttachmentsView label={item.resolutionEvidence} />
                </Box>
              )}
            </Box>
          ) : (
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  border: 1,
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography fontWeight={FontWeight.BOLD} color={"gray"}>
                      {tableTitle("Grading")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {tableTitle("Reference Clause")}
                  </TableCell>
                  <TableCell align="center">
                    {tableTitle("Assessment Team")}
                  </TableCell>
                  <TableCell align="center">{tableTitle("Remarks")}</TableCell>
                  {item?.resolution?.rootCauseAnalysus && (
                    <TableCell align="center">
                      {tableTitle(" Root Cause Analysis (RCA)")}
                    </TableCell>
                  )}
                  {item?.resolution?.correctionActions && (
                    <TableCell align="center">
                      {tableTitle("Corrections (CA)")}
                    </TableCell>
                  )}
                  {item?.resolution?.targetedDate && (
                    <TableCell align="center">
                      {tableTitle("Target Date")}
                    </TableCell>
                  )}
                  {item?.resolution?.reasonForNonAcceptance && (
                    <TableCell align="center">
                      {tableTitle(" Reason for Non Acceptance")}
                    </TableCell>
                  )}
                  {item?.resolution?.conclusionForUnacceptedFindings && (
                    <TableCell align="center">
                      {tableTitle("Conclusion")}
                    </TableCell>
                  )}
                  {item?.resolutionEvidence && (
                    <TableCell align="center">
                      {tableTitle("Resolution Evidence")}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 1 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell align="center">
                    <Typography fontWeight={FontWeight.BOLD}>
                      {item?.grading}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={FontWeight.BOLD}>
                      {item?.referenceClause}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={FontWeight.BOLD}>
                      {item?.assessementTeamMember}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={FontWeight.BOLD}>
                      {item?.remarks}
                    </Typography>
                  </TableCell>
                  {item?.resolution?.rootCauseAnalysus && (
                    <TableCell align="center">
                      <Typography fontWeight={FontWeight.BOLD}>
                        {item.resolution.rootCauseAnalysus}
                      </Typography>
                    </TableCell>
                  )}
                  {item?.resolution?.correctionActions && (
                    <TableCell align="center">
                      <Typography fontWeight={FontWeight.BOLD}>
                        {item.resolution.correctionActions}
                      </Typography>
                    </TableCell>
                  )}
                  {item?.resolution?.targetedDate && (
                    <TableCell align="center">
                      <Typography fontWeight={FontWeight.BOLD}>
                        {item.resolution.targetedDate}
                      </Typography>
                    </TableCell>
                  )}
                  {item?.resolution?.reasonForNonAcceptance && (
                    <TableCell align="center">
                      <Typography fontWeight={FontWeight.BOLD}>
                        {item.resolution.reasonForNonAcceptance}
                      </Typography>
                    </TableCell>
                  )}
                  {item?.resolution?.conclusionForUnacceptedFindings && (
                    <TableCell align="center">
                      <Typography fontWeight={FontWeight.BOLD}>
                        {item.resolution.conclusionForUnacceptedFindings}
                      </Typography>
                    </TableCell>
                  )}
                  {item?.resolutionEvidence && (
                    <TableCell align="center">
                      <AttachmentsView label={item.resolutionEvidence} />
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Box>
        {item.status ? (
          <Box
            mt={4}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
          ></Box>
        ) : (
          <Box
            mt={3}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            width={"100%"}
          >
            {role == USERTYPE.USER && (
              <ToggleButtonGroup
                color={alignment == "accept" ? "success" : "error"}
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="accept">Accept</ToggleButton>
                <ToggleButton value="reject">Reject</ToggleButton>
              </ToggleButtonGroup>
            )}
            <Formik
              initialValues={auditFindingsInitialValue}
              validationSchema={commonValidationSchema}
              onSubmit={(value: any) => {
                const formData = new FormData();
                formData.append("applicationId", applicationId);
                formData.append("findingsId", index);
                formData.append("resolution", JSON.stringify(value));
                formData.append("status", alignment);
                if (alignment == "accept") {
                  for (let i = 0; i < evidence.length; i++) {
                    formData.append("evidence", evidence[i]);
                  }
                }
                execute(formData, handleResponse);
              }}
            >
              {({ handleChange, handleSubmit }) => (
                <form
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onSubmit={handleSubmit}
                >
                  {alignment === "reject" ? (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                    >
                      <Box mt={4} width={"100%"}>
                        <TextInputLight
                          type={TextFieldType.TEXT}
                          name={"reasonForNonAcceptance"}
                          label="REASON FOR NON-ACCEPTANCE OF FINDING"
                          multiline
                          width={isMobile ? "100%" : 600}
                          labelHeight={0}
                          textFieldHeigt={30}
                          row={2}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box mt={4} pt={4} width={"100%"}>
                        <TextInputLight
                          type={TextFieldType.TEXT}
                          name={"conclusionForUnacceptedFindings"}
                          label="CONCLUSION FOR THE UNACCEPTED FINDINGS"
                          multiline
                          labelHeight={0}
                          width={isMobile ? "100%" : 600}
                          textFieldHeigt={30}
                          row={2}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{ mt: 7 }}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>
                  ) : alignment === "accept" ? (
                    <Box>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                        width={"100%"}
                      >
                        <Box mt={4} width={isMobile ? "90%" : "100%"}>
                          <TextInputLight
                            type={TextFieldType.TEXT}
                            name={"rootCauseAnalysus"}
                            label="ROOT CAUSE ANALYSIS (RCA)"
                            multiline
                            width={isMobile ? "100%" : 600}
                            labelHeight={0}
                            textFieldHeigt={30}
                            row={2}
                            onChange={handleChange}
                          />
                        </Box>
                        <Box mt={6} width={isMobile ? "90%" : "100%"}>
                          <TextInputLight
                            type={TextFieldType.TEXT}
                            name={"correctionActions"}
                            label="CORRECTIONS AND/OR CORRECTIVE ACTIONS (CA)"
                            multiline
                            width={isMobile ? "100%" : 600}
                            labelHeight={0}
                            textFieldHeigt={30}
                            row={2}
                            onChange={handleChange}
                          />
                        </Box>
                        <Box
                          mt={4}
                          pt={4}
                          pb={5}
                          width={isMobile ? "90%" : "100%"}
                        >
                          <TextInputLight
                            type={TextFieldType.DATE}
                            name={"targetedDate"}
                            label="TARGET DATE OF IMPLEMENTATION(yyyy-mm-dd)"
                            labelHeight={0}
                            width={isMobile ? "100%" : 600}
                            textFieldHeigt={30}
                            onChange={handleChange}
                          />
                        </Box>
                        <Box width={isMobile ? "90%" : "100%"}>
                          <FileComponent
                            onSelectFile={(file: any) => setEvidence(file)}
                          />
                        </Box>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{ mt: 5, mb: 5 }}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>
                  ) : null}
                </form>
              )}
            </Formik>
          </Box>
        )}
        {role == USERTYPE.AUDITOR &&
          item.status &&
          item.status !== "Closed" && (
            <Box
              mt={3}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
              width={"100%"}
            >
              <Typography fontWeight={FontWeight.BOLD} textAlign={"center"}>
                VERIFICATION OF EFECTIVENESS OF CLOSURE OF FINDINGS
              </Typography>
              <Formik
                initialValues={auditorFindingsInitialValue}
                validationSchema={commonValidationSchema}
                onSubmit={(value: any) => {
                  const audiorPayload = {
                    applicationId: applicationId,
                    findingsId: index,
                    audiorRemarks: JSON.stringify(value),
                    status: "Closed",
                  };
                  auditorUpdate(audiorPayload, auditorHandleResponse);
                }}
              >
                {({ handleChange, handleSubmit }) => (
                  <form
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                    onSubmit={handleSubmit}
                  >
                    <Box width={isMobile ? "90%" : "50%"}>
                      <Box mt={4}>
                        <TextInputLight
                          type={TextFieldType.TEXT}
                          name={"recommendationOrStatus"}
                          label="RECOMMENDATION / STATUS OF CLOSURE OF FINDINGS"
                          multiline
                          labelHeight={0}
                          textFieldHeigt={30}
                          row={2}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box mt={4} pt={4}>
                        <TextInputLight
                          type={TextFieldType.TEXT}
                          name={"closedAssementTeam"}
                          label="DATE VERIFIED / CLOSED BY ASSESSMENT TEAM)"
                          multiline
                          labelHeight={0}
                          textFieldHeigt={30}
                          row={2}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box mt={4} pt={4}>
                        <TextInputLight
                          type={TextFieldType.TEXT}
                          name={"assementTeamMember"}
                          label="ASSESSMENT TEAM MEMBER"
                          multiline
                          labelHeight={0}
                          textFieldHeigt={30}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box mt={4} pt={4} pb={5}>
                        <TextInputLight
                          type={TextFieldType.TEXT}
                          name={"auditorRemarks"}
                          label="REMARKS / NOTES (if any)"
                          labelHeight={0}
                          textFieldHeigt={30}
                          multiline
                          row={2}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{ mt: 5, mb: 5 }}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          )}
        {item.status == "Closed" ? (
          <Box
            mt={3}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            width={"100%"}
          >
            <Typography fontWeight={FontWeight.BOLD} textAlign={"center"}>
              VERIFICATION OF EFECTIVENESS OF CLOSURE OF FINDINGS
            </Typography>
            <Grid
              container
              spacing={isMobile ? 2 : 4}
              alignItems={"baseline"}
              columns={{ xs: 2, sm: 12, md: 16 }}
              sx={{
                paddingTop: 5,
                paddingBottom: 2,
                pr: 5,
                pl: 5,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {item.auditorRemarks.recommendationOrStatus &&
                detailField(
                  "RECOMMENDATION / STATUS OF CLOSURE OF FINDINGS",
                  item.auditorRemarks.recommendationOrStatus
                )}
              {item.auditorRemarks.closedAssementTeam &&
                detailField(
                  "DATE VERIFIED / CLOSED BY ASSESSMENT TEAM",
                  item.auditorRemarks.closedAssementTeam
                )}
              {item.auditorRemarks.assementTeamMember &&
                detailField(
                  "ASSESSMENT TEAM MEMBER",
                  item.auditorRemarks.assementTeamMember
                )}
              {item.auditorRemarks.auditorRemarks &&
                detailField(
                  "REMARKS / NOTES (if any)",
                  item.auditorRemarks.auditorRemarks
                )}
            </Grid>
          </Box>
        ) : (
          <Box></Box>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default FindingsResolution;
