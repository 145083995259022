import { Box } from "@mui/material";
import { signUpBackground } from "../../../../core/Images";

const ImageContainer = () => {
  return (
    <Box
      component="img"
      sx={{
        width: "50%",
        minHeight: "60vh",
        backgroundColor: "grey",
        backgroundImage: `url(${signUpBackground})`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `100% 100%`,
        height: "100%",
      }}
    />
  );
};

export default ImageContainer;
