import { Box, Typography } from "@mui/material";
import InputIcon from '@mui/icons-material/Input';
import { FontWeight } from "../../types";

const LabelDownloadComponent = ({
  label = "report",
  onClick,
  bgColor = "#a6d5fa",
}: any) => {
  return (
    <Box onClick={onClick}>
      <Box
        mt={2}
        sx={{
          backgroundColor: bgColor, borderRadius: 2, transition: "transform 0.2s ease-in-out",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          "&:hover": {
            animation: "shake 0.5s",
            transform: "translate3d(1px, 0, 0)",
          },
          "@keyframes shake": {
            "0%, 100%": {
              transform: "translate3d(0, 0, 0)",
            },
            "10%, 30%, 50%, 70%, 90%": {
              transform: "translate3d(-2px, 0, 0)",
            },
            "20%, 40%, 60%, 80%": {
              transform: "translate3d(2px, 0, 0)",
            },
          },
        }}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        p={2}
      >
        <Box>
          <Typography color={"#2E2E2E"} fontWeight={FontWeight.MEDIUM}>
            {label}
          </Typography>
        </Box>
        <Box>
          <InputIcon />
        </Box>
      </Box>
    </Box>
  );
};

export default LabelDownloadComponent;
