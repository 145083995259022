import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { BillItem } from "../../Constants";
import { FC } from "react";

interface QuotationDetailProps {
  quotationBreakUp: BillItem[];
}

const QuotationDetails: FC<QuotationDetailProps> = ({
  quotationBreakUp = [],
}) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box pl={ isMobile ? 2 : 5} pr={ isMobile ? 2 : 5} pt={4}>
      {quotationBreakUp.map((data) => {
        const { id, item, amount } = data;
        return (
          <Box
            key={id}
            pb={4}
            display={"flex"}
            flexDirection={"row"}
            width={"100%"}
            justifyContent={"space-between"}
          >
            <Typography width={"80%"} variant={"subtitle2"}>
              {item}
            </Typography>
            <Typography variant={"subtitle2"}>{amount}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default QuotationDetails;
