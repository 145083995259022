import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import { FontWeight, TextFieldType } from "../../../core/types";
import { useLocation, useNavigate } from "react-router-dom";
import {
  auditorsRecommendation,
  stageOneCheckListB,
  stageOneInitialValueB,
  summaryStageOneAudit,
} from "../constants";
import { Formik } from "formik";
import { stageOneAuditCheckListA } from "../../../core/validations/formSchema";
import TextInputTextField from "../../../core/components/Input/TextInputTextField";
import ThreeCheckComponentWithFeild from "../components/threeCheckComponentWithField";
import FileComponent from "../../../core/components/FileComponent";
import { useState } from "react";
import { REQUIREDCHECKLIST } from "../../../router/config";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { stageOnecheckList } from "../../../store/AuditorFlow/auditorListService";
import Loader from "../../../core/components/Loader";

const StageOneCheckListB = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();
  const navigate = useNavigate();
  const { data: info, checkList } = location.state;
  const [productFile, setProductFile] = useState([]);

  const { execute, loading } = useAsyncRequest(stageOnecheckList);

  const { applicationId } = info;

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      navigate(REQUIREDCHECKLIST, {
        state: { data: info },
      });
    }
  };

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={loading} />
        <Formik
          initialValues={stageOneInitialValueB}
          validationSchema={stageOneAuditCheckListA}
          onSubmit={(value) => {
            const formData = new FormData();
            Object.entries(value).forEach(([key, value]: any) => {
              const newValue = JSON.stringify(value);
              formData.append(key, newValue);
            });

            formData.append("applicationId", applicationId);
            formData.append("auditMode", checkList.auditMode);

            const applicationDocument = JSON.stringify(
              checkList.applicationDocument
            );
            formData.append("applicationDocument", applicationDocument);
            formData.append(
              "applicationDocumentsCheckListFollowUpComments",
              checkList.applicationDocumentsCheckListFollowUpComments
            );
            formData.append(
              "applicationDocumentsCheckListRemarks",
              checkList.applicationDocumentsCheckListRemarks
            );

            for (let i = 0; i < productFile.length; i++) {
              formData.append("photographOfProcess", productFile[i]);
            }
            execute(formData, handleResponse);
          }}
        >
          {({ handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Typography
                variant="h6"
                fontWeight={FontWeight.BOLD}
                pl={2}
                pt={3}
              >
                B. Stage One Audit Checklist
              </Typography>
              <ThreeCheckComponentWithFeild
                rows={stageOneCheckListB}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
                fieldName={"stageOneAuditCheckList"}
                data={info}
              />
              <Typography
                variant="h6"
                fontWeight={FontWeight.BOLD}
                pl={2}
                pt={3}
              >
                C. Photograph of Product/Process (it may be posted here or as
                attachment):
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: 2,
                }}
              >
                <FileComponent
                  onSelectFile={(file: any) => setProductFile(file)}
                />
              </Box>
              <Typography
                variant="h6"
                fontWeight={FontWeight.BOLD}
                pl={2}
                pt={3}
              >
                D. Summary of Stage 1 Audit:
              </Typography>
              <Box>
                {summaryStageOneAudit.map((item: any, index: any) => {
                  return (
                    <TextInputTextField
                      type={TextFieldType.TEXT}
                      name={item.name}
                      label={item.label}
                      onChange={(val: any) => {
                        setFieldValue(
                          `summaryStageOneAudit[${index}].${item.name}`,
                          { value: val.target.value, label: item.label }
                        );
                      }}
                      onBlur={handleBlur}
                      multiline={true}
                      row={2}
                      mt={index == 0 ? 0 : 4}
                    />
                  );
                })}
              </Box>
              <Typography
                variant="h6"
                fontWeight={FontWeight.BOLD}
                pl={2}
                pt={3}
                mt={4}
              >
                E. Auditor’s Recommendation
              </Typography>
              <Box display={"flex"} flexDirection={"column"} pl={1} mt={2}>
                {auditorsRecommendation.map((item: any, index: any) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={item.name}
                          onChange={(value: any) => {
                            setFieldValue(
                              `auditorRecommedation[${index}].${item.name}`,
                              { value: value.target.checked, label: item.label }
                            );
                          }}
                          onBlur={handleBlur}
                        />
                      }
                      label={<Typography fontSize={isMobile ?"0.7rem" : 14}>{item.label}</Typography>}
                    />
                  );
                })}
              </Box>
              <TextInputTextField
                type={TextFieldType.TEXT}
                name="followUpRemarksToStageTwoAudit"
                label="Follow-up / Remarks to Stage 2 Audit, if applicable (conducted after Stage 1):"
                onChange={handleChange}
                onBlur={handleBlur}
                multiline={true}
                row={2}
              />
              <Box
                pt={7}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                width={"85%"}
              >
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default StageOneCheckListB;
