import { Box, Checkbox, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { FontWeight } from "../../../core/types";
import { fsmsCheckRequestList } from "../constants";

const LabelCheckBox = ({
  rows = [1, 2, 4],
  setFieldValue,
  fieldName = "name",
  heading = "haeding",
}: any) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [selected, setSelected] = useState<any>({});

  const handleChange =
    (name: string, option: any, label: string, index: any) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = {
        value: event.target.checked ? option : "",
        label: label,
      };
      setSelected((prev: any) => ({
        ...prev,
        [name]: newValue,
      }));
      if (fieldName === "fsmsCheckList") {
        setFieldValue(`${fieldName}`, fsmsCheckRequestList);
      } else {
        setFieldValue(`${fieldName}.${index}`, newValue);
        setFieldValue(`${fieldName}[heading]`, heading);
      }
    };

  return (
    <Box pl={2} pt={1}>
      {rows.map((item: any, index: any) => {
        return (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pt={2}
          >
            <Typography fontSize={isMobile ? "0.8rem" : 16} fontWeight={FontWeight.MEDIUM}>
              {item.label}
            </Typography>
            <Checkbox
              checked={selected[item.name]?.value === true}
              onChange={handleChange(item.name, true, item.label, index)}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default LabelCheckBox;
