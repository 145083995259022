import { makeApiRequest } from "../../networking/apiClient";
import { ADDCOMPANYDETAIL } from "../../networking/endpoints";

export const addCompanyDetails = async (payload: any) => {
  try {
    const customHeaders = {
      "Content-Type": "multipart/form-data;",
    };
    const response = await makeApiRequest(
      ADDCOMPANYDETAIL,
      "POST",
      payload,
      customHeaders
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
