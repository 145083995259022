import { Box, TextField, Typography } from "@mui/material";
import { FC, FocusEventHandler } from "react";
import { FontWeight, TextFieldType } from "../../types";

interface TextInputProps {
  label: string;
  labelHeight?: any;
  onChange: any;
  size?: any;
  type?: string;
  name?: string;
  value?: string;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  error?: boolean;
  helperText?: any;
  multiline?: boolean;
  row?: number;
  width?: any;
  textFieldHeigt?: any;
  mt?: number;
  disabled?: any;
}

const TextInputTextField: FC<TextInputProps> = ({
  label,
  size = "small",
  labelHeight = 40,
  onChange,
  type = TextFieldType.TEXT,
  name,
  value,
  onBlur,
  error,
  helperText,
  multiline = false,
  row = 1,
  width = "100%",
  textFieldHeigt = 20,
  disabled = false,
  mt,
}) => {
  return (
    <Box sx={{ padding: { xs: 1, sm: 1, md: 1 } }} mt={mt}>
      <Box
        sx={{
          height: {
            sx: labelHeight,
            md: labelHeight,
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        {label && (
          <Typography
            fontWeight={FontWeight.BOLD}
            color={"#929396"}
            paddingBottom={1}
            fontSize={15}
          >
            {label}
          </Typography>
        )}
      </Box>

      <TextField
        size={size}
        sx={{
          width: { xs: width, md: width },
          minWidth: 250,
          height: textFieldHeigt,
          "& .MuiOutlinedInput-root": {
            color: "#000",
            fontFamily: "Arial",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#D5D5D5",
              borderWidth: 1,
              borderRadius: 1,
            },
          },
          "& .MuiInputLabel-outlined": {
            color: "#2e2e2e",
          },
        }}
        multiline={multiline}
        rows={row}
        onChange={onChange}
        type={type}
        error={error}
        name={name}
        value={value}
        onBlur={onBlur}
        helperText={helperText}
        disabled={disabled}
      />
    </Box>
  );
};

export default TextInputTextField;
