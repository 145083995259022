import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import { FontWeight } from "../../../core/types";
import { useLocation, useNavigate } from "react-router-dom";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import {
  getCertificateByApplication,
  issueCertificate,
} from "../../../store/Application/applicationService";
import Loader from "../../../core/components/Loader";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import CommonGrid from "../../onBoarding/Components/common/CommonGrid";
import { certificateDetails, certificateDetailsInitialValue } from "./contant";
import { issueCertificateSchema } from "../../../core/validations/formSchema";
import FileUploadWithPreview from "./components/uploadComponent";
import TextWithLabel from "../../../core/components/TextWithLabel";
import { BASE_URL } from "../../../networking/apiClient";
import { downloadFile, handleDownloadPdf } from "../../../core/utils";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: '10px'
};

const CertificateUpload = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: any) => state.auth.user);
  const { role } = user;
  const { data } = location.state;
  const [alertOpen, setAlertOpen] = useState(false);
  const [certificateData, setCertificateData] = useState<any>();
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const handleOpenAlert = () => setAlertOpen(true);
  const handleCloseAlert = () => setAlertOpen(false);

  const [selectedFiles, setSelectedFiles] = useState<any>(null);
  const [fieldValue, setFieldValue] = useState<any>([]);

  const { applicationId, userEmail } = data;

  const { execute, loading } = useAsyncRequest(issueCertificate);

  const { execute: getCertificate, loading: getLoading } = useAsyncRequest(
    getCertificateByApplication
  );

  useEffect(() => {
    const payload = {
      applicationId: applicationId,
    };
    getCertificate(payload, handleGetResponse);
  }, [success]);

  const handleGetResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      setCertificateData(data);
    }
  };

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      setSuccess(true);
      setMessage("Certificate Issued Succesfully");
      handleCloseAlert();
    } else {
      setSuccess(false);
      handleCloseAlert();
      setMessage("Something Went Wrong");
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 2000);
    return () => clearTimeout(timer);
  }, [message, success]);

  const confirmAlert = () => {
    return (
      <Modal
        open={alertOpen}
        onClose={handleCloseAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please confirm, are you sure want to issue certificate
          </Typography>
          <Box
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <Button onClick={() => handleCloseAlert()} variant="outlined">No</Button>
            <Button
              onClick={() => {
                const formData = new FormData();
                formData.append("applicationId", applicationId);
                formData.append("userEmail", userEmail);
                Object.entries(fieldValue).forEach(([key, value]: any) => {
                  const newValue = JSON.stringify(value);
                  formData.append(key, newValue);
                });
                formData.append("certificateFile", selectedFiles);

                execute(formData, handleResponse);
              }}
              variant="contained"
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  const detailField = (title: string, subtitle: any) => {
    const isBool = typeof subtitle === "boolean";
    let subTitle = "";
    if (isBool) {
      subTitle = subtitle === true ? "Yes" : "No";
    } else {
      subTitle = subtitle ? subtitle : "-";
    }
    return (
      <Grid item xs={2} sm={4} md={4}>
        {title && <TextWithLabel title={title} subTitle={subTitle} />}
      </Grid>
    );
  };

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={loading || getLoading} />
        {confirmAlert()}
        {certificateData ? (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
          >
            <Typography fontSize={24} fontWeight={FontWeight.BOLD} pl={2}>
              Halal Registration Certificate
            </Typography>
            <Grid
              container
              spacing={4}
              alignItems={"baseline"}
              columns={{ xs: 2, sm: 12, md: 16 }}
              sx={{
                paddingTop: 10,
                paddingBottom: 5,
              }}
            >
              {detailField("Certification No", certificateData?.certificateNo)}
              {detailField("Reference No", certificateData?.referenceNo)}
              {detailField("Reference No", certificateData?.referenceNo)}
              {detailField("Effective Date", certificateData?.effectiveDate)}
              {detailField("Expiry Date", certificateData?.expiryDate)}
            </Grid>
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize={24} fontWeight={FontWeight.BOLD} pl={2}>
                Certificate
              </Typography>
              <IconButton onClick={() => downloadFile(certificateData?.file,'cerificate')}>
                <Typography mr={1}>Download</Typography>
                <FileDownloadRoundedIcon />
              </IconButton>
            </Box>

            <Box
              component="img"
              src={BASE_URL + certificateData?.file}
              alt="Certificate Preview"
              sx={{
                width: "100%",
                height: "auto",
                border: "1px solid #ccc",
                padding: "10px",
                mt: 2,
              }}
            />
          </Box>
        ) : (
          <Box id="page-content" sx={{ padding: 5, backgroundColor: "white" }}>
            <Box
              pr={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
              sx={{ backgroundColor: "white" }}
            >
              <Typography fontSize={24} fontWeight={FontWeight.BOLD} pl={2}>
                Issue Halal Registration Certificate
              </Typography>
            </Box>
            <Formik
              initialValues={certificateDetailsInitialValue}
              validationSchema={issueCertificateSchema}
              onSubmit={(value: any) => {
                setFieldValue(value);
                handleOpenAlert();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    fontSize={20}
                    fontWeight={FontWeight.MEDIUM}
                    pt={6}
                    pl={4}
                  >
                    Please fill the fields below
                  </Typography>
                  <CommonGrid
                    handleChange={handleChange}
                    value={values}
                    onBlur={handleBlur}
                    paddingTop={{ xs: 2, md: 2 }}
                    error={errors}
                    list={certificateDetails}
                    touched={touched}
                  />

                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    mt={4}
                  >
                    <FileUploadWithPreview
                      onFileSelected={(val: any) => setSelectedFiles(val)}
                    />
                    <Button
                      type={"submit"}
                      variant="contained"
                      disabled={!selectedFiles}
                      sx={{ mt: 4 }}
                    >
                      Issue Certificate
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        )}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        ></Box>
        {message && (
          <Alert severity={success ? "success" : "error"}>{message}</Alert>
        )}
      </DashboardContainer>
    </AdminLayout>
  );
};

export default CertificateUpload;
