import { TextFieldType } from "../../../core/types";


export const certificateDetails = [
    {
      type: TextFieldType.DATE,
      name: "effectiveDate",
      label: "Effrective Date",
      value: "",
    },
    {
      type: TextFieldType.DATE,
      name: "expiryDate",
      label: "Expiry Date",
    },
    {
        type: TextFieldType.TEXT,
        name: "referenceNo",
        label: "Reference No",
      },
  ];


 export const certificateDetailsInitialValue = {
    effectiveDate : '',
    expiryDate : ''
 }

 export const uploadFile = [
    {
      type: TextFieldType.TEXT,
      name: "issueCertificate",
      label: "Issue Certificate",
    },
]