import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { useEffect, useState } from "react";

function createData(
  siteName: string,
  activitis: string,
  noOfShifts: any,
  shiftName: any,
  fullTimeEmp: any,
  partTimeEmp: any,
  contractEmp: any
) {
  return {
    siteName,
    activitis,
    noOfShifts,
    shiftName,
    fullTimeEmp,
    partTimeEmp,
    contractEmp,
  };
}

const SeasonalActivityTable = ({ sesonalActivityData }: any) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (sesonalActivityData) {
      let list: any = [];
      sesonalActivityData.map((item: any) => {
        list.push(
          createData(
            item.siteName ? item.siteName : "-",
            item.activeties,
            item.noOfShifts ? item.noOfShifts : "-",
            item.shiftName ? item.shiftName : "-",
            item.fullTimeEmp ? item.fullTimeEmp : "-",
            item.partTimeEmp ? item.partTimeEmp : "-",
            item.contractEmp ? item.contractEmp : "-"
          )
        );
      });
      setData(list);
    }
  }, [sesonalActivityData]);

  return (
    <Box>
      <TableContainer>
        <Table
          sx={{
            minWidth: 650,
            width: "100%",
            mb: 4,
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Sites Name</TableCell>
              <TableCell align="center">Activities</TableCell>
              <TableCell align="center">No. of shifts</TableCell>
              <TableCell align="center">Shifts Name</TableCell>
              <TableCell align="center">Full time Emp.</TableCell>
              <TableCell align="center">Part time Emp.</TableCell>
              <TableCell align="center">Contract Emp.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row: any, index: any) => (
                <TableRow key={row.name}>
                  <TableCell align="center">{row.siteName}</TableCell>
                  <TableCell align="center">{row.activitis}</TableCell>
                  <TableCell align="center">{row.noOfShifts}</TableCell>
                  <TableCell align="center">{row.shiftName}</TableCell>
                  <TableCell align="center">{row.fullTimeEmp}</TableCell>
                  <TableCell align="center">{row.partTimeEmp}</TableCell>
                  <TableCell align="center">{row.contractEmp}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SeasonalActivityTable;
